<script setup lang="ts"></script>

<template>
  <div class="footer-logo d-flex align-items-start">
    <img
      class="footer-logo-image"
      src="/img/logo/ncpp_logo_black-white.svg"
      :alt="`${$t('main.siteTitleFull')} ${$t('main.ofisialWebsite')} `"
    />
    <div class="footer-logo-text-container">
      <span class="footer-logo-text"> {{ $t("main.siteTitleFull") }}</span>
      <span class="footer-logo-description">{{ $t("main.ofisialWebsite") }}</span>
    </div>
  </div>
</template>
