export default {
  en: {
    siteTitle: "NMCS",
    siteTitleFull: "National Mediation and Conciliation Service",
    ofisialWebsite: "Official website",
    license1: "All content is available under a Creative Commons license",
    license2: "Attribution 4.0 International license unless otherwise noted",
    test_mode: "The site works in test mode",
    breadcrumbs: {
      main: "Main",
      aboutUs: "About us",
      activities: "Activity areas",
      activityDisputes: "Collective disputes (conflicts)",
      socialDialogue: "Social dialogue",
      pressCenter: "Press center",
      news: "News",
      mediaLibrary: "Media library",
      events: "Events",
      gallery: "Gallery",
      pubInMedia: "Publications in mass media",
      reports: "Reports",
      governmentCleans: "Government Cleans",
      submit_documents: "Submit Documents",
      forPublic: "For the public",
      resources: "Registries and resources",
      vacancies: "Vacancies and contests",
    },
    common: {
      share: "Share",
      tags: "Tags",
      sections: "Sections",
      consent:
        "By registering an application, I consent to the processing and use of personal data in accordance with the legislation of Ukraine",
      fieldIsRequired: "Field is required.",
    },
    galleries: {
      photoTitle: "Photo",
      videoTitle: "Video",
      allPhotoLink: "All photos",
      allVideoLink: "All videos",
    },
    menu: {
      aboutUs: {
        title: "About us",
        mission: "Mission, vision, values",
        stakeholders: "Our stakeholders",
        history: "History",
        cooperation: "International cooperation",
        departments: "Structure",
        contacts: "Contacts",
        reports: "Activity reports",
      },
      areasOfActivities: {
        title: "Areas of activities",
        collectiveDisputes: "Collective disputes (conflicts)",
        disputesConflicts: "The concept of a collective labor dispute (conflict)",
        alorithm: "Algorithm for solving a collective labor dispute (conflict)",
        trainingLaborArbitrators: "Training of labor arbitrators and independent mediators",
        prevention: "Prevention of collective labor disputes",
        socialDialogue: "Social dialogue",
        concept: "Social dialogue concept",
        legislation: "Legislation of Ukraine on social dialogue",
        principles: "Basic principles of social dialogue",
        determination: "Determination of representativeness criteria",
        peculiarities:
          "Peculiarities of submitting documents and extending the validity of certificates under martial law",
        socioEconomicCouncil: "National tripartite socio-economic council",
        foreignExperience: "Foreign experience",
      },
      regulatory: "Normative base",
      resources: {
        title: "Registries and resources",
        bulletin: "Bulletin",
        collectiveLaborDisputesRegister: "Register of collective labor disputes (conflicts)",
        partiesEntitiesRegister:
          "Register of representative subjects of parties of trade unions and employers' organizations",
        laborArbitrators: "List of labor arbitrators",
        independentMediators: "List of independent mediators",
        links: "Useful links",
      },
      forPublic: {
        title: "For the public",
        appealOfCitizens: "Appeal of citizens",
        governmentProcurement: "Government procurement",
        publicInformation: "Access to public information",
        actualVacancies: "Vacancies and contests",
        governmentCleans: "Purification of power",
        submitDocuments: "Submit documents for registration",
        antiCorruptionPolicy: "Prevention and counteraction of corruption",
      },
      pressCenter: {
        title: "Press center",
        news: "News",
        mediaLibrary: "Media library",
        announcements: "Announcements",
        pubInMedia: "Publications in mass media",
        popularTags: "Popular news tags",
      },
    },
    emptyResults: {
      defaultTitle: "Nothing was found for your request",
      noDocuments: "No documents were found for your request",
      action: "What to do with it",
      step1: "Make sure all words are spelled correctly.",
      step2: "Try other keywords.",
      step3: "Try to use more general words.",
    },
    loading: "Loading...",
    tabs: {
      vacancies: {
        actual: "Actual vacancies",
        results: "Results of the competition",
        info: "Information for contest participants",
      },
      regulatory: {
        legislation: "Legislation",
        activity: "NPA by areas of activity",
        judicialPractice: "Judicial practice",
      },
    },
    tags: {
      title: "Tags",
    },
    regions: {
      vi: "Vinnytsia",
      vo: "Volynsk",
      dp: "Dnipropetrovsk",
      do: "Donetsk",
      zh: "Zhytomyr",
      uz: "Zakarpattia",
      zp: "Zaporizhzhia",
      if: "Ivano-Frankivsk",
      ky: "Kyivska",
      ki: "Kirovohradska",
      lg: "Luhansk",
      lv: "Lviv",
      mk: "Mykolaivska",
      od: "Odeska",
      pl: "Poltava",
      rv: "Rivnenska",
      su: "Sumska",
      te: "Ternopilska",
      kh: "Kharkivska",
      ks: "Khersonska",
      km: "Khmelnytska",
      ck: "Cherkasia",
      cv: "Chernivetska",
      cn: "Chernihivska",
    },
    readMore: "Read More",
    readLess: "Read Less",
    datepicker: {
      select: "Select",
      cancel: "Cancel",
      datePickerPlaceholder: "Select date",
    },
    download: "Download",
    and: "and",
    by: "by",
    hours: "h",
    at: "at",
    socialMedia: {
      facebook: "NSPP on Facebook",
      telegram: "NSPP in Telegram",
    },
    open: "Expand",
    close: "Collapse",
  },
  uk: {
    siteTitle: "НСПП",
    siteTitleFull: "Національна служба посередництва і примирення",
    ofisialWebsite: "Офіційний веб-сайт",
    license1: "Весь контент доступний за ліцензією Creative Commons",
    license2: "Attribution 4.0 International license, якщо не зазначено інше",
    test_mode: "Сайт працює в тестовому режимі",
    breadcrumbs: {
      main: "Головна",
      aboutUs: "Про нас",
      activities: "Напрями діяльності",
      activityDisputes: "Колективні спори (конфлікти)",
      socialDialogue: "Соціальний діалог",
      pressCenter: "Пресцентр",
      news: "Новини",
      mediaLibrary: "Медіатека",
      events: "Анонси",
      gallery: "Медіатека",
      pubInMedia: "Публікації у ЗМІ",
      reports: "Звіти про діяльність",
      governmentCleans: "Очищення влади",
      forPublic: "Для громадськості",
      resources: "Реєстри та ресурси",
      vacancies: "Вакансії та конкурси",
    },
    common: {
      share: "Поділитись",
      tags: "Теги",
      sections: "Розділи",
      consent:
        "Реєструючи звернення, даю згоду на обробку і використання персональних даних згідно з законодавством України",
      fieldIsRequired: "Обовʼязкове поле.",
    },
    galleries: {
      photoTitle: "Фото",
      videoTitle: "Відео",
      allPhotoLink: "Всі фото",
      allVideoLink: "Всі відео",
    },
    menu: {
      aboutUs: {
        title: "Про нас",
        mission: "Місія, візія, цінності",
        stakeholders: "Наші стейкхолдери",
        history: "Історія",
        cooperation: "Міжнародна співпраця",
        departments: "Структура",
        contacts: "Контакти",
        reports: "Звіти про діяльність",
      },
      areasOfActivities: {
        title: "Напрями діяльності",
        collectiveDisputes: "Колективні трудові спори",
        disputesConflicts: "Поняття колективного трудового спору (конфлікту)",
        alorithm: "Алгоритм вирішення колективного  трудового спору (конфлікту)",
        trainingLaborArbitrators: "Підготовка трудових арбітрів та незалежних посередників",
        prevention: "Запобігання виникненню колективних трудових спорів",
        socialDialogue: "Соціальний діалог",
        concept: "Поняття соціального діалогу",
        legislation: "Законодавство України про соціальний діалог",
        principles: "Основні принципи соціального діалогу",
        determination: "Визначення критеріїв репрезентативності",
        peculiarities:
          "Особливості подання документів і продовження дії свідоцтв в умовах воєнного стану",
        socioEconomicCouncil: "Національна тристороння соціально-економічна рада",
        foreignExperience: "Зарубіжний досвід",
      },
      regulatory: "Нормативна база",
      resources: {
        title: "Реєстри та ресурси",
        bulletin: "Бюлетень НСПП",
        collectiveLaborDisputesRegister: "Реєстр колективних трудових спорів (конфліктів)",
        partiesEntitiesRegister:
          "Реєстр репрезентативних суб'єктів сторін профспілок та організацій роботодавців",
        laborArbitrators: "Список трудових арбітрів",
        independentMediators: "Список незалежних посередників",
        links: "Корисні посилання",
      },
      forPublic: {
        title: "Для громадськості",
        appealOfCitizens: "Звернення громадян",
        governmentProcurement: "Державні закупівлі",
        publicInformation: "Доступ до публічної інформації",
        actualVacancies: "Вакансії та конкурси",
        governmentCleans: "Очищення влади",
        submitDocuments: "Подати документи на реєстрацію КТС(к)",
        antiCorruptionPolicy: "Запобігання та протидія корупції",
      },
      pressCenter: {
        title: "Пресцентр",
        news: "Новини",
        mediaLibrary: "Медіатека",
        announcements: "Анонси",
        pubInMedia: "Публікації у ЗМІ",
        popularTags: "Популярні новинні теги",
      },
    },
    emptyResults: {
      defaultTitle: "За вашим запитом нічого не знайдено",
      noDocuments: "За вашим запитом не знайдено документів",
      action: "Що з цим робити",
      step1: "Переконайтеся, що всі слова написані правильно.",
      step2: "Спробуйте інші ключові слова.",
      step3: "Спробуйте використати загальніші слова.",
    },
    loading: "Завантаження...",
    tabs: {
      vacancies: {
        actual: "Актуальні вакансії",
        results: "Результати конкурсу",
        info: "Інформація для учасників конкурсу",
      },
      regulatory: {
        legislation: "Законодавство",
        activity: "НПА за напрямами діяльності",
        judicialPractice: "Судова практика",
      },
    },
    tags: {
      title: "Теги",
    },
    regions: {
      vi: "Вінницька",
      vo: "Волинська",
      dp: "Дніпропетровська",
      do: "Донецька",
      zh: "Житомирська",
      uz: "Закарпатська",
      zp: "Запорізька",
      if: "Івано-Франківська",
      ky: "Київська",
      ki: "Кіровоградська",
      lg: "Луганська",
      lv: "Львівська",
      mk: "Миколаївська",
      od: "Одеська",
      pl: "Полтавська",
      rv: "Рівненська",
      su: "Сумська",
      te: "Тернопільська",
      kh: "Харківська",
      ks: "Херсонська",
      km: "Хмельницька",
      ck: "Черкаська",
      cv: "Чернівецька",
      cn: "Чернігівська",
    },
    readMore: "Показати більше",
    readLess: "Показати менше",
    datepicker: {
      select: "Вибрати",
      cancel: "Скасувати",
      datePickerPlaceholder: "Обрати дату",
    },
    download: "Завантажити",
    and: "та",
    by: "до",
    hours: "год",
    at: "о",
    socialMedia: {
      facebook: "НСПП на Facebook",
      telegram: "НСПП у Telegram",
    },
    open: "Розгорнути",
    close: "Згорнути",
  },
};
