import { BASE_URL } from "@/constants";
import { AppealRequest, AppealResponse } from "@/interfaces";

export default {
  async getAppealCSRF(): Promise<AppealResponse> {
    const response = await fetch(`${BASE_URL}forms/appeal`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },
  async postAppeal(form: AppealRequest): Promise<void> {
    const formdata = new FormData();

    formdata.append("name", encodeURIComponent(form.name));
    formdata.append("email", encodeURIComponent(form.email));
    formdata.append("address", encodeURIComponent(form.address));
    formdata.append("district", encodeURIComponent(form.district));
    formdata.append("settlement", encodeURIComponent(form.settlement));
    formdata.append("respond_by", encodeURIComponent(form.respondBy));
    formdata.append("postal_code", encodeURIComponent(form.postalCode));
    formdata.append("message", encodeURIComponent(form.message));
    formdata.append("attachment", encodeURIComponent(form.attachment));
    formdata.append("csrf_token", encodeURIComponent(form.csrfToken));

    const response = await fetch(`${BASE_URL}forms/appeal`, {
      method: "POST",
      body: formdata,
      redirect: "follow",
    });
    if (response.status !== 200) {
      throw response.status;
    }
  },
};
