export default {
  en: {
    mission: {
      title: "Mission, Vision, Values",
      mission: "Mission",
      text: "We promote social dialogue and help to pave the way for understanding.",
      vision: "Vision",
      visionList: {
        item1:
          "The National Mediation and Conciliation Service has broad competencies, significant experience, and an understanding of the target group. This provides a reliable foundation for the effective implementation of our social mission. We create conditions for the continuous development of the Service.",
        item2:
          "NMCS adopts a forward-looking approach to preventing and facilitating the resolution of collective labor disputes. We ensure a high level of inclusivity at all levels of social dialogue with appropriate assessment of needs, risks, and resources.",
        item3:
          "NMCS is a permanent state body that works coherently and flexibly, with a common understanding of our social mission. We are an independent regulator that ensures equal and impartial treatment of participants in socio-labor relations.",
        item4:
          "We encourage innovation and collaboration and adhere to our shared values. We enhance digital security and use modern digital solutions and new technologies for training and development.",
        item5:
          "NMCS's activities are based on knowledge and professional experience. We bring together leading scientific and educational communities in our field and offer continuous professional development programs for our employees, independent mediators, and labor arbitrators. We are active and responsible public servants who share our professional experience with others.",
        item6:
          "NMCS has a good reputation and enjoys a high level of trust and authority in society. We are a strong and visible player that plays an important role in policy development in our field, and we use effective and modern communication platforms.",
        item7: "NMCS is prestigious and honorable, and its employees are worthy representatives.",
      },
      values: "Values",
      valuesList: {
        item1: "Impartiality",
        item2: "Professionalism",
        item3: "Integrity",
        item4: "Objectivity",
        item5: "Trust",
        item6: "Fairness",
        item7: "Openness",
      },
    },
    history: {
      title: "History",
      p1: "The National Mediation and Conciliation Service (NMCS, the Service) was founded on November 17, 1998, by a decree of the President of Ukraine 'On the establishment of the National Mediation and Reconciliation Service' in accordance with the Law of Ukraine 'On the procedure for resolving collective labor disputes (conflicts)'.",
      p2: "The purpose of the Service is to improve labor relations, prevent the emergence and facilitate the resolution of collective labor disputes.",
      p3: "NMCS mediates and reconciles in conflict situations, assists in reaching a compromise between employees, trade unions, and employers. It also ensures the convergence of positions, the achievement of mutual agreements, and the adoption of coordinated decisions by the parties of social dialogue.",
      p4: "During the creation of NMCS, international labor standards and the experience of other democratic countries with similar services, which have existed worldwide for decades, were taken into account.",
      p5: "For example, the Advisory, Conciliation and Arbitration Service in the United Kingdom was founded in 1896, the National Mediation Service in Sweden was established in 1906, the Federal Mediation and Conciliation Service in the United States was founded in 1947, and in 1969 the Collective Labor Relations Service was created in Belgium.",
      imgDescription1:
        "June 7, 1993, is remembered as the day of the largest miners' strike in Donbas in the history of Ukraine.",
      p6: "The creation of the Service was actually a response to labor conflicts in the 1990s between hired workers, trade unions, and employers. High unemployment rates, the outflow of qualified workers from various sectors of the economy, the critical condition of state enterprises, and numerous cases of wage arrears led to heightened protest sentiments among citizens.",
      p7: "Social protests and strikes spread across the country.",
      p8: "In response to the challenges of that time, the parliament adopted the Law of Ukraine 'On the procedure for resolving collective labor disputes (conflicts)', which envisaged the existence of the Service as a permanent state body designed to facilitate the resolution of collective labor disputes (conflicts).",
      p9: "With the accumulation of experience, NMCS has improved its forms and methods of work. Initially, the Service focused on the prompt resolution of collective labor disputes, but later NMCS specialists concluded that resolving a dispute is more difficult and costly than preventing it. Therefore, in developing Article 15 of the Law of Ukraine 'On the procedure for resolving collective labor disputes (conflicts)', NMCS developed and implemented a comprehensive system of legal measures to prevent the emergence of collective labor disputes (conflicts), settle differences between hired workers and employers at the pre-conflict stage, and prevent the escalation of conflict situations.",
      imgDescription2:
        "Protest action of AeroSvit employees, 2014. Illustrative photo from open sources.",
      imgDescription3: "The first Head of NMCS V. Rudenko. Photo from the NMCS archive.",
      p10: "The first head of the Service from 1998 to 2006 was Vasyl Rudenko. From 2006 to 2022, it was Oleksandr Okis. Currently, the Head of NMCS is Dmytro Kukhniuk, appointed by the decree of the President of Ukraine on September 14, 2022.",
      p11: "To effectively resolve collective labor disputes, NMCS has implemented a legal framework for the functioning of labor arbitrators and independent mediators in Ukraine.",
      p12: "Since 2001, the training of independent mediators began at the National Academy of Internal Affairs in Kyiv and the Yaroslav Mudryi National Law Academy in Kharkiv.",
      p13: "As part of the International Labour Organization project 'Inclusive labor market for job creation in Ukraine', funded by the Government of Denmark, the Service, with the support of ILO experts and trainers, organized the training of trainers and conducted five regional training seminars 'Mediation in the settlement of collective labor disputes' during 2019-2021. The participants of these seminars were Service specialists, representatives of trade unions, employer organizations, executive authorities, and local self-government, as well as scientists. As a result of the training, they received mediator certificates.",
      imgDescription4:
        "Training seminar: 'Mediation in the settlement of collective labor disputes', 2019. Photo from the NMCS archive.",
      imgDescription5:
        "Training: 'Mediation in labor disputes', 2019. Photo from the NMCS archive.",
      p14: "Currently, under the auspices of NMCS, there are 256 labor arbitrators and 100 independent mediators.",
      p15: "One of the important areas of the Service's work is ensuring social dialogue.",
      p16: "According to the Law of Ukraine 'On social dialogue in Ukraine', NMCS assesses the compliance of trade unions and their associations, employer organizations, and their associations with representativeness criteria.",
      p17: "Confirmation of representativeness is a necessary condition for the participation of trade unions and employers' sides in social dialogue.",
      p18: "To develop social dialogue in Ukraine, NMCS initiates and participates in various communication events.",
      p19: "One of the important areas of the Service's work is ensuring social dialogue.",
      p20: "According to the Law of Ukraine 'On social dialogue in Ukraine', NMCS assesses the compliance of trade unions and their associations, employer organizations, and their associations with representativeness criteria.",
      p21: "Confirmation of representativeness is a necessary condition for the participation of trade unions and employers' sides in social dialogue.",
      imgDescription6:
        "Presentation of a certificate recognizing the All-Ukrainian Professional Union of Workers in Higher Education as meeting the criteria of representativeness at the sectoral level to the head of the union, Stanislav Yarotsky. Photo from the NMCS archive.",
      imgDescription7:
        "Visit to the ILO headquarters (Geneva) by NMCS Head Dmytro Kukhniuk as part of a tripartite delegation from Ukraine, 2023. Photo from the NMCS archive.",
      p22: "One of NMCS's key partners is the International Labour Organization.",
      p23: "In 2023, during a visit of a tripartite delegation from Ukraine (representatives of the government, nationally representative trade unions, and employer organizations) to the ILO headquarters (Switzerland, Geneva), international experience in improving social dialogue and enhancing its role in Ukraine was studied.",
      p24: "During the visit, the Ukrainian tripartite delegation discussed ways to strengthen social dialogue, improve labor legislation, and implement international labor standards.",
      imgDescription8:
        "Visit to the ILO headquarters (Geneva) by NMCS Head Dmytro Kukhniuk as part of a tripartite delegation from Ukraine, 2023. Photo from the NMCS archive.",
      imgDescription9: "Educational visit to Turin (Italy), November 2023.",
      p25: "In November 2023, for the purpose of training and exchanging experience, NMCS leadership and heads of five regional departments in Volyn, Kirovohrad, Poltava, Khmelnytskyi, and Cherkasy regions visited Turin (Italy), where two events took place at the ILO International Training Center:",
      list: {
        item1:
          "Training 'Creating effective systems for preventing and resolving labor disputes', held as part of the project 'Inclusive labor market for job creation in Ukraine';",
        item2:
          "Seminar 'Evaluation of labor dispute resolution institutions', which, in particular, discussed the draft law 'On collective labor disputes' with the participation of international experts.",
      },
      p26: "In addition, in Kyiv in November 2023, a seminar 'Social dialogue as a tool of understanding' was organized for central office employees and heads of regional departments of NMCS, conducted by the Aspen Institute Kyiv, for the exchange of ideas, opinions, and visions when discussing planned topics.",
      imgDescription10:
        "Photo of NMCS employees. Seminar 'Social dialogue as a tool of understanding', conducted by the Aspen Institute Kyiv, November 2023.",
      imgDescription11: "Strategic planning seminar of NMCS, Kyiv, December 2023.",
      p27: "In December of the same year, NMCS leadership and employees again gathered at an important and significant event: a two-day strategic planning seminar of NMCS - to jointly define the mission, vision, and values of the Service.",
      p28: "The event was supported by the ILO, where based on the developments obtained during the working trip to Turin and taking into account ILO recommendations, the mission, vision, and values of NMCS were formed through joint efforts and a shared understanding.",
      p29: "In May 2023, NMCS Head Dmytro Kukhniuk, as an involved participant in constitutional proceedings, presented the position of NMCS in the Constitutional Court of Ukraine regarding the peculiarities of the extrajudicial and pre-trial procedures for resolving collective labor disputes (conflicts) in the case of the constitutional complaint of the All-Ukrainian Trade Union of Science, Production, and Finance Workers of PJSC 'ArcelorMittal Kryvyi Rih'.",
      p30: "NMCS pays significant attention to improving the regulatory framework.",
      imgDescription12:
        "NMCS Head Dmytro Kukhniuk in the Constitutional Court of Ukraine, May 2023.",
      p31: "In order to systematize and make available information about NMCS's activities to all interested parties, a unique open-access electronic archive has been created on the Service's website.",
      imgDescription13: "Illustration of the NMCS emblem",
      imgDescription14: "Illustration of the NMCS flag",
      p32: "Over the years, NMCS has played a vital role in creating and improving the regulatory framework for regulating collective labor relations and preventing the emergence of collective labor disputes (conflicts). In particular, in February 2023, the Law of Ukraine 'On Collective Agreements and Contracts' was adopted, which was developed with the participation of NMCS representatives.",
      p33: "NMCS has developed a significant amount of regulatory documents, which have been digitized and made publicly available on the NMCS website.",
      p34: "In collaboration with the Ministry of Economy of Ukraine, the Joint Representative Body of Representative All-Ukrainian Trade Union Associations at the national level, the Joint Representative Body of the Employers' Side at the national level, and the National Tripartite Socio-Economic Council, as well as scientists, national and international experts, a draft Law of Ukraine 'On Collective Labor Disputes' was developed. This draft aims to improve the system for resolving labor disputes and ensure a more effective and democratic approach to conflict resolution.",
      p35: "NMCS's experience indicates that establishing effective social dialogue and practical resolution of collective labor disputes (conflicts) requires the involvement of interested parties in cooperation with NMCS.",
      p36: "The service has established communication with key stakeholders. Memorandums of cooperation and interaction have been signed with leading universities, scientific and expert institutions, public and professional associations.",
      imgDescription15:
        "Signing of the cooperation agreement between the National Mediation and Conciliation Service and Taras Shevchenko National University of Kyiv in 2022. Photo from the NMCS archive.",
      imgDescription16:
        "Signing of the Memorandum of Cooperation between NMCS and the Research Institute of Lawmaking and Legal Expertise of the National Academy of Legal Sciences of Ukraine, 2023. Photo from the archive.",
      p37: "Recently, NMCS has become a more noticeable factor in social dialogue.",
      p38: "The service actively cooperates with trade unions and employers' organizations at all levels and strengthens social dialogue, which is important under martial law. The positions of all interested parties need to converge to make balanced decisions in the socio-labor sphere in accordance with the challenges of wartime.",
      p39: "During martial law, the NMCS team focused on modernizing and updating the service. In particular, attention was paid to developing communication, legislative work, digitalizing processes and services, establishing partnerships with interested parties, creating a new website, and enhancing work in the direction of developing social dialogue.",
      p40: "The service also strives to be open and transparent, sharing information on its social media pages.",
      p41: "Additionally, the service has strengthened its staff while maintaining the 'institutional memory' of its personnel - the vast majority of highly qualified and experienced employees continued to work in our team.",
      imgDescription17:
        "Signing of the Memorandum of Cooperation between NMCS and the Coordination Center for Legal Aid Provision, 2023. Photo from the NMCS archive.",
      imgDescription18:
        "Employees of the central office of NMCS. Vyshyvanka Day, 2023. Photo from the NMCS archive.",
      p42: "The service has improved regulatory acts on representativeness and confirmation of representativeness for the period of martial law.",
      p43: "In particular, in accordance with the requirements of paragraph 5 of section VI 'Final and Transitional Provisions' of the Law of Ukraine 'On Collective Agreements and Contracts,' a procedure was developed for extending the validity period of certificates confirming representativeness. This procedure applies to entities that previously received certificates of representativeness whose validity expired during martial law. The procedure allows social dialogue parties to continue participating in social dialogue during martial law and for six months after its end.",
      p44: "The aforementioned issues have become priorities for the service. Effective social dialogue is the key to peace and harmony in the socio-labor sphere and an important factor in the rapid recovery of the country from the consequences of the war.",
      p45: "NMCS's experience confirms the importance and productivity of its role as an authoritative state body that facilitates the resolution of differences between parties in social and labor relations through a civilized legal approach and the development of social dialogue.",
      imgDescription19:
        "Issuance of the order 'On extending the validity of the certificate of representativeness confirmation of the Association of All-Ukrainian Autonomous Trade Unions' to the head of the Association of All-Ukrainian Autonomous Trade Unions, Trade Union of Armed Forces Employees of Ukraine Valeriy Dobrovolsky. Photo from the NMCS archive.",
    },
    stakeholders: {
      allStakeholdersLink: "All stakeholders",
      emptyResult: "Sorry, no information",
      error: "Failed to load stakeholders. Try later",
    },
    cooperation: {
      title: "International Cooperation",
      buttonText: "Learn More",
      ilo: {
        header: "International Labour Organization (ILO)",
        text: "As part of the implementation of the International Labour Organization project 'Inclusive Labour Market for Job Creation in Ukraine', funded by the Government of Denmark under the Danish Neighbourhood Programme (DANEP) for 2017-2021 and the Danish Support Programme for Ukraine, the National Mediation and Conciliation Service (NMCS) conducted five regional training seminars on 'Mediation in the Settlement of Collective Labour Disputes' with the assistance of ILO experts and trainers. As a result of these training seminars, 80 participants – NMCS specialists and its regional offices, representatives of trade unions and employers' organizations, executive authorities and local self-government bodies, and academics – received mediator certificates. In 2021, ILO specialists provided expert support to NMCS in drafting the law 'On Collective Labour Disputes'.",
      },
      sida: {
        header: "SIDA - Swedish International Development Cooperation Agency",
        text: "SIDA is a government agency of the Ministry of Foreign Affairs of Sweden that is responsible for development cooperation worldwide. In 2013, within the framework of the Swedish-Ukrainian project 'Resolution of Labour Disputes in Ukraine', an expert group including NMCS employees developed a new model for resolving labour disputes.",
      },
      usaid: {
        header: "United States Agency for International Development (USAID)",
        text: "NMCS is a beneficiary of the 'Global Labour Program - New Horizons for the Protection of Workers' Rights', under which the US Government (USAID) implements this project dedicated to protecting workers' rights.",
      },
      europe: {
        header: "Council of Europe",
        text: "Within the framework of the Council of Europe project 'Strengthening Social Protection in Ukraine', Dr. Jana Symutina, Doctor of Law and Professor, was invited to work on the draft law 'On Collective Labour Disputes'.",
      },
      taiex: {
        header: "TAIEX (Technical Assistance and Information Exchange)",
        text: "As part of the cooperation with TAIEX, a seminar on 'Foreign Experience in Strike Regulation' was held on 14 December 2022.",
      },
      nba: {
        header: "Netherlands Business Academy",
        text: "To strengthen friendly relations and establish bilateral cooperation, NMCS collaborates with the Netherlands Business Academy under a memorandum of understanding. In particular, in June 2023, the rector of the Netherlands Business Academy, Jan van Zwieten, conducted a training on best mediation practices in the Netherlands.",
      },
    },
    departments: {
      seeBiography: "See biography",
      direction: "Direction",
      centralDepartment: "Central Department",
      structuralDepartments: "Structural departments",
      regionalDepartments: "Regional departments",
      address: "Address",
      phone: "Phone number",
      email: "Email",
      antiCorruptionTitle: "Special authorized entities in the field of anti-corruption",
      nazk: "National Agency for the Prevention of Corruption",
      gp: "General Prosecutor's Office of Ukraine (General Prosecutor's Office)",
      nabu: "National Anti-Corruption Bureau of Ukraine",
      police: "National Police of Ukraine",
      workSchedule: "Work schedule",
      monThu: "Mon-Thu",
      fri: "Fri",
      break: "Break",
      citizens: "Citizens reception",
      description1:
        "During the period of martial law in Ukraine, introduced in accordance with the Decree of the President" +
        ' of Ukraine dated February 24, 2022 No. 64/2022 "On the introduction of martial law in Ukraine" ' +
        "(as amended), the personal reception of citizens by officials of the National Security Service is " +
        "suspended, or, if possible, can take place in the video communication mode.",
      description2:
        "We draw your attention to the fact that, in the event that an air raid signal is announced during " +
        "a personal reception, the person responsible for organizing the reception will stop it and advise " +
        "the visitor to go to the places of civil defense protective structures.",
      description3:
        'At the same time, we note that in accordance with the Law of Ukraine "On Appeals of Citizens", ' +
        "you have the opportunity to send an electronic appeal to the address nspp{'@'}nspp.gov.ua or send " +
        "an appeal in writing to the following address: st. Baseina 1/2-A, Kyiv, 01024.",
      error: "Failed to load the departments. Try later",
      table: {
        caption: "Schedule of personal reception of citizens",
        heading: {
          position: "Position, full name",
          receptionDays: "Reception days",
          receptionTime: "Reception time",
        },
        row1: {
          position: "NMCS Head",
          name: "KUHNYUK Dmytro",
          receptionDays: "The second and fourth half of the month",
        },
        row2: {
          position: "NMCS First Deputy Chairman",
          name: "ZAPARA Svitlana",
          receptionDays: "First and third Wednesday of the month",
        },
        row3: {
          position: "NMCS Head of Staff",
          name: "MARCHUK Roman",
          receptionDays: "First and third Thursday of the month",
        },
      },
    },
    reportsPage: {
      title: "Activity reports",
      error: "Failed to load the reports. Try later",
    },
  },
  uk: {
    mission: {
      title: "Місія, візія, цінності",
      mission: "Місія",
      text: "Ми сприяємо здійсненню соціального діалогу та допомагаємо пройти шлях до порозуміння.",
      vision: "Візія",
      visionList: {
        item1: `Національна служба посередництва і примирення має широкі компетенції, значний досвід і
            розуміння цільової групи. Це забезпечує надійну основу для ефективної реалізації нашої
            соціальної місії. Ми створюємо умови для постійного розвитку Служби.`,
        item2: `НСПП застосовує орієнтований на майбутнє підхід до попередження виникнення та сприяння
            вирішенню колективних трудових спорів. Ми забезпечуємо високий рівень інклюзивності на
            всіх рівнях соціального діалогу з відповідною оцінкою потреб, ризиків і ресурсів.`,
        item3: `НСПП – постійно діючий державний орган, який працює злагоджено і гнучко, із спільним
            розумінням нашої соціальної місії. Ми є незалежним регулятором, що забезпечує рівне та
            неупереджене ставлення до учасників соціально-трудових відносин.`,
        item4: `Ми заохочуємо інноваціями та співпрацею і дотримуємося наших спільних цінностей.
            Посилюємо цифрову безпеку і використовуємо сучасні цифрові рішення та нові технології
            для навчання і розвитку.`,
        item5: `Діяльність НСПП ґрунтується на знаннях та професійному досвіді. Ми обʼєднуємо провідну
            наукову та освітню спільноту в сфері своєї діяльності і пропонуємо програми постійного
            професійного розвитку для наших співробітників, незалежних посередників (медіаторів) та
            трудових арбітрів. Ми – активні та відповідальні державні та публічні службовці, які
            діляться своїм професійним досвідом з іншими.`,
        item6: `НСПП має гарну репутацію і користується високим рівнем довіри та авторитету в
            суспільстві. Ми є сильним і помітним гравцем, який відіграє важливу роль у розробці
            політик у сфері нашої діяльності, і використовуємо ефективні та сучасні комунікаційні
            платформи.`,
        item7: "НСПП є престижною та почесною, а її службовці – гідними представниками.",
      },
      values: "Цінності",
      valuesList: {
        item1: "Неупередженість",
        item2: "Професіоналізм",
        item3: "Доброчесність",
        item4: "Об`єктивність",
        item5: "Довіра",
        item6: "Справедливість",
        item7: "Відкритість",
      },
    },
    history: {
      title: "Історія",
      p1: `Національна служба посередництва і примирення (НСПП, Служба) заснована 17 листопада 1998 року Указом Президента України «Про утворення Національної служби посередництва і примирення» відповідно до Закону України «Про порядок вирішення колективних трудових спорів (конфліктів)».`,
      p2: `Мета Служби – поліпшення стану трудових відносин, запобігання виникненню та сприяння вирішенню колективних трудових спорів.`,
      p3: `НСПП здійснює посередництво і примирення в конфліктних ситуаціях, сприяє досягненню компромісу між працівниками, профспілками та роботодавцями. А також забезпечує зближення позицій, досягнення спільних домовленостей та прийняття узгоджених рішень сторонами соціального діалогу.`,
      p4: `Під час створення НСПП враховувалися міжнародні стандарти праці та досвід інших демократичних країн із подібними службами, які у світі існували десятиліттями.`,
      p5: `Наприклад, Консультаційна служба арбітражу та примирення у Великій Британії була заснована у 1896 році, Національна служба примирення у Швеції була заснована у 1906 році, Федеральна служба посередництва і примирення у Сполучених Штатах Америки заснована у 1947 році, а у 1969 році було створено Службу з питань колективних трудових відносин у Бельгії.`,
      imgDescription1: `7 червня 1993 запам'ятався як день, коли відбувся наймасовіший страйк шахтарів Донбасу за всю історію України.`,
      p6: `Власне, створення Служби стало відповіддю на трудові конфлікти у 1990 роках між найманими працівниками, профспілками та роботодавцями. Високий рівень безробіття, відтік кваліфікованих працівників з різних секторів економіки, критичний стан державних підприємств, масові випадки заборгованості із виплати заробітної плати – ці фактори призвели до загострення протестних настроїв серед громадян.`,
      p7: "Країною ширилися акції соціальних протестів та страйки.",
      p8: `У відповідь на виклики того часу парламентом прийнято Закон України «Про порядок вирішення колективних трудових спорів (конфліктів)», яким передбачено існування Служби як постійно діючого державного органу, покликаного сприяти вирішенню колективних трудових спорів (конфліктів).`,
      p9: "З отриманням певного досвіду НСПП удосконалювала форми і методи роботи. Якщо на початку діяльності Служби акцентувалась увага на оперативному розв’язанні колективних трудових спорів, то надалі фахівці НСПП дійшли висновку, що вирішити спір важче і дорожче, ніж його попередити. Тому у розвиток статті 15 Закону України «Про порядок вирішення колективних трудових спорів (конфліктів)» НСПП розроблено та впроваджено комплексну систему правових заходів щодо запобігання виникненню колективних трудових спорів (конфліктів), врегулюванню розбіжностей між найманими працівниками та роботодавцями на доконфліктній стадії, недопущення ескалації конфліктних ситуацій.",
      imgDescription2:
        "Акція протесту працівників «Аеросвіту», 2014 рік. Ілюстративне фото з відкритих джерел.",
      imgDescription3: "Перший Голова НСПП В. Руденко. Фото з архіву НСПП.",
      p10: "Першим головою Служби з 1998 до 2006 року став Василь Руденко. З 2006 до 2022 року – Олександр Окіс. Наразі Головою НСПП є Дмитро Кухнюк, призначений на посаду Указом Президента України 14 вересня 2022 року.",
      p11: "Для ефективного вирішення колективних трудових спорів НСПП впровадила правову базу щодо функціонування в Україні трудових арбітрів та незалежних посередників.",
      p12: "З 2001 року розпочалася підготовка незалежних посередників на базі Національної академії внутрішніх справ у Києві та Національної юридичної академії імені Ярослава Мудрого у Харкові.",
      p13: `У рамках проєкту Міжнародної організації праці «Інклюзивний ринок праці для створення робочих місць в Україні», фінансованого Урядом Данії, Службою за сприяння експертів та тренерів Міжнародної організації праці у період 2019-2021 років було організовано підготовку тренерів та за їх участі проведено п'ять навчальних регіональних семінарів: «Посередництво у врегулюванні колективних трудових спорів». Слухачами цих семінарів були фахівці Служби, представники профспілок, організацій роботодавців, органів виконавчої влади та місцевого самоврядування, а також науковці. За результатами навчання ними отримано сертифікати посередників.`,
      imgDescription4:
        "Навчальний семінар: «Посередництво у врегулюванні колективних трудових спорів», 2019 рік. Фото із архіву НСПП.",
      imgDescription5: "Тренінг: «Медіація у трудових спорах», 2019 рік. Фото з архіву НСПП.",
      p14: "Наразі під егідою НСПП діють 256 трудових арбітрів та 100 незалежних посередників.",
      p15: "Одним із важливих напрямів роботи Служби є забезпечення здійснення соціального діалогу.",
      p16: `Відповідно до Закону України «Про соціальний діалог в Україні» НСПП здійснює оцінку відповідності критеріям репрезентативності профспілок та їх об'єднань, організацій роботодавців та їх об'єднань.`,
      p17: `Підтвердження репрезентативності – необхідна умова для участі сторін профспілок і роботодавців у соціальному діалозі.`,
      p18: `З метою розвитку соціального діалогу в Україні НСПП ініціює та бере участь у різних комунікаційних заходах.`,
      p19: "Одним із важливих напрямів роботи Служби є забезпечення здійснення соціального діалогу.",
      p20: `Відповідно до Закону України «Про соціальний діалог в Україні» НСПП здійснює оцінку відповідності критеріям репрезентативності профспілок та їх об'єднань, організацій роботодавців та їх об'єднань.`,
      p21: `Підтвердження репрезентативності – необхідна умова для участі сторін профспілок і роботодавців у соціальному діалозі.`,
      imgDescription6: `Вручення Голові Всеукраїнської професійної спілки працівників сфери вищої освіти Станіславу Яроцькому свідоцтва про визнання профспілки такою, що відповідає критеріям репрезентативності на галузевому рівні. Фото з архіву НСПП.`,
      imgDescription7: `Візит до штаб-квартири МОП (м. Женева) Голови НСПП Дмитра Кухнюка у складі тристоронньої делегації від України, 2023 рік. Фото із архіву НСПП.`,
      p22: "Одним із ключових партнерів НСПП є Міжнародна організація праці.",
      p23: `Так, у 2023 році в межах візиту тристоронньої делегації від України (представників уряду, репрезентативних на національному рівні профспілок та організацій-роботодавців) до штаб-квартири Міжнародної організації праці (Швейцарія, м. Женева) відбулось вивчення міжнародного досвіду з вдосконалення соціального діалогу та підвищення його ролі в Україні.`,
      p24: `Під час візиту українська тристороння делегація обговорювала шляхи посилення соціального діалогу, удосконалення трудового законодавства та впровадження міжнародних стандартів у сфері праці.`,
      imgDescription8: `Візит до штаб-квартири МОП (м. Женева) Голови НСПП Дмитра Кухнюка у складі тристоронньої делегації від України, 2023 рік. Фото з архіву НСПП.`,
      imgDescription9: "Навчальний візит у м.Турин (Італія), листопад 2023 року.",
      p25: `Також з метою навчання та обміну досвідом у листопаді 2023 року відбувся навчальний візит керівництва НСПП та керівників п’яти регіональних відділень у Волинській, Кіровоградській, Полтавській, Хмельницькій та Черкаській областях до м. Турин (Італія), де на базі Міжнародного навчального центру МОП відбулося два заходи:`,
      list: {
        item1: `тренінг «Створення ефективних систем запобігання та вирішення трудових спорів», що пройшов у рамках проєкту «Інклюзивний ринок праці для створення робочих місць в Україні»;`,
        item2: `семінар «Оцінка інституцій врегулювання трудових спорів», на якому, зокрема, було обговорено законопроєкт «Про колективні трудові спори» за участю міжнародних експертів.`,
      },
      p26: `Крім того, у Києві у листопаді 2023 року для працівників центрального апарату та керівників регіональних відділень НСПП було організовано семінар: «Соціальний діалог як інструмент порозуміння», який провів Аспен Інститут Київ, з метою обміну ідеями, думками та баченням при обговоренні запланованих до розгляду тем.`,
      imgDescription10: `Фото працівників НСПП. Семінар «Соціальний діалог як інструмент порозуміння», який провів Аспен Інститут Київ, листопад 2023 року.`,
      imgDescription11: "Семінар із стратегічного планування НСПП, м. Київ, грудень 2023 року.",
      p27: `У грудні цього ж року керівництво та працівники НСПП знову зустрілися на важливому та знаковому заході: дводенному семінарі із стратегічного планування НСПП – для спільного визначення місії, візії та цінностей Служби.`,
      p28: `Захід відбувся за підтримки МОП, де на основі напрацювань, отриманих у межах робочої поїздки до м. Турин, та з урахуванням рекомендацій МОП об’єднаними зусиллями і через спільне розуміння було сформовано місію, візію та цінності НСПП.`,
      p29: `У травні 2023 року Голова НСПП Дмитро Кухнюк, як залучений учасник конституційного провадження, представив позицію НСПП в Конституційному Суді України щодо особливостей позасудового та досудового порядків вирішення колективних трудових спорів (конфліктів) у межах розгляду справи за конституційною скаргою Всеукраїнської профспілки працівників науки, виробництва та фінансів ПАТ «Арселор Міттал Кривий Ріг».`,
      p30: "НСПП приділяє значну увагу вдосконаленню нормативно-правової бази.",
      imgDescription12:
        "Голова НСПП Дмитро Кухнюк у Конституційному Суді України, 2023 рік. Джерело – ccu.gov.ua",
      p31: `Так, за ініціативою НСПП були внесені зміни до Положення про НСПП, уточнені окремі положення та виключені застарілі і колізійні норми (Указ Президента України «Про внесення змін до Указу Президента України від 17 листопада 1998 року № 1258/98» №51/2023). А також затверджено офіційну символіку НСПП (Указ Президента України «Про символіку Національної служби посередництва і примирення» №52/2023).`,
      imgDescription13: "Малюнок емблеми НСПП",
      imgDescription14: "Малюнок прапора НСПП",
      p32: `За роки своєї діяльності НСПП відіграла важливу роль у створенні та вдосконаленні нормативно-правової бази для регулювання колективних трудових відносин та запобігання виникненню колективних трудових спорів (конфліктів). Зокрема, у лютому 2023 року було прийнято Закон України «Про колективні угоди і договори» над розробкою якого, в тому числі, працювали представники НСПП.`,
      p33: `Службою напрацьовано значний обсяг нормативно-правових документів, які були відцифровані та розміщені у публічному доступі на сайті НСПП.`,
      p34: `НСПП спільно з Міністерством економіки України, Спільним представницьким органом репрезентативних всеукраїнських об'єднань профспілок на національному рівні, Спільним представницьким органом сторони роботодавців на національному рівні та Національною тристоронньою соціально-економічною радою, науковцями, національними та міжнародними експертами розроблено проєкт Закону України «Про колективні трудові спори». Цей проєкт має на меті вдосконалити систему вирішення трудових спорів та забезпечити більш ефективний і демократичний підхід до регулювання конфліктів.`,
      p35: `Досвід роботи НСПП свідчить, що налагодження ефективного соціального діалогу та практичне вирішення колективних трудових спорів (конфліктів) вимагає залучення заінтересованих сторін до співпраці з НСПП.`,
      p36: `Службою налагоджено комунікацію з ключовими стейкхолдерами. Підписано меморандуми про співпрацю та взаємодію з провідними університетами, науковими та експертними установами, громадськими та професійними об’єднаннями.`,
      imgDescription15: `Підписання договору про співпрацю між Національною службою посередництва і примирення та Київським національним університетом імені Тараса Шевченка 2022 рік. Фото із архіву НСПП.`,
      imgDescription16: `Підписання Меморандуму про співпрацю між НСПП і НДІ правотворчості та науково-правових експертиз НАПрН України, 2023 рік. Фото з архіву.`,
      p37: "Останнім часом НСПП стає більш помітним фактором соціального діалогу.",
      p38: `Служба активно співпрацює з профспілками та організаціями роботодавців на всіх рівнях і посилює соціальний діалог, що є важливим в умовах воєнного стану. Адже позиції всіх зацікавлених сторін мають зближатися для того, щоб приймалися виважені рішення у соціально-трудовій сфері відповідно до викликів воєнного часу.`,
      p39: `Під час воєнного стану команда НСПП сфокусувалася на модернізації та оновленні Служби. Зокрема, приділивши увагу розвитку комунікації, законопроєктній роботі, цифровізації процесів та сервісів, налагодженню партнерських відносин із зацікавленими сторонами, створенню нового сайту, активізації роботи у напрямі розвитку соціального діалогу.`,
      p40: `Також Служба прагне бути відкритою та прозорою, тому ділиться інформацією на власних сторінках у соціальних мережах.`,
      p41: `Крім того, Служба посилила кадровий склад, однак разом з тим залишивши «інституційну пам'ять» кадрів – абсолютна більшість персоналу, висококваліфікованих та досвідчених працівників продовжили працювати в нашій команді.`,
      imgDescription17: ` Підписання Меморнадуму про співпрацю між НСПП та Координаційним центром з надання правової допомоги, 2023 рік. Фото з архіву НСПП.`,
      imgDescription18:
        "Працівники центрального апарату НСПП. День Вишиванки, 2023 рік. Фото із архіву НСПП.",
      p42: `Службою удосконалено нормативно-правові акти з питань репрезентативності та підтвердження репрезентативності на період дії воєнного стану.`,
      p43: `Зокрема, на виконання вимог пункту 5 розділу VI «Прикінцеві та перехідні положення» Закону України «Про колективні угоди та договори» було розроблено Порядок продовження строку дії свідоцтв про підтвердження репрезентативності, який поширюється на суб'єктів, які отримали раніше свідоцтва про підтвердження репрезентативності строк дії яких сплив під час дії воєнного стану. Порядок дає змогу сторонам соціального діалогу продовжити участь у соціальному діалозі під час дії воєнного стану та протягом шести місяців після його закінчення.`,
      p44: `Вищезазначені питання стали для Служби пріоритетними. Адже ефективний соціальний діалог – це запорука миру і злагоди в соціально-трудовій сфері та важливий чинник швидкого відновлення країни від наслідків війни.`,
      p45: `Досвід діяльності НСПП підтверджує важливість та продуктивність її ролі як авторитетного державного органу, що сприяє вирішенню розбіжностей між сторонами соціально-трудових відносин за допомогою цивілізованого правового підходу та розвитку соціального діалогу.`,
      imgDescription19: `Вручення розпорядження «Про продовження строку дії свідоцтва про підтвердження репрезентативності Об’єднання всеукраїнських автономних профспілок» голові Об’єднання всеукраїнських автономних профспілок, Профспілки працівників Збройних Сил України Валерію Добровольському. Фото з архіву НСПП.`,
    },
    stakeholders: {
      allStakeholdersLink: "Всі стейкхолдери",
      emptyResult: "Немає результатів",
      error: "Не вдалося завантажити стейкхолдерів. Спробуйте пізніше",
    },
    cooperation: {
      title: "Міжнародна співпраця",
      buttonText: "Детальніше",
      ilo: {
        header: "Міжнародна організація праці (МОП)",
        text: `У рамках реалізації Проєкту Міжнародної організації праці "Інклюзивний ринок праці для створення робочих місць в Україні", що фінансувався Урядом Данії у рамках Данської програми сусідства (DANEP) на 2017-2021 роки та Данської програми підтримки для України, НСПП за сприяння експертів та тренерів Міжнародної організації праці було проведено п'ять навчальних регіональних семінарів на тему: "Посередництво у врегулюванні колективних трудових спорів". За результатами навчальних семінарів 80 слухачів – фахівці НСПП та її відділень в областях, представники профспілок та організацій роботодавців, органів виконавчої влади та місцевого самоврядування, науковці – отримали сертифікати посередників. Спеціалісти МОП надали у 2021 році експертну підтримку НСПП у розробці проєкту закону «Про колективні трудові спори».`,
      },
      sida: {
        header: "SIDA - урядове агентство Міністерства закордонних справ Швеції",
        text: "SIDA - урядове агентство Міністерства закордонних справ Швеції, яке опікується розвитком співпраці у всьому світі. У 2013 році в межах реалізації шведсько-українського проекту «Вирішення трудових спорів в Україні» експертна група до складу якої входили працівники НСПП розробила Нову модель вирішення трудових спорів.",
      },
      usaid: {
        header: "Агентство США з міжнародного розвитку - USAID",
        text: "НСПП є бенефіциаром проєкту «Глобальна Трудова Програма – Нові Горизонти щодо захисту прав трудящих», згідно з яким Уряд США (Агенство США з міжнародного розвитку) реалізує цей проєкт, присвячений захисту прав трудящих.",
      },
      europe: {
        header: "Рада Європи",
        text: "В межах реалізації проєкту Ради Європи «Посилення соціального захисту в Україні» до роботи над проєктом Закону «Про колективні трудові спори» була запрошена експертка, д.ю.н., проф. Яна Симутіна.",
      },
      taiex: {
        header: "TAIEX (Technical Assistance and Information Exchange)",
        text: "В межах співпраці із TAIEX 14.12.2022 проведено семінар «Зарубіжний досвід регулювання страйків».",
      },
      nba: {
        header: "Нідерландська бізнес-академія",
        text: "НСПП з метою зміцнення дружніх відносин, налагодження двосторонньої співпраці співпрацює із Нідерландською бізнес-академією в межах укладеного меморандуму. Зокрема, у червні 2023 році ректор нідерландської бізнес академії Ян ван Звітен проводив тренінг щодо найкращого досвіду медіації в Нідерландах.",
      },
    },
    departments: {
      seeBiography: "Подивитися біографію",
      direction: "Керівництво",
      centralDepartment: "Центральний апарат",
      structuralDepartments: "Структурні підрозділи",
      regionalDepartments: "Регіональні відділення",
      address: "Адреса",
      phone: "Телефон",
      email: "Електронна адреса",
      antiCorruptionTitle: "Спеціальні уповноважені суб’єкти у сфері протидії корупції",
      nazk: "Національне агентство з питань запобігання корупції",
      gp: "Генеральна прокуратура України (Офіс Генерального прокурора)",
      nabu: "Національне антикорупційне бюро України",
      police: "Національна поліція України",
      workSchedule: "Графік роботи",
      monThu: "Пн-Чт",
      fri: "Пт",
      break: "Перерва",
      citizens: "Прийом громадян",
      description1:
        "На період дії воєнного стану в Україні, введеного відповідно до Указу Президента\n" +
        "              України від 24 лютого 2022 року № 64/2022 «Про введення воєнного стану в Україні» (зі\n" +
        "              змінами), проведення особистого прийому громадян посадовими особами НСПП\n" +
        "              призупиняється, або, за можливості, може відбуватися у режимі відеозв’язку.",
      description2:
        "Звертаємо вашу увагу, у разі якщо під час проведення особистого прийому оголошено\n" +
        "              сигнал повітряної тривоги, особа, відповідальна за організацію прийому, припиняє його\n" +
        "              проведення та рекомендує відвідувачу пройти до місць захисних споруд цивільного\n" +
        "              захисту.",
      description3:
        'Водночас зазначаємо, що відповідно до Закону України "Про звернення громадян" у Вас є\n' +
        "              можливість надіслати електронне звернення на адресу nspp{'@'}nspp.gov.ua або надіслати\n" +
        "              звернення у письмовій формі на адресу: вул. Басейна 1/2-А, м. Київ, 01024.",
      error: "Не вдалося завантажити відділення. Спробуйте пізніше",
      table: {
        caption: "ГРАФІК ОСОБИСТОГО ПРИЙОМУ ГРОМАДЯН",
        heading: {
          position: "Посада, ПІБ",
          receptionDays: "Дні прийому",
          receptionTime: "Час прийому",
        },
        row1: {
          position: "Голова НСПП",
          name: "КУХНЮК Дмитро Володимирович",
          receptionDays: "Другий та четвертий вівторок місяця",
        },
        row2: {
          position: "Перший заступник голови НСПП",
          name: "ЗАПАРА Світлана Іванівна",
          receptionDays: "Перша та третя середа місяця",
        },
        row3: {
          position: "Керівник апарату НСПП",
          name: "МАРЧУК Роман Валентинович",
          receptionDays: "Перший та третій четвер місяця",
        },
      },
    },
    reportsPage: {
      title: "Звіти про діяльність",
      error: "Не вдалося завантажити звіти. Спробуйте пізніше",
    },
  },
};
