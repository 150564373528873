import { BASE_URL, Locale } from "@/constants";
import { Person, PersonDetails, PersonQueryParams } from "@/interfaces";

export default {
  async getMediators(locale: Locale, queryParams: PersonQueryParams): Promise<Person[]> {
    const params = {
      ...(queryParams.name && { name: queryParams.name }),
      ...(queryParams.region && { region: queryParams.region }),
      ...(queryParams.formOfActivity && { formOfActivity: queryParams.formOfActivity }),
    };
    const response = await fetch(
      `${BASE_URL}mediators?language=${locale}&${new URLSearchParams(params)}`,
      { method: "GET" },
    );
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },

  async getMediator(id: number, locale: Locale): Promise<PersonDetails> {
    const response = await fetch(`${BASE_URL}mediators/${id}?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
