export default {
  en: {
    disputes: {
      columns: {
        order: "Order",
        registrationNumber: "Реєстраційний номер КТС(К)",
        numberAndDate: "Номер та дата розпорядження про реєстрацію КТС (к)",
        name: "Name of the order",
        requirements: "Requirements",
        stage: "Review stage",
      },
      error: "Failed to load Register of collective labor disputes. Try again later",
      searchByKeyWord: "Search by keyword",
      search: "Search",
      emptyResults: "No collective labor disputes were found for your request",
    },
    partiesEntities: {
      title: "Parties entities register",
      filters: {
        level: {
          title: "Level",
          default: "All levels",
          national: "national",
          industry: "industry",
          territorial: "territorial",
        },
        region: {
          title: "region",
          default: "All regions",
        },
        date: {
          title: "Acceptance date",
          placeholder: "Choose a date",
        },
      },
      columns: {
        order: "No.",
        organization: "Assessed organization",
        level: "Level",
        region: "Region",
        entityType: "Type of subject of social dialogue",
        legalAddress: "Legal address",
        dateNumberType: "Date, number and solution type",
        numberAndExpirationDate: "Certificate number and validity period",
      },
      error: "Failed to load the register. Try later",
      emptyResults:
        "According to your request, no subjects of parties of trade unions and employers' organizations were found",
      searchByKeyWord: "Search by key word",
    },
    persons: {
      certificate: "Certificate number and date of issue",
      error: "Failed to load list. Try again later",
      emptyResults: "No labor arbitrators were found for your request",
      formOfActivity: "Form of activity",
      region: "Region of activity",
      formOfActivitySelect: {
        all: "All forms",
        online: "Online",
        offline: "Offline",
      },
      location: "Region of activity",
      locationDefault: "All regions",
      searchByName: "Search by name",
      search: "Search",
      workExperience: "Work experience",
      noInfo: "No information",
      bio: {
        error: "Failed to load bio",
        biography: "Biography",
        education: "Education",
        employment: "Employment",
      },
    },
    independentMediators: {
      emptyResults: "No independent mediators were found for your request",
    },
    bulletin:
      "The Bulletin of the National Mediation and Conciliation Service is a publication that contains useful structured information related to the activities of the Service.",
    links: {
      scientificWorks: "Scientific works",
      reports: "Reports, analytical reports, ILO recommendations",
      CERecommendations: "Recommendations of the Committee of Ministers of the Council of Europe",
      authoritiesRecommendations:
        "Recommendations, analytical reference materials of authorities, parties of social dialogue",
      roundTables: "Round tables, conferences, speeches",
      presentations: "Information and presentation materials",
    },
  },
  uk: {
    disputes: {
      columns: {
        order: "№ з/п",
        registrationNumber: "Реєстраційний номер КТС(К)",
        numberAndDate: "Номер та дата розпорядження про реєстрацію КТС (к)",
        name: "Назва розпорядження",
        requirements: "Вимоги",
        stage: "Стадія розгляду",
      },
      error: "Не вдалося завантажити Реєстр колективних трудових спорів. Спробуйте пізніше",
      searchByKeyWord: "Пошук по ключовому слову",
      search: "Знайти",
      emptyResults: "За вашим запитом не знайдено колективних трудових спорів",
    },
    partiesEntities: {
      title: "Реєстр репрезентативних суб'єктів сторін профспілок та організацій роботодавців",
      filters: {
        level: {
          title: "Рівень",
          default: "Усі рівні",
          national: "національний",
          industry: "галузевий",
          territorial: "територіальний",
        },
        region: {
          title: "Область",
          default: "Усі області",
        },
        date: {
          title: "Дата прийняття",
          placeholder: "Обрати дату",
        },
      },
      columns: {
        order: "№ з/п",
        organization: "Організація, що пройшла оцінку",
        level: "Рівень",
        region: "Область",
        entityType: "Вид суб’єкта соціального діалогу",
        legalAddress: "Юридична адреса",
        dateNumberType: "Дата, номер та вид рішення",
        numberAndExpirationDate: "Номер та термін дії свідоцтва",
      },
      error: "Не вдалося завантажити реєстр. Спробуйте пізніше",
      emptyResults:
        "За вашим запитом не знайдено  суб’єктів сторін профспілок та організацій роботодавців",
      searchByKeyWord: "Пошук по ключовому слову",
    },
    persons: {
      certificate: "Номер та дата видачі свідоцтва",
      error: "Не вдалося завантажити список. Спробуйте пізніше",
      emptyResults: "За вашим запитом не знайдено трудових арбітрів",
      region: "Регіон діяльності",
      formOfActivity: "Форма діяльності",
      formOfActivitySelect: {
        all: "Усі форми",
        online: "Онлайн",
        offline: "Офлайн",
      },
      location: "Регіон здійснення діяльності",
      locationDefault: "Усі регіони",
      searchByName: "Пошук по імені",
      search: "Знайти",
      workExperience: "Стаж роботи",
      noInfo: "Немає інформації",
      bio: {
        error: "Не вдалося завантажити біографію",
        biography: "Біографія",
        education: "Освіта",
        employment: "Трудова діяльність",
      },
    },
    independentMediators: {
      emptyResults: "За вашим запитом не знайдено незалежних посередників",
    },
    bulletin:
      "Бюлетень Національної служби посередництва і примирення – видання, у якому міститься корисна структурована інформація, пов’язана із діяльністю Служби.",
    links: {
      scientificWorks: "Наукові праці",
      reports: "Звіти, аналітичні довідки, рекомендації МОП",
      CERecommendations: "Рекомендації Комітету Міністрів Ради Європи",
      authoritiesRecommendations:
        "Рекомендації, аналітично довідкові матеріали органів влади, сторін соціального діалогу",
      roundTables: "Круглі столи, конференції, виступи",
      presentations: "Інформаційно-презентаційні матеріали",
    },
  },
};
