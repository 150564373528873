export default {
  en: {
    disputesConflicts: {
      p: "According to Article 2 of the Law of Ukraine 'On the Procedure for Resolving Collective Labour Disputes (Conflicts)' dated March 3, 1998 No. 137/98-VR, a COLLECTIVE LABOUR DISPUTE is a disagreement that arises between the parties of social and labour relations regarding:",
      list: {
        item1:
          "establishing new or changing existing socio-economic working conditions and production life;",
        item2: "concluding or amending a collective agreement or contract;",
        item3: "fulfilling a collective agreement, contract, or individual provisions thereof;",
        item4: "non-compliance with labour legislation requirements.",
      },
    },
    trainingLaborArbitrators: {
      title1: {
        title: "Features of Training. Requirements for Labor Arbitrators and Independent Mediators",
        p: `<p>One of the important functions of the NMCS is the training of mediators and arbitrators specializing in the resolution of collective labor disputes (conflicts) and the formation of lists of arbitrators and mediators (subparagraphs 3-4 of part 6 of Article 15 of the Law).</p>
            <p>Training and professional development of mediators and arbitrators are carried out at the expense of the State Budget of Ukraine, as well as at the expense of individuals and legal entities.</p>
            <p>According to p. 1.3. of the Regulations on the Procedure for Training and Professional Development of Mediators and Arbitrators, approved by the order of the NMCS dated June 10, 2019 No. 41 (Regulations 41), mediators and arbitrators can be:<p>`,
        list: `<li>citizens of Ukraine who have higher education (at least a bachelor's or master's degree), have at least three years of experience in the profession of a lawyer, economist, financier, relevant knowledge in resolving collective labor disputes (conflicts) by conciliation commission and labor arbitration, have passed a qualification exam and an interview.</li>
              <li>employees of the NMCS and its departments in the Autonomous Republic of Crimea and regions (hereinafter referred to as departments), with at least one year of experience in the position under the above conditions with the consent of the Head of the NMCS.</li>`,
      },
      title2: {
        title: "Procedure for Training Labor Arbitrators and Independent Mediators",
        p: `<p>Training and professional development of mediators and arbitrators are carried out by a higher education institution of Ukraine with which the NMCS has concluded an agreement, according to educational plans and programs developed by the NMCS or the relevant educational institution in accordance with the typical programs of the Ministry of Education and Science of Ukraine and approved by the Head of the NMCS.</p>
            <p>Professional development of persons who have undergone training and received certificates of mediators and arbitrators can be carried out by the NMCS commission, involving independent experts or scientists, the composition of which is approved by the Head of the NMCS.</p>
            <p>Mediators and arbitrators from among the employees of the NMCS and its departments can improve their qualifications during seminars, meetings, trainings, and participation in conciliation procedures.</p>
            <p>Qualification exams to determine the level of knowledge on the issues of resolving collective labor disputes (conflicts) are taken by each candidate in the manner approved by the joint decision of the NMCS and the relevant educational institution.</p>
            <p>Mediator and arbitrator certificates are official documents that certify the right of a citizen of Ukraine to carry out conciliation procedures for resolving collective labor disputes (conflicts) as an independent mediator or a member of labor arbitration in Ukraine.</p>
            <p>The term of the certificate before the first extension of the mediator/arbitrator's powers is three years.</p>
            <p>The certificate of a mediator or arbitrator from among the employees of the NMCS and its departments is valid for the entire period of their work at the NMCS or its department.</p>
            <p>The formation of lists of mediators and arbitrators is carried out in accordance with the Regulations on the Procedure for Forming and Maintaining the List of Mediators, approved by the order of the NMCS dated April 4, 2000 No. 27 (with amendments made by the orders of the NMCS No. 27 dated January 29, 2003, No. 398 dated September 2, 2003, No. 39 dated March 24, 2005) (Regulations 27) and the Regulations on the Procedure for Forming and Maintaining the List of Arbitrators approved by the order of the NMCS dated April 4, 2000 No. 28 (with amendments made by the orders of the NMCS No. 28 dated January 29, 2003, No. 398 dated September 2, 2003, No. 39 dated March 24, 2005) (Regulations 28).</p>`,
      },
      title3: {
        title:
          "Procedure for Extending the Term of Independent Mediator and Labor Arbitrator Powers",
        p: `<p>The extension of the powers of a mediator and arbitrator, from among the persons who have undergone training or improved their qualifications and received certificates, is carried out by the NMCS commission, involving independent experts or scientists, the composition of which is approved by the Head of the NMCS.</p>
        <p>The extension of the powers of a mediator and arbitrator can be carried out during seminars, meetings, trainings, and participation in conciliation procedures.</p>
        <p>The decisions of the NMCS commission are formalized by a protocol, on the basis of which the Head of the NMCS makes a decision on the extension of the mediator and arbitrator's powers, usually for five years.</p>
        <p>Mediators and arbitrators who have successfully participated in the work of conciliation bodies at the national or sectoral level, the extension of powers is carried out by the submission of the NMCS commission and the decision of the Head of the NMCS, with a validity period of up to ten years.</p>`,
      },
      title4: {
        title: "Responsibility and Guarantees",
        p: `<p>The extension of the powers of a mediator and arbitrator, from among the persons who have undergone training or improved their qualifications and received certificates, is carried out by the NMCS commission, involving independent experts or scientists, the composition of which is approved by the Head of the NMCS.</p>
        <p>The extension of the powers of a mediator and arbitrator can be carried out during seminars, meetings, trainings, and participation in conciliation procedures.</p>
        <p>The decisions of the NMCS commission are formalized by a protocol, on the basis of which the Head of the NMCS makes a decision on the extension of the mediator and arbitrator's powers, usually for five years.</p>
        <p>Mediators and arbitrators who have successfully participated in the work of conciliation bodies at the national or sectoral level, the extension of powers is carried out by the submission of the NMCS commission and the decision of the Head of the NMCS, with a validity period of up to ten years.</p>
        <p>Independent mediators, members of conciliation commissions, and labor arbitrations during their work in conciliation bodies formed in accordance with the Law are guaranteed the preservation of their workplace (position) and average earnings, as well as the guarantees provided by the Labor Code of Ukraine for elected trade union workers, members of councils (boards) of enterprises, and councils of labor collectives.</p>
        <p>Independent mediators, members of conciliation commissions, and labor arbitrations are paid at least the average monthly salary and reimbursed for expenses related to participation in the conciliation procedure at the expense of the parties to the collective labor dispute (conflict) by agreement, and if the parties do not reach an agreement - in equal shares, in the manner and on the terms determined by the Regulations on the Procedure for Ensuring Guarantees to Independent Mediators, Members of Conciliation Commissions and Labor Arbitrations (labor arbitrators, approved by the order of the NMCS dated September 5, 2005 No. 93, with amendments made by the order of the NMCS dated July 13, 2012 No. 82 (Regulations 93) and the Regulations on the Procedure for Reimbursing Expenses Related to Participation in the Conciliation Procedure for Resolving a Collective Labor Dispute (Conflict) by an Independent Mediator, Members of the Conciliation Commission, and Labor Arbitration, approved by the order of the NMCS, the Ministry of Finance of Ukraine and the Ministry of Labor and Social Policy of Ukraine dated December 1, 1999 No. 116/308/210 (Regulations 116/308/210).</p>`,
      },
    },
    algorithm: {
      title1: {
        title: "Step 1. Preparation for dispute resolution",
        subtitle1: "1.1. Formation and approval of requirements",
        subtitle2: "1.2. Submission of requirements and their consideration by the employer",
        subtitle3:
          "1.3. The onset of a collective labor dispute and notification of the commencement of a collective labor dispute (conflict)",
        subtitle4:
          "1.4. Registration of demands raised by employees, trade unions, and the collective labor dispute (conflict)",
        subtitle5: "1.5. Regulatory framework",
        p1: `<p>Depending on the grounds for the demands provided for in Article 2 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)", the procedure for resolving a collective labor dispute changes.</p>
        <p>It is necessary to approach the formulation of demands very carefully, as the terms for resolving the collective labor dispute in a peaceful manner depend on this.</p>
        <p>Demands must be addressed to the appropriate employer's party, that is, to the authority or person competent to meet the demands of the workers.</p>
        <h4>1.1.1. At the Production Level</h4>
        <p>Approval of demands by employees or a trade union at the production level and determination of the authority or person who will represent their interests at a general meeting or conference is carried out as follows:</p>
        <ul>
          <li>Meetings are convened on the initiative of the elected trade union body or another body authorized by the employees or an initiative group of employees, as well as at the proposal of at least one-third of the total number of regular employees;</li>
          <li>Employee meetings are considered valid with more than half of the employees present, who are regular employees of the enterprise, institution, organization, a separate structural unit, or a specific category of employees;</li>
          <li>Conferences of employee representatives can be convened when it is difficult to convene meetings due to shift work or the territorial separation of workshops, departments, sections, and other structural units;</li>
          <li>The conference of employee representatives is considered valid if at least three-quarters of the elected employee representatives (conference delegates) participate;</li>
          <li>Alternatively, they are formed by collecting signatures and are considered valid with at least half of the signatures of the members of the labor collective of the enterprise, institution, organization, or their structural unit.</li>
        </ul>
        <p>Demands by employees, trade unions, or trade union associations are documented in a relevant protocol and sent to the employer's party.</p>
        <p>In cases where it is difficult to convene a general meeting of employees due to shift work or the territorial separation of workshops, departments, and other structural units, a conference of employees should be convened with proper representation norms.</p>
        <p>The initiative to convene meetings or conferences is communicated to employees no later than 7 days before their holding, indicating the time, place, and issues to be discussed.</p>
        <p>All regular employees can participate in the meetings.</p>
        <p>The employer's party, representatives of local executive authorities, local self-government bodies, employees of the National Mediation and Conciliation Service, and other representatives of public associations (with the consent of the participants of the general meeting or conference) have the right to participate in the meetings.</p>
        <p>Decisions of general meetings (conferences) of employees are made by open or secret voting and are considered valid if at least half of the working members of the labor collective of the enterprise, institution, organization, or their separate structural unit voted for them.</p>
        <p>A protocol is drawn up based on the results of the meetings (conference), signed by the chairman and secretary of the meeting (conference). The protocol of the meeting (conference) includes materials for registering participants (a list of employees present at the meeting or a list of employee representatives and the protocol (conclusion) of the mandate (counting) commission).</p>
        <p>Approval of employees' demands and determination of the authority or person who will represent their interests can also be carried out by collecting signatures.</p>
        <p>The trade union body or an initiative group of employees prepares signature sheets, which specify the demands proposed by the employees and the authority or person proposed to represent the employees' interests. Employees review the proposals and voluntarily sign the signature sheet.</p>
        <p>After collecting the signatures of employees, the results are summarized by the body authorized to represent the interests of the employees, and a protocol is drawn up.</p>
        <p>The demands of employees are considered valid with at least half of the signatures of the members of the labor collective of the enterprise, institution, organization, or their structural unit or a specific category of employees.</p>
        <h4>1.1.2. At the Sectoral, Territorial, or National Levels</h4>
        <p>Approval of demands by employees or a trade union at the sectoral, territorial, or national levels and determination of the authority or person who will represent their interests is carried out as follows:</p>
        <ul>
          <li>In cases where the interests of employees are represented by a trade union, a trade union association - by a decision of the elected body of the relevant trade union or trade union association;</li>
          <li>Demands of employees, trade unions, or trade union associations are documented in a relevant protocol and sent to the employer's party;</li>
          <li>In cases where the interests of employees are represented by other authorized organizations (bodies) - by a conference of representatives of enterprises, institutions, organizations elected by meetings (conferences) of employees of enterprises, institutions, organizations that are in a state of labor dispute (conflict).</li>
        </ul>
        <p>The conference of employee representatives is considered valid if at least three-quarters of the elected employee representatives participate.</p>
        <p>Approval of the demands of employees and determination of the authority or person who will represent their interests by the conference of representatives of enterprises, institutions, organizations is carried out as follows:</p>
        <ul>
          <li>The conference is convened on the initiative of the elected trade union body or another body authorized by the employees or an initiative group of employees;</li>
          <li>No later than two weeks before the conference, the initiator informs the employees of enterprises, institutions, organizations of the relevant sector or territory about the time, place of the conference, and the issues to be discussed;</li>
          <li>For holding the conference of representatives of enterprises, institutions, organizations of the relevant sector or territory, representation norms are established.</li>
        </ul>
        <p>Decisions of the conference of representatives of enterprises, institutions, organizations are made by open or secret voting and are considered valid if at least half of the representatives (delegates of the conference) representing the employees of enterprises, institutions, organizations that are in a state of labor dispute (conflict) and delegated these representatives to the conference voted for them.</p>
        <p>A protocol is drawn up based on the results of the conference, signed by the chairman and secretary of the conference. The protocol includes materials for registering participants (a list of employee representatives who participated in the conference and the protocol (conclusion) of the mandate (counting) commission).</p>
        <p>The demands of the trade union are documented in a relevant protocol of the meeting of the elected body of the trade union.</p>`,
        p2: `<h4>1.2.1. Submission of Demands</h4>
        <p>
          The demands of employees, trade unions, or trade union associations are documented in a relevant protocol and sent to the employer's party.
        </p>
        <p>
          It is important to record the date of delivery of the demands to the employer's party (in the journal or other registration forms of incoming correspondence, or in the form of the employer's party's signature on the second copy of the demands, which remains with the employees' representative, or in a postal document (receipt) confirming the sending of the demands by registered mail).
        </p>
        <p>
          The time for considering the demands begins from the moment the employer's party receives the demands.
        </p>
        <p>
          The day of receiving the demands is considered the day of their registration in the journal or book of incoming correspondence, which is maintained by the relevant structural unit at the enterprise, institution, organization, or directly by the party to the dispute, as defined by law.
        </p>
        <p>
          During the registration of demands received for consideration by the employer's party, the protocol documenting the demands is marked with the date of registration and the incoming number.
        </p>
        <p>
          In case the demands are received by mail as a registered letter with a return receipt, the day of receipt of the demands is considered the day the employer's party receives the corresponding envelope (with the demands enclosed), which will be confirmed by the personal signature of the employer's party or the signature of the employee responsible for record keeping on the postal receipt. Entries in the journal of incoming documentation are made on the day the postal item is actually received.
        </p>

        <h4>1.2.2. Timeframes for Considering Demands</h4>
        <p>
          According to Article 5 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)," the employer's party is obliged to consider the demands of employees, categories of employees, the collective of employees, or the trade union and inform their representatives of its decision within three days from the day of receipt of the demands (this period is counted from the day following the day the period begins. If the last day of the period falls on a holiday, weekend, or non-working day, the last day is considered the next working day. The last day of the period lasts until 24 hours. The period is not considered missed if the necessary documents are submitted to the post office before it ends).
        </p>
        <p>
          If the satisfaction of the demands goes beyond the competence of the employer's party, they are obliged to send them within three days from the day of receipt of the demands to the owner or to the relevant higher management body that has the right to make a decision. At the same time, the consideration period for employees' demands by each instance should not exceed three days.
        </p>
        <p>
          The total time for considering the demands and making a decision (taking into account the time for mailing) should not exceed thirty days from the day the demands are received by the employer's party until the employees or trade union receive a notification from the employer's party about the decision made.
        </p>
        <p>
          The decision of the employer's party must be well-reasoned, contain answers to all questions posed in the demands, and provide information on the satisfaction or partial satisfaction of the employees' or trade union's demands, or a full or partial refusal to satisfy the collective demands.
        </p>
        <p>
          The decision of the employer's party is presented in written form and no later than the next day is sent to the authorized representative body of the other party of the collective labor dispute (conflict) along with the socio-economic justification.
        </p>`,
        p3: `<h4>1.3.1. Emergence of a Collective Labor Dispute</h4>
          <p>A collective labor dispute (conflict) arises from the moment:</p>
          <ul>
            <li>
              When the authorized representative body of the employees, a category of employees, the employees' collective, or the trade union receives from the employer's party a notice of full or partial refusal to satisfy the collective demands and makes a decision to disagree with the decision.
            </li>
            <li>
              When the deadlines for considering the demands, as stipulated by the law, have expired, and no response has been received from the employer's party.
            </li>
          </ul>

          <h4>1.3.2. Notification of Entry into a Collective Labor Dispute (Conflict)</h4>
          <p>
            Upon the emergence of a collective labor dispute, the body representing the interests of the employees or the trade union is obliged within three days to inform in writing:
          </p>
          <ul>
            <li>The employer;</li>
            <li>The central executive authority;</li>
            <li>The local executive authority;</li>
            <li>The local self-government body at the location of the enterprise;</li>
            <li>
              The district, regional, or sectoral trade union organization (depending on the level of the collective labor dispute);
            </li>
            <li>The National Mediation and Reconciliation Service (regional branches).</li>
          </ul>
          <p>
            In cases stipulated by the normative legal acts of the National Mediation and Reconciliation Service, the registration of a collective labor dispute may be suspended or the registration may be denied.
          </p>`,
        p4: `<h4>1.4.1. Registration of a Collective Labor Dispute (Conflict)</h4>
          <p>
            The authorized representative body (person) of the employees (a specific category of employees) or the trade union, after making a decision to enter into a collective labor dispute (conflict), within three days sends a written notice to the National Mediation and Reconciliation Service (NMSP) about the emergence of a collective labor dispute (conflict), which includes:
          </p>
          <ul>
            <li>The parties to the collective labor dispute (conflict) with their full names and addresses (legal addresses);</li>
            <li>The demands of the employees (a specific category of employees), the trade union;</li>
            <li>The moment of the emergence of the collective labor dispute (conflict);</li>
            <li>The body or person who will represent the interests of the employees (a specific category of employees);</li>
            <li>
              The total number of employees (a specific category of employees) at the enterprise, institution, or organization, or their structural units where the collective labor dispute (conflict) arose, and the number of employees participating in this collective labor dispute (conflict).
            </li>
          </ul>
          <p>
            Together with the written notice about the emergence of the collective labor dispute (conflict), the authorized representative body (person) of the employees (a specific category of employees) or the trade union sends to the Head of the NMSP or its corresponding branch in the Autonomous Republic of Crimea and regions (hereinafter referred to as the NMSP branch) the following attachments:
          </p>
          <ul>
            <li>The original signed lists and protocols;</li>
            <li>
              Originals or properly certified copies of letters, orders, extracts, certificates, etc., that confirm the provided information.
            </li>
          </ul>
          <p>
            The written notice about the emergence of the collective labor dispute (conflict) is sent to:
          </p>
          <ul>
            <li>At the national and sectoral levels - to the Head of the NMSP;</li>
            <li>At the production and territorial levels - to the NMSP branches.</li>
          </ul>
          <p>
            The written notice about the emergence of the collective labor dispute (conflict) is registered in the prescribed manner in the incoming documents logs and forwarded for consideration to the Head of the NMSP, the head of the NMSP branch for issuing the appropriate order.
          </p>
          <p>
            Based on the results of the review of the written notice about the emergence of the collective labor dispute (conflict) at the production and territorial levels, the NMSP branch prepares and sends no later than the next day (if the deadline falls on a weekend, holiday, or other non-working day, the last day of the term is the first working day), a submission to the Head of the NMSP with an attachment of copies of the corresponding written notice from the party of the collective labor dispute (conflict) and copies of protocols, letters, orders, extracts, certificates, etc., confirming the provided information:
          </p>
          <ul>
            <li>On the registration of the demands put forward by the employees (a specific category of employees) or the trade union and the collective labor dispute (conflict);</li>
            <li>On the suspension of the registration of the demands put forward by the employees (a specific category of employees) or the trade union and the collective labor dispute (conflict);</li>
            <li>On the refusal to register the demands put forward by the employees (a specific category of employees) or the trade union and the collective labor dispute (conflict).</li>
          </ul>
          <p>
            At the request of the Head of the NMSP, the NMSP branch sends additional information necessary for the registration, suspension of registration, or refusal to register the demands and the collective labor dispute (conflict).
          </p>
          <p>
            The registration of the demands put forward by the employees (a specific category of employees) or the trade union and the collective labor dispute (conflict) is carried out by the NMSP order, which specifies:
          </p>
          <ul>
            <li>The date and number of the NMSP order;</li>
            <li>The title of the NMSP order;</li>
            <li>The fact of registration of the collective labor dispute (conflict);</li>
            <li>The registration number of the collective labor dispute (conflict);</li>
            <li>
              The fact of registration of the demands put forward by the employees (a specific category of employees), the trade union;
            </li>
            <li>
              The decision to refuse to register certain demands (parts of the demands) of the employees (a specific category of employees), the trade union (if necessary);
            </li>
            <li>
              The name of the authorized representative body (person) of the employees (a specific category of employees) or the trade union, the employer or the person authorized by him, the employers' organization, the association of employers' organizations, legal entities, to whom the NMSP order is sent (local self-government body, local executive authority, central executive authority, under whose management the relevant enterprise, institution, or organization belongs, NMSP branch).
            </li>
          </ul>`,
        p5: `<ol>
          <li>Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)"</li>
          <li>
            Provisions "On the National Mediation and Reconciliation Service," approved by the Presidential Decree of Ukraine dated 17.11.98 No. 1258/98, with amendments
          </li>
          <li>
            NMSP Order No. 43 dated 21.06.2019 "On Approval of the Provisions on the Procedure for Formulating and Approving Demands of Employees, Trade Unions" with amendments
          </li>
          <li>
            NMSP Order No. 28 dated 13.08.2020 "On Approval of the Provisions on the Procedure for Registration by the NMSP of Demands Made by Employees, Trade Unions, and Collective Labor Disputes (Conflicts)" with amendments
          </li>
        </ol>`,
      },
      title2: {
        title: "Step 2. Options for Resolving Collective Labor Disputes (Conflicts)",
        subtitle1:
          "2.1. The sequence of reviewing and resolving a collective labor dispute (conflict).",
        subtitle2: "2.2. Conciliation Commission",
        subtitle3: "2.3. Involvement of an independent mediator in the resolution of the dispute",
        subtitle4: "2.4. Labor arbitration",
        subtitle5: "2.5. The right to strike",
        subtitle6: "2.6. Appeal to the court",
        p1: ` <p>
            The procedure for reviewing and resolving a collective labor dispute (conflict) is determined by
            Articles 7-14 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes
            (Conflicts)."
          </p>
          <p>Collective labor disputes (conflicts) are reviewed on the issues provided for:</p>
          <ul>
              <li>
                  by points "a" and "b" of Article 2 of the Law - by a conciliation commission, and in the absence of a decision within the time limits established by Article 9 of the Law - by labor arbitration;
              </li>
              <li>by points "c" and "d" of Article 2 of the Law - by labor arbitration.</li>
          </ul>
          <p>
              However, according to the Regulations on the Conciliation Commission, approved by the Order of the NSLC dated
              01.12.2020 No. 67 (Regulation 67), by agreement of the parties to the collective labor dispute (conflict),
              the demands of employees on the issues of fulfilling a collective agreement, contract, or individual provisions thereof, and the failure to comply with labor legislation, may be considered by the conciliation commission.
          </p>
          <p>
              Moreover, according to the provisions of part 2 of Article 13 of the Law, Convention of the International
              Labour Organization No. 154 of 1981 "On the Promotion of Collective Bargaining" (ratified by the Resolution of the Verkhovna Rada of Ukraine dated 04.02.1994 No. 3932-XII), Recommendation of the International
              Labour Organization No. 92 of 1951 "On Voluntary Conciliation and Arbitration," Recommendation of the International Labour Organization No. 130 of 1967 "On the Settlement of Disputes at the Enterprise Level with a View to their Settlement" on the rights and obligations of the parties to collective labor disputes (conflicts) and the sequence of conciliation procedures in terms of the parties' obligation to use all possibilities not prohibited by law to resolve a collective labor dispute (conflict), the parties to collective labor disputes (conflicts) may, by mutual agreement, establish a conciliation commission to resolve collective labor disputes (conflicts) arising on issues of:
          </p>
          <ul>
              <li>fulfilling a collective agreement, contract, or individual provisions thereof;</li>
              <li>failure to comply with labor legislation.</li>
          </ul>
          <p>
              Neither party to a collective labor dispute (conflict) can evade participation in the conciliation procedure.
          </p>`,
        p2: `<p>
            The conciliation commission is an organ designed to develop a decision that may satisfy the parties
            to a collective labor dispute (conflict) and is composed of representatives of the parties.
          </p>
          <p>The conciliation commission is established at the initiative of one of the parties:</p>
          <ul>
              <li>at the production level - within three days,</li>
              <li>at the sectoral or territorial level - within five days,</li>
              <li>
                  at the national level - within ten days from the moment of the collective labor
                  dispute (conflict) arising, from an equal number of representatives of the parties.
              </li>
          </ul>
          <p>
              The procedure for determining representatives to the conciliation commission is determined independently by each of the parties to the collective labor dispute (conflict).
          </p>
          <p>
              During the negotiations and preparation of the decision by the conciliation commission, its members are given time off from work.
          </p>
          <p>If necessary, the conciliation commission:</p>
          <ul>
              <li>includes an independent mediator;</li>
              <li>
                  consults with the parties to the collective labor dispute (conflict), central and
                  local executive authorities, local self-government bodies, and other
                  interested bodies.
              </li>
          </ul>
                <p>
              Organizational and material-technical support for the work of the conciliation commission is provided by
              agreement of the parties, and if the parties have not reached an agreement - in equal shares.
          </p>
          <p>
              The parties to the collective labor dispute (conflict) are obliged to provide the conciliation commission
              with the information necessary for conducting negotiations.
          </p>
          <p>
              Members of the conciliation commission do not have the right to disclose information that constitutes a state or other secret protected by law.
          </p>
          <p>
              Collective labor disputes (conflicts) are reviewed by the production conciliation commission within
              five days, by the sectoral and territorial conciliation commissions - within ten days, and by the conciliation commission at the national level - within fifteen days from the moment of the commissions' creation. By agreement of the parties, these terms may be extended.
          </p>
          <p>
              The decision of the conciliation commission is formalized by a protocol and is binding on the parties and is executed in the manner and within the time limits established by this decision.
          </p>
          <p>
              After a decision on resolving the collective labor dispute (conflict) is made, the conciliation
              commission ceases its work.
          </p>
          <p>
              The conciliation commission is established and considers the demands of employees or trade unions in
              accordance with Regulation 67 and the Rules of Procedure of the Conciliation Commission for Reviewing and Resolving Collective Labor Disputes (Conflicts), approved by the Order of the NSLC dated August 8, 2008,
              No. 94 (Regulation 94).
          </p>
          <p>
              At the request of the parties, the NSLC may involve members of the Ukrainian Parliament, representatives of government bodies, and local self-government bodies in the work of the conciliation commission, in accordance with the Regulations on the Procedure for Involving Members of the Ukrainian Parliament, Representatives of Government Bodies and Local Self-Government Bodies in Conciliation Procedures for Resolving Collective Labor Disputes (Conflicts) dated 05.05.2003 No. 178, as amended (Regulation 178).
          </p>`,
        p3: `<p>
            If necessary, the conciliation commission includes an independent mediator. The status of
            an independent mediator is defined by Article 10 of the Law and the Regulation on the Mediator,
            approved by the NSLC dated 18.11.2008 No. 133 (Regulation 133).
          </p>
          <p>
              A mediator is a person trained by the NSLC who, in the event of being chosen by mutual agreement of the parties
              to the collective labor dispute (conflict), acquires the status of an independent mediator.
          </p>
          <p>
              An independent mediator is a person chosen by mutual agreement of the parties to the collective labor dispute
              (conflict), who facilitates the interaction between the parties, conducts negotiations,
              and participates in developing a mutually acceptable decision by the conciliation commission. The purpose and scope of the independent mediator's activities are defined in Section 1 of Regulation 133.
          </p>
          <p>
              According to part 1 of Article 16 of the Law, the NSLC, at the request of the parties to the collective labor dispute
              (conflict), proposes candidates for independent mediators.
          </p>
          <p>
              The procedure for involving an independent mediator in the work of the conciliation commission and resolving
              a collective labor dispute (conflict) by the conciliation commission with the participation of an independent
              mediator is defined in Section 3 of Regulation 133 and the Regulation on the Procedure for Reviewing
              Collective Labor Disputes (Conflicts) by the Conciliation Commission with the Participation of an Independent
              Mediator, approved by the NSLC order dated 11.05.2004 No. 71 (Regulation 71).
          </p>`,
        p4: `<p>
              Labor arbitration is an organ composed of specialists, experts, and other persons engaged by the parties, and makes decisions on the merits of the labor dispute (conflict).
          </p>
          <p>
              Labor arbitration is established at the initiative of one of the parties or an independent mediator within three days in case of:
          </p>
          <ul>
              <li>
                  the failure of the conciliation commission to reach an agreed decision on resolving the collective labor dispute
                  (conflict) on the issues specified in points "a" and "b" of Article 2 of this Law;
              </li>
              <li>
                  the occurrence of a collective labor dispute (conflict) on the issues specified in points "c" and "d" of Article 2 of this Law.
              </li>
          </ul>
                <p>
              The quantitative and personal composition of the labor arbitration is determined by mutual agreement of the parties. The chairperson of the labor arbitration is elected from among its members.
          </p>
          <p>
              Members of the Ukrainian Parliament, representatives of government bodies, local self-government bodies, and other persons may also be included in the labor arbitration.
          </p>
          <p>
              Organizational and material-technical support for the work of labor arbitration is provided by
              agreement of the parties, and if the parties have not reached an agreement - in equal shares.
          </p>
                <p>
              The collective labor dispute (conflict) is reviewed by labor arbitration with the mandatory participation
              of the representatives of the parties, and if necessary - representatives of other interested bodies and organizations.
          </p>
          <p>
              Labor arbitration must make a decision within ten days from the day of its creation. By decision of the majority of the members of the labor arbitration, this period may be extended to twenty days.
          </p>
              <p>
            The decision of the labor arbitration is made by a majority vote of its members, documented in a protocol, and signed by all its members.
          </p>
          <p>
            Members of the labor arbitration are not allowed to disclose information that is a state secret or other secrets protected by law.
          </p>
          <p>
            The decision of the labor arbitration on the resolution of a collective labor dispute (conflict) is binding if the parties have previously agreed to this.
          </p>
          <p>
            The procedure for forming and operating the labor arbitration is regulated by the Regulation on the Arbitrator, registered by the NSPP order No. 132 dated 18.11.2008 (Regulation132), and the Regulation on Labor Arbitration, registered by the NSPP order No. 68 dated 01/12/2020 (Regulation68).
          </p>`,
        p5: `<h4>2.5.1. Exercising the Right to Strike</h4>
          <p>
            According to Article 44 of the Constitution of Ukraine, workers have the right to strike to protect their economic and social interests.
          </p>
          <p>The procedure for exercising the right to strike is established by law.</p>
          <p>
            A strike is a temporary collective voluntary cessation of work by employees (absence from work, non-performance of their labor duties) at an enterprise, institution, or organization (or structural subdivision) to resolve a collective labor dispute (conflict).
          </p>
          <p>
            A strike is used as a last resort (when all other possibilities are exhausted) to resolve a collective labor dispute (conflict) due to the refusal of the employer or an authorized person, an employers' organization, or an association of employers' organizations to satisfy the demands of the employees or their authorized body, trade union, trade union association, or its authorized body.
          </p>
          <p>
            A strike may begin if reconciliation procedures have not resolved the collective labor dispute (conflict) or if the employer, an authorized person, an employers' organization, or an association of employers' organizations avoids reconciliation procedures or does not fulfill an agreement reached during the resolution of the collective labor dispute (conflict).
          </p>
          <p>
            The procedure for declaring, conducting a strike, and resolving a collective labor dispute (conflict) during a strike is regulated by the Regulation on the Procedure for Conducting a Strike as a Last Resort for Resolving a Collective Labor Dispute (Conflict) and Reconciliation Procedures During a Strike, approved by the NSPP Order No. 131 dated 18.11.2008 (Regulation131), and the Rules of Conduct During a Strike provided by the Rules of Conduct for Employees, Trade Union Bodies, Other Authorized Bodies, Employers or Their Authorized Representatives, and the Body (Person) Leading the Strike, approved by the NSPP Order No. 66 dated 28.04.2004, with amendments from 07.02.2005 (Rules66).
          </p>

          <h4>2.5.2. Decision to Declare a Strike</h4>
          <p>
            The decision to declare a strike at an enterprise is made upon submission by the elected body of the primary trade union organization (trade union representative) or another organization of employees authorized under Article 3 of the Law to represent the interests of the employees, at a general meeting (conference) of the employees through voting and is considered adopted if the majority of employees or two-thirds of the conference delegates vote in favor. The decision to declare a strike is documented in a protocol.
          </p>
          <p>
            Recommendations regarding the declaration or non-declaration of a sectoral or territorial strike are made at the sectoral or territorial level during a conference, meeting, plenary session, or another elected body of employee representatives and/or trade unions and are sent to the relevant labor collectives or trade unions.
          </p>
          <p>
            Employees of enterprises in the sector or administrative-territorial units independently decide whether to declare a strike at their enterprise.
          </p>
          <p>
            A strike is considered sectoral or territorial if the number of employees at enterprises where the strike has been declared exceeds half of the total number of employees in the relevant sector or territory.
          </p>
          <p>No one can be forced to participate or not participate in a strike.</p>
          <p>
            The body (person) leading the strike is required to notify the employer or an authorized person, an employers' organization, or an association of employers' organizations in writing at least seven days before the strike begins, and in the case of a decision to strike at a continuously operating production, at least fifteen days in advance.
          </p>
          <p>
            The employer or an authorized person, employers' organization, or association of employers' organizations is obligated to promptly notify suppliers and consumers, transport organizations, as well as other interested enterprises, institutions, and organizations about the employees' decision to declare a strike.
          </p>
          <p>
            The location of employees participating in the strike is determined by the body (person) managing the strike, in agreement with the employer.
          </p>
          <p>
            In the event of meetings, rallies, or pickets outside the enterprise, the body (person) leading the strike must notify the local executive authority or local self-government body at least three days in advance of the planned event.
          </p>
          <p>
            The employer, local executive authority or local self-government body, and the body (person) leading the strike are required to take the necessary measures during the strike to ensure the viability of the enterprise, preserve property, maintain law and order, and prevent threats to life and health, as well as to the environment.
          </p>
          <h4>2.5.3. Leadership of the Strike</h4>
          <p>
            The strike at an enterprise is led by a body (or person) determined by the general meeting (conference) of employees when the decision to declare a strike is made.
          </p>
          <p>
            A sectoral or territorial strike is led (coordinated) by a body (or person) designated by the conference, meeting, plenary session, or another elected body of employee representatives, trade unions, or other worker organizations authorized to represent the relevant labor collectives.
          </p>
          <p>
            The body (or person) leading the strike operates within the rights provided by this Law during the strike and informs the employees about the progress of resolving the collective labor dispute (conflict).
          </p>
          <p>
            The authority of the body (or person) as the strike leader ceases if the parties sign an agreement to resolve the collective labor dispute (conflict), as well as if a decision is made to cancel or end the strike.
          </p>

          <h4>2.5.4. Resolving the Collective Labor Dispute (Conflict) During the Strike</h4>
          <p>
            During the strike, the parties to the collective labor dispute (conflict) are required to continue seeking ways to resolve it, using all available opportunities.
          </p>
          <p>
            The agreement to resolve the collective labor dispute (conflict) is signed by the leader or another authorized representative of the body leading the strike and the employer or an authorized person, the head of the employers' organization, or an association of employers' organizations.
          </p>
          <p>
            The parties to the collective labor dispute (conflict) or their authorized bodies (persons) are responsible for monitoring the implementation of the terms of this agreement.
          </p>

          <h4>2.5.5. Declaring a Strike Illegal</h4>
          <p>Strikes are considered illegal if they are:</p>
          <ul>
            <li>
              Declared with demands to change the constitutional order, state borders, and administrative-territorial structure of Ukraine, or with demands that violate human rights;
            </li>
            <li>
              Declared without adherence by employees, trade unions, union associations, or their authorized bodies to the provisions of Articles 2, 4, 6, parts one and five of Article 12, and parts one, three, and six of Article 19 of the Law;
            </li>
            <li>
              Started in violation by employees, trade unions, union associations, or their authorized bodies of the requirements of Article 20, and parts two and three of Article 24 of the Law;
            </li>
            <li>
              Declared and/or conducted during the reconciliation procedures provided for by this Law.
            </li>
          </ul>
          <p>
            The employer or their authorized person, employers' organization, or association of employers' organizations may file a petition to declare a strike illegal, which will be considered in court.
          </p>
          <p>
            The court must consider the case, including the time for preparing the case for trial, within seven days.
          </p>
          <p>
            A court decision declaring a strike illegal obliges the strike participants to decide to cease or cancel the declared strike, and employees must resume work no later than the next day after the court's decision is delivered to the body (or person) leading the strike.
          </p>
          <h4>2.5.6. Prohibition of Strikes</h4>
          <p>
            Strikes are prohibited if the cessation of work by employees poses a threat to human life and health, the environment, or impedes the prevention of natural disasters, accidents, catastrophes, epidemics, and epizootics or the elimination of their consequences.
          </p>
          <p>
            Strikes are also prohibited for employees (except for technical and service staff) of the prosecutor's office, courts, the Armed Forces of Ukraine, state authorities, security, and law enforcement agencies.
          </p>
          <p>
            In the event of a state of emergency, the Verkhovna Rada of Ukraine or the President of Ukraine may prohibit strikes for a period not exceeding one month. Any further prohibition must be approved by a joint act of the Verkhovna Rada and the President of Ukraine. In the event of the declaration of martial law, a strike ban automatically comes into effect until it is lifted.
          </p>
          <p>
            The following categories of workers are legally prohibited from striking:
          </p>
          <ul>
            <li>Public servants (Article 10 of the Law of Ukraine "On Public Service");</li>
            <li>
              Officials of local self-government bodies (Article 12 of the Law of Ukraine "On Service in Local Self-Government Bodies");
            </li>
            <li>
              Diplomatic service employees (Article 31 of the Law of Ukraine "On Diplomatic Service");
            </li>
            <li>Police officers (Article 61 of the Law of Ukraine "On National Police");</li>
            <li>
              Armed Forces of Ukraine employees (Article 17 of the Law of Ukraine "On the Armed Forces of Ukraine");
            </li>
            <li>
              Military personnel (Article 5 of the Law of Ukraine "On Social and Legal Protection of Military Personnel");
            </li>
            <li>
              Officers and staff of civil protection services, professional emergency rescue services employees (Article 116 of the Civil Protection Code of Ukraine);
            </li>
            <li>Emergency rescue services employees (Article 29 of the Mining Law of Ukraine);</li>
            <li>
              Citizens assigned to alternative service (Article 8 of the Law of Ukraine "On Alternative (Non-Military) Service");
            </li>
            <li>
              Staff members of enterprises, institutions, organizations working with the operation of nuclear installations, facilities for handling radioactive waste, and other sources of ionizing radiation (Article 35 of the Law of Ukraine "On the Use of Nuclear Energy and Radiation Safety");
            </li>
            <li>
              Transport company employees in cases related to passenger transportation, servicing continuous production processes, and when the strike poses a threat to human life and health (Article 18 of the Law of Ukraine "On Transport").
            </li>
          </ul>
          <p>Prohibition of strikes at certain productions:</p>
          <ul>
            <li>
              At power generation enterprises when it may lead to disruptions in the stability of the United Energy System of Ukraine (Article 13 of the Law of Ukraine "On the Electricity Market");
            </li>
            <li>
              At heating supply facilities when it may lead to disruptions in the stability of heating supply to consumers during the heating period (Article 30 of the Law of Ukraine "On Heat Supply");
            </li>
            <li>
              For personnel of electronic communication service providers if such actions result in the termination of the functioning of electronic communication networks or services, which hinders the provision of national security, healthcare, and human rights and freedoms (Article 75 of the Law of Ukraine "On Electronic Communications");
            </li>
            <li>
              In underground workings (Article 42 of the Mining Law of Ukraine).
            </li>
          </ul>

          <h4>2.5.7. Guarantees for Workers During Strikes and Consequences of Workers' Participation in a Strike</h4>
          <p>
            Participation in a strike, except for strikes deemed illegal by the court, is not considered a violation of labor discipline and cannot be the basis for disciplinary action.
          </p>
          <p>
            By decision of the employees or the trade union, a strike fund may be created from voluntary contributions and donations.
          </p>
          <p>
            Employees who did not participate in the strike but were unable to perform their job duties due to the strike are entitled to wages not less than the amounts established by law and the collective agreement in effect at the enterprise, as for downtime not caused by the employee's fault. The employer is responsible for keeping records of such employees.
          </p>
          <p>
            Organizing a strike deemed illegal by the court or participating in it is a violation of labor discipline.
          </p>
          <p>
            The time spent on a strike by participating employees is not paid.
          </p>
          <p>
            The time spent by an employee participating in a strike deemed illegal by the court is not counted toward their overall and continuous work experience.
          </p>`,
        p6: `<p>
            Ukrainian legislation clearly defines guarantees for employees to protect their social and economic interests in cases of prohibition or restriction of the right to strike.
          </p>
          <p>
            According to Article 25 of the Law, in cases provided for by Article 24 of the Law and when the recommendations of the National Mediation and Conciliation Service (NMCS) regarding the resolution of a collective labor dispute (conflict) are not taken into account by the parties, the NMCS applies for the resolution of the collective labor dispute (conflict) to the Supreme Court of the Autonomous Republic of Crimea, regional, Kyiv, and Sevastopol city courts.
          </p>
          <p>
            The procedure for NMCS providing recommendations on resolving a collective labor dispute (conflict) is regulated by the Regulation on the procedure for preparing and making decisions by the National Mediation and Conciliation Service on the substance of a collective labor dispute (conflict) when the parties of the collective labor dispute (conflict) apply for assistance in resolving it, approved by NMCS order No. 97 of September 8, 2005.
          </p>
          <p>
            The preparation and submission of an NMCS application to the court for the resolution of a collective labor dispute (conflict) is regulated by the Instruction on the procedure for preparing and submitting an application by the National Mediation and Conciliation Service to the court for the resolution of a collective labor dispute (conflict), approved by NMCS order No. 96 of November 16, 2000, with amendments introduced by NMCS orders No. 20 of January 23, 2003, No. 412 of September 8, 2003, No. 458 of October 3, 2003, and No. 39 of March 24, 2005.
          </p>
          <p>
            Thus, employees for whom the laws of Ukraine establish a prohibition on strikes have the opportunity to protect their social and economic interests through conciliation procedures and, ultimately, if conciliation procedures do not lead to a resolution of disputes between them and the employer or its authorized body (representative), through judicial proceedings.
          </p>`,
      },
    },
    prevention: {
      title1: {
        title: "The Goal and Objectives of Preventing Collective Labor Disputes",
        p: `<p>
            Prevention of collective labor disputes (conflicts) is a complex of measures aimed at resolving differences between parties to social-labor relations at the pre-conflict stage.
        </p>
        <p>The tasks of the National Mediation and Conciliation Service (NMCS) in preventing collective labor disputes (conflicts) are:</p>
        <ul>
            <li>
                Identification of enterprises, institutions, organizations, territories, and industries where complications in social-labor relations may lead to the emergence of collective labor disputes (conflicts);
            </li>
            <li>
                Implementation of measures aimed at preventing the emergence of collective labor disputes (conflicts), preventing strikes and social protests;
            </li>
            <li>
                Conducting studies of the state of social-labor relations to identify potential risks of their exacerbation;
            </li>
            <li>
                Facilitating conciliation procedures to resolve differences between parties to social-labor relations;
            </li>
            <li>
                Interaction with central and local executive authorities, other state bodies, local self-government bodies, trade unions and their associations, employers' organizations and their associations in preventing the emergence of collective labor disputes (conflicts), etc.
            </li>
        </ul>`,
      },
      title2: {
        title:
          "Procedure for registering conflict situations by the National Mediation and Conciliation Service (NMCS).",
        p: `<p>
            Registration of conflict situations aimed at preventing collective labor disputes (conflicts) is carried out as follows: (Clause 3.1 of the Regulations on the procedure for the National Mediation and Conciliation Service to prevent collective labor disputes (conflicts), Order of the NMCS No. 39 dated June 6, 2019):
        </p>
        <ul>
            <li>
                based on written or oral appeals from subjects involved in preventing collective labor disputes (conflicts) about complicating the state of social-labor relations for reasons defined in Article 2 of the Law of Ukraine "On the procedure for resolving collective labor disputes (conflicts)";
            </li>
            <li>
                based on the results of monitoring the state of social-labor relations carried out by NMCS departments.
            </li>
        </ul>
        <p>
            Information contained in the appeals is studied by relevant NMCS structural units with the subsequent preparation of a report on the complications in the state of social-labor relations, the non-resolution of which may lead to the emergence of a collective labor dispute (conflict), escalation of the situation, and may prompt spontaneous strikes and social protest actions (NMCS Order No. 90 of December 31, 2009 "On approving the Exemplary list of issues for monitoring enterprises, institutions, organizations where complications in social-labor relations can lead to the emergence of collective labor disputes (conflicts)").
        </p>
        <p>
            Registration of conflict situations at the production and territorial levels is carried out by an order of the NMCS department.
        </p>
        <p>
            The order of the NMCS department on the registration of a conflict situation must specify the legal grounds for registration, the parties to the social-labor relations, discrepancies defined in Article 2 of the Law of Ukraine "On the procedure for resolving collective labor disputes (conflicts)".
        </p>
        <p>
            A copy of the NMCS department's order on the registration of a conflict situation is sent to the subjects of social dialogue.
        </p>`,
      },
      title3: {
        title: "The measures of the NMCS to prevent collective labor disputes.",
        p: `<p>
          After issuing the order, the NMCS department informs the subjects of the social dialogue about
          the registration of the conflict situation, provides recommendations on ways to resolve
          socio-labor relations differences, and initiates a conciliatory meeting to prevent a collective
          labor dispute.
        </p>
        <p>
          If the socio-labor relations differences involve issues that, according to legislation, fall
          within the competence of executive authorities, local self-government bodies, the NMCS
          departments send their proposals or recommendations to the heads of these bodies for further
          assistance in resolving such issues. These bodies should consider them within fifteen days and
          inform the NMCS and the respective department of their decisions.
        </p>`,
      },
      title4: {
        title: "De-registration of conflict situations by the NMCS",
        p: `<p>
          The removal of conflict situations from the NMCS registry is carried out in the following cases:
        </p>
        <ul>
          <li>
            resolution of disagreements, for which the NMCS has received relevant information;
          </li>
          <li>
            consideration of issues that have complicated the state of socio-labor relations in a judicial procedure;
          </li>
          <li>
            decision following a conciliatory meeting to resolve disagreements in socio-labor relations;
          </li>
          <li>
            initiation of a collective labor dispute (conflict);
          </li>
          <li>
            termination of labor relations between parties in socio-labor relations, including due to the dismissal of more than two-thirds of hired employees;
          </li>
          <li>
            resolution of a conflict situation through a collective agreement (contract) in force at the enterprise;
          </li>
          <li>
            conclusion of an agreement between parties in socio-labor relations;
          </li>
          <li>
            presence of other cases.
          </li>
        </ul>
        <p>
          In the event of removal of a conflict situation from the production and territorial levels, if the disagreements have not been resolved and a collective labor dispute (conflict) has arisen, the relevant order of the NMCS division on the removal of the conflict situation from the registry is issued.
        </p>
        <p>
          A copy of the NMCS division's order on the removal of the conflict situation from the registry is sent to the subjects of the social dialogue.
        </p>
        <p>
          If the employer or its authorized body (representative) informs about the resolution of disagreements and the body authorized to represent the interests of hired workers, the trade union refuses to confirm, the NMCS division analyzes the elimination/non-elimination of disagreements regarding the advisability of further registration of the conflict situation.
        </p>
        <p>
          The NMCS division's order on the removal of the conflict situation from the registry specifies the legal grounds for removal, parties in socio-labor relations, disagreements specified in Article 2 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)."
        </p>`,
      },
      title5: {
        title: "Work Record",
        p: `<p>
            Data on work to prevent collective labor disputes (conflicts) are entered into the Work Record Book for preventing collective labor disputes (conflicts) and reflected in the reporting of structural units of the National Mediation and Conciliation Service in the form of monthly reports on the results of work to prevent collective labor disputes (conflicts) (Form No. 1-ZKTS), appendices 3, 5 to quarterly information, first half-year, nine months, and annual activity report.
        </p>
        <p>
            From the moment the Department of the National Mediation and Conciliation Service registers a conflict situation, a case is formed regarding measures to prevent a collective labor dispute (conflict), which includes:
        </p>
        <ul>
            <li>
                Appeals from subjects preventing collective labor disputes (conflicts) regarding discrepancies on issues specified in the provisions of Article 2 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)".
            </li>
            <li>
                Materials on the study and analysis confirming the existence of discrepancies in labor relations that may lead to a collective labor dispute (conflict).
            </li>
            <li>
                Order of the Department of the National Mediation and Conciliation Service on registering a conflict situation on issues specified in Article 2 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)".
            </li>
            <li>
                Action plan for preventing a collective labor dispute (conflict).
            </li>
            <li>
                Department of the National Mediation and Conciliation Service's letter to parties of labor relations regarding registration of a conflict situation and providing information on the causes of the conflict situation and possible ways to resolve it.
            </li>
            <li>
                Responses from parties of social dialogue, copies of letters, statistical materials, information related to improving labor relations.
            </li>
            <li>
                Recommendations from the Department of the National Mediation and Conciliation Service on ways to resolve discrepancies.
            </li>
            <li>
                Directive of the National Mediation and Conciliation Service on registering a collective labor dispute (conflict) and demands of hired workers, trade unions that caused the complication of labor relations.
            </li>
            <li>
                Order of the Department of the National Mediation and Conciliation Service on deregistering a conflict situation on issues specified in the provisions of Article 2 of the Law of Ukraine "On the Procedure for Resolving Collective Labor Disputes (Conflicts)".
            </li>
            <li>
                Other necessary documents.
            </li>
        </ul>`,
      },
      title6: {
        title: "Information support",
        p: `<p>
            The main task of information support in the system of preventing collective labor disputes (conflicts) is to provide the National Mediation and Reconciliation Service (NSPP), as well as its departments, with necessary informational materials for studying, analyzing, and determining the causes of complications in social and labor relations, in order to prepare well-founded recommendations on ways to improve these relations.
        </p>
        <p>
            Information support for the activities of the NSPP and its departments is carried out by the entities involved in preventing collective labor disputes (conflicts).
        </p>
        <p>
            In order to access the necessary official documents for work, the NSPP and its departments (in accordance with established procedures) submit relevant requests to the subjects.
        </p>
        <p>
            Entities providing information support review requests from the NSPP and its departments within ten days, in accordance with paragraph 5 of the Regulations on the National Mediation and Reconciliation Service and taking into account the provisions of the Law of Ukraine "On Information."
        </p>
        <p>
            The NSPP and its departments inform the parties of the social dialogue about the progress of implementing measures to prevent collective labor disputes (conflicts) and contribute to improving labor relations.
        </p>
        <p>
            The NSPP publishes consolidated results of measures to prevent collective labor disputes (conflicts) and contribute to improving labor relations in the activity report of the NSPP and on the official NSPP website.
        </p>`,
      },
    },
    concept: {
      p: "According to Article 1 of the Law of Ukraine 'On Social Dialogue in Ukraine', social dialogue is the process of defining and bringing together positions, achieving common agreements, and making coordinated decisions by the parties of social dialogue representing the interests of workers, employers, and executive authorities and local self-government bodies on issues related to the formation and implementation of state social and economic policies, regulation of labor, social, and economic relations.",
    },
    principles: {
      p: `<p>According to Article 3 of the Law of Ukraine "On Social Dialogue in Ukraine", the fundamental principles of social dialogue are:</p>
      <ul>
          <li>legality and rule of law;</li>
          <li>representativeness and authority of the parties and their representatives;</li>
          <li>independence and equality of the parties;</li>
          <li>constructiveness and interaction;</li>
          <li>voluntariness and acceptance of real commitments;</li>
          <li>mutual respect and search for compromise solutions;</li>
          <li>mandatory consideration of proposals from the parties;</li>
          <li>priority of conciliation procedures;</li>
          <li>openness and transparency;</li>
          <li>mandatory compliance with agreements reached;</li>
          <li>responsibility for the implementation of commitments.</li>
      </ul>`,
    },
    peculiarties: {
      p: `<p>
          During the period of martial law, upon the expiration of the validity period of certificates confirming the representativeness of trade union entities and employer organizations at the national, sectoral, and territorial levels, trade union entities and employer organizations shall apply to the NSPP/NSPP department in accordance with the Procedure for Assessment.
      </p>
      <p>
          Trade union entities and employer organizations have the right to submit a substantiated application to the NSPP/NSPP department for the extension of the validity of the certificate confirming compliance with representativeness criteria or confirmation of representativeness during the period of martial law and within six months after the termination or cancellation of martial law (hereinafter referred to as the "application"), if it is impossible to assess compliance with representativeness criteria and confirm representativeness of trade union entities and employer organizations in accordance with the Law of Ukraine "On Social Dialogue in Ukraine" due to the absence of necessary statistical data, information, inability to obtain it within the established deadlines, and verify and confirm it.
      </p>`,
      title1: {
        title: "List of required data",
        subtitle1: "At the national level",
        subtitle2: "At the industry level",
        subtitle3: "At the territorial level",
        list: `<li>For nationwide trade union associations - data on the number of members.</li>
          <li>For nationwide associations of employers' organizations - data on the number of employees and conducting selective verification of the accuracy of the provided data.</li>`,
        p1: `<p>
          For nationwide trade unions at the sectoral level - data on the number of union members specifying the organizational structure of nationwide trade unions and the main types of economic activity in the respective sector (types of economic activity) where they are employed, and data on the total number of employees engaged in the respective sector (types of economic activity) from the statistics authorities of Ukraine.
        </p>
        <p>
          For employers' organizations and their associations, which are nationwide associations of employers' organizations created by sectoral criteria - data on the total number of employees at enterprises that are members of the respective employers' organizations, their associations that are part of the applicant, and data on the total number of employees engaged in the respective sector (types of economic activity) from the statistics authorities of Ukraine.
        </p>`,
        p2: `<p>
          For associations of trade unions, trade unions, their organizations - data on the number of union members and data from the statistics authorities of Ukraine on the number of employed population in the respective administrative-territorial unit.
        </p>
        <p>
          For employers' organizations, their associations - data on the total number of employees working at enterprises that are members of the respective employers' organizations, their associations that are part of the applicant, and data from the statistics authorities of Ukraine on the number of employed population in the respective administrative-territorial unit.
        </p>`,
      },
      title2: {
        title:
          "Documents provided for the extension of the validity period of the certificate confirming the representativeness of the subjects of the parties of trade unions and employers' organizations",
        p: `<h4>1. Statement (including the following information:)</h4>
        <ul>
          <li>full name of the trade union or employers' organization;</li>
          <li>email address;</li>
          <li>
            certificate number confirming compliance with the criteria, date of issuance, and expiry date;
          </li>
          <li>
            circumstances preventing the submission of necessary statistical data, information by representative subjects of trade unions and employers' organizations, inability to obtain it within the specified deadlines;
          </li>
        </ul>
        <h4>2. The application shall be accompanied by:</h4>
        <ul>
          <li>
            Copy (scan copy) of the certificate confirming compliance with the representativeness criteria;
          </li>
          <li>
            Documents confirming the circumstances proving the absence of necessary statistical data, information, inability to obtain it within the specified deadlines (letter from the State Statistics Service of Ukraine or another authorized executive authority confirming the impossibility of obtaining and confirming the data).
          </li>
        </ul>`,
      },
      title3: {
        title: "General requirements for providing documents",
        p: `<p>
          All documents must be submitted together and signed by the head and certified with the seal of the trade union or employer's organization.
        </p>
        <p>
          Trade unions, their organizations, and associations provide data on the number of union members in accordance with reporting forms approved by decisions of their elective collegial bodies (managers).
        </p>
        <p>
          Employers' organizations and their associations provide data on the number of employees working in member enterprises of employers' organizations and their associations based on information from state statistics bodies.
        </p>
        <p>Data on the number are provided as of January 1 of the application year.</p>
        <p>
          Data are determined for subjects of trade union sides and employers' organizations located in territories included in the List of territories where hostilities (combat actions) are being conducted or temporarily occupied by the Russian Federation are considered temporarily absent.
        </p>
        <p>
          Documents are considered not submitted if the absence of a document or incorrectly executed documents (without attachments, unsigned, not certified, etc.) is detected. Correspondence is returned to the sender together with a cover letter.
        </p>
        <p>
          Subjects of trade union sides and employers' organizations, including newly formed ones, have the right to apply to the National Social Partnership Platform (NSPP) or its relevant branches for an assessment of compliance with representativeness criteria if there are factual grounds for this, but not more than once a year.
        </p>`,
      },
      title4: {
        title: "Regulatory legal framework",
        list: `<li>Law of Ukraine "On Social Dialogue in Ukraine"</li>
              <li>
                Procedure for assessing compliance with representativeness criteria and confirming representativeness of trade union and employer organizations, approved by the order of NSPP dated 21.07.2011 No. 73
              </li>
              <li>
                Regulations on commissions for assessing compliance with representativeness criteria and confirming representativeness of trade union and employer organizations, approved by the order of NSPP dated 01.08.2011 No. 80, with amendments introduced by the order of NSPP dated 13.07.2012 No. 80
              </li>
              <li>
                Procedure for conducting selective checks by NSPP and its branches in the Autonomous Republic of Crimea, regions, in Kyiv city and Kyiv region to assess compliance with representativeness criteria and confirm representativeness of trade union and employer organizations, approved by the order of NSPP dated 19.08.2011 No. 89
              </li>
              <li>
                Methodological recommendations on organizing the preparation and conducting of assessment of compliance with representativeness criteria and confirming representativeness of trade union and employer organizations, approved by the order of NSPP dated 24.06.2019 No. 44
              </li>
              <li>
                Procedure for extending the validity period of certificates confirming the representativeness of trade union and employer organizations, approved by the order of NSPP No.
              </li>`,
      },
    },
    foreignExperience: {
      title: "Basic Acts on Social Dialogue in the EU",
      p: `<ul>
          <li>Articles 9, 151, 152 of the Treaty on the Functioning of the European Union;</li>
          <li>
            Decisions taken at the summit in Maastricht (Netherlands) in 1991;
          </li>
          <li>
            Contents of the Green Paper published in 1996 by the European Commission "Partnership for a new organization of work";
          </li>
          <li>Charter of Community on fundamental social rights of workers (1989);</li>
          <li>Laken Declaration of European Social Partners 2001;</li>
          <li>
            Declaration on a new start for a strong social dialogue, approved by social partners at the thematic group meeting on January 26/27, 2016, etc.
          </li>
        </ul>
        <h4>It is envisaged:</h4>
        <ul>
          <li>Institutionalization of social dialogue partners (from populism to capacity);</li>
          <li>
            <span>Regulation of three types of activities of social partners</span>
            <ul>
              <li>
                Tripartite coordination: to define political and/or technical exchanges between social partners and European bodies (e.g., tripartite social summit, macroeconomic dialogue).
              </li>
              <li>
                Consultations with social partners: to define the activities of advisory committees or special hearings on matters with social implications, and official consultations in the spirit of Article 154 of the Treaty on the Functioning of the European Union.
              </li>
              <li>
                Social dialogue: to define bilateral work of social partners, whether or not prompted by official consultations of the Commission based on Articles 154-155 of the Treaty. (Bilateral social dialogue concerns contractual relations between social partners, the results of which may be reflected in their autonomous work program, agreements, framework actions, joint declarations and/or positions, as well as other instruments)
              </li>
            </ul>
          </li>
        </ul>`,
    },
    legislation:
      "Law of Ukraine 'On Social Dialogue in Ukraine; dated December 23, 2010 No. 2862-VI",
    determination: {
      title1: {
        title: "All-Ukrainian Association of Trade Unions",
        subtitle1: "All-Ukrainian Association of Trade Unions",
        subtitle2: "All-Ukrainian Union of Employers' Organizations",
        p1: `<h4>Documents provided for evaluation</h4>
          <ul>
            <li>letter – statement (on the attached form);</li>
            <li>
            a copy of the legalization certificate issued by the Ministry of Justice of Ukraine, for compliance
            declared status;
            </li>
            <li>a copy of the charter;</li>
            <li>
            <span> a list of all-Ukrainian trade unions that are part of the union, legalized
            by the Ministry of Justice of Ukraine, with the indication:</span>
            <ul>
              <li>their registration dates and certificate numbers;</li>
              <li>legal address and actual location;</li>
              <li>surname, first name, patronymic of the manager;</li>
              <li>means of communication (phone numbers, email addresses);</li>
              <li>number of trade union members of each of them;</li>
            </ul>
            </li>
            <li>
            <span> a list of trade unions, organizations of all-Ukrainian trade unions with republican status,
            regional, city in the cities of Kyiv and Sevastopol, legalized by the relevant authorities
            of justice with the indication:</span>
            <ul>
              <li>their registration dates and certificate numbers;</li>
              <li>legal address and actual location;</li>
              <li>surname, first name, patronymic of the manager;</li>
              <li>means of communication (phone numbers, email addresses);</li>
              <li>
              number of trade union members, which they unite in the corresponding administrative -
              territorial units;
              </li>
              <li>certificate on the total number of trade union members in the union.</li>
            </ul>
            </li>
          </ul>
          <h4>Checking the assessment of compliance with the representativeness criteria</h4>
          <ul>
            <li>
            <span>regarding sectoral and territorial branching - by analysis:</span>
            <ul>
              <li>structures of the association,</li>
              <li>statutory documents,</li>
              <li>agreement on formation,</li>
              <li>decisions of collegial bodies regarding admission to association membership;</li>
            </ul>
            </li>
            <li>
            regarding the number - by carrying out random checks of the reliability of the provided data.
            </li>
          </ul>
          <h4>Requirements for representativeness</h4>
          <p>Professional associations are recognized as representative:</p>
          <ul>
            <li>which have at least 150 thousand members;</li>
            <li>
            have in their composition trade unions, their organizations in the majority
            administrative-territorial units of Ukraine, defined by the second part of Article 133
            Constitution of Ukraine,
            </li>
            <li>as well as at least three all-Ukrainian trade unions.</li>
          </ul>`,
        p2: `<h4>Documents provided for evaluation</h4>
          <ul>
            <li>application letter (on the attached form);</li>
            <li>a copy of the applicant's certificate confirming the fact of legalization (registration) of the association;</li>
            <li>a copy of the applicant's charter;</li>
            <li>
            <span
            > information on the list of associations of employers' organizations located in the majority
            administrative-territorial units defined by the second part of Article 133
            of the Constitution of Ukraine, all-Ukrainian unions of employers' organizations from
            by providing:</span
            >
            <ul>
              <li>copy of registration (legalization) certificates,</li>
              <li>
              decisions of the applicant's statutory bodies on the admission of these associations to its composition, information
              about the location of their statutory bodies;
              </li>
            </ul>
            </li>
            <li>
            a certificate on the total number of employees working at member enterprises
            relevant organizations of employers, their associations, which are part of the applicant.
            </li>
          </ul>
          <h4>Checking the assessment of compliance with the representativeness criteria</h4>
          <ul>
            <li>
            with regard to sectoral and territorial branching - by analyzing the structure of the association,
            statutory documents;
            </li>
            <li>
            regarding the number - by carrying out random checks of the reliability of the provided data.
            </li>
          </ul>
          <h4>Requirements for representativeness</h4>
          <p>Representative are: enterprises whose members work:</p>
          <ul>
            <li>at least 200 thousand workers;</li>
            <li>
            have in their composition associations of employers' organizations in the majority
            administrative-territorial units of Ukraine, defined by the second part of Article 133
            Constitution of Ukraine,
            </li>
            <li>as well as at least three all-Ukrainian associations of employers' organizations.</li>
          </ul>`,
      },
      title2: {
        title: "Industry Level",
        subtitle1: "All-Ukrainian Trade Unions",
        subtitle2:
          "Employers' organizations and their associations, which are all-Ukrainian associations of employers' organizations, created on a sectoral basis",
        p1: `<h4>Documents provided for evaluation</h4>
            <ul>
              <li>application letter (on the attached form);</li>
              <li>
              a copy of the legalization certificate issued by the Ministry of Justice of Ukraine, for compliance
              declared status;
              </li>
              <li>a copy of the charter;</li>
              <li>
              a certificate on the total number of trade union members with an indication of the organizational structure
              all-Ukrainian trade unions and the main types of economic activity in the relevant industry.
              </li>
            </ul>
            <h4>Checking the assessment of compliance with the representativeness criteria</h4>
            <ul>
              <li>
              by comparing data on the number of trade union members with data from statistical authorities on
              the number of employees employed in the relevant industry (types of economic activity).
              </li>
            </ul>
            <h4>Requirements for representativeness</h4>
            <p>
            Trade unions whose members are not less than 3% of employees are recognized as representative,
            employed in the relevant industry (types of economic activity).
            </p>`,
        p2: `<h4>Documents provided for evaluation</h4>
          <ul>
            <li>application letter (on the attached form);</li>
            <li>a copy of the applicant's certificate confirming the fact of legalization (registration) of the association;</li>
            <li>a copy of the applicant's charter;</li>
            <li>
            a certificate on the total number of employees working at member enterprises
            relevant organizations of employers, their associations, which are part of the applicant;
            </li>
            <li>information about the territorial distribution of the applicant.</li>
          </ul>
          <h4>Checking the assessment of compliance with the representativeness criteria</h4>
          <ul>
            <li>
            by comparing data on the total number of employees working for
            enterprises - members of the relevant organizations of employers, their associations, which are included in
            composition of the applicant with data from statistical authorities on the number of employees employed in
            the corresponding type (types) of economic activity.
            </li>
          </ul>
          <h4>Requirements for representativeness</h4>
          <p>
          All-Ukrainian associations of employers' organizations, created under
          an industry characteristic, at the enterprises of whose members work at least five percent
          employees employed in the relevant type (types) of economic activity.
          </p>`,
      },
      title3: {
        title: "Territorial level",
        subtitle1: "Union of Trade Unions",
        subtitle2:
          "Professional unions (republican, regional, local), organizations of all-Ukrainian trade unions:",
        subtitle3: "Employers' organizations, associations of employers' organizations",
        p1: `<h4>Documents provided for evaluation</h4>
            <ul>
              <li>letter - statement (on the attached form);</li>
              <li>
              a copy of the certificate of legalization issued by the relevant justice body in Autonomia
              the Republic of Crimea, the region, the city of Kyiv and Sevastopol, in accordance with the declared status;
              </li>
              <li>a copy of the charter (regulations);</li>
              <li>
              <span> list of republican regional, local trade unions, all-Ukrainian organizations
              of trade unions, legalized by the relevant bodies of justice with the indication:
              </span>
              <ul>
                <li>their registration dates and certificate numbers;</li>
                <li>legal address and actual location;</li>
                <li>surname, first name, patronymic of the manager;</li>
                <li>means of communication (phone numbers, email addresses);</li>
                <li>number of trade union members;</li>
              </ul>
              </li>
              <li>certificate on the total number of members of trade unions that are part of the association;</li>
            </ul>
            <h4>Checking the assessment of compliance with the representativeness criteria</h4>
            <ul>
              <li>
              by comparing the data on the number of trade union members with the data of the statistical authorities on
              the number of employed population in the relevant administrative-territorial unit.
              </li>
            </ul>
            <h4>Requirements for representativeness</h4>
            <p>
            Associations of trade unions whose members are at least 2% of the employed are recognized as representative
            population in the relevant administrative-territorial unit,
            </p>`,
        p2: `<h4>Documents provided for evaluation</h4>
          <ul>
            <li>letter - statement (on the attached form);</li>
            <li>
            a copy of the certificate of legalization or notification of inclusion in the register of associations
            of citizens, issued by the relevant justice body, in accordance with the declared status;
            </li>
            <li>
            <span
            > a list of trade union organizations in the relevant administrative-territorial unit,
            legalized by the relevant bodies of justice with the indication:
            </span>
            <ul>
              <li>their registration dates and certificate numbers;</li>
              <li>legal address and actual location;</li>
              <li>surname, first name, patronymic of the manager;</li>
              <li>means of communication (phone numbers, email addresses);</li>
              <li>number of trade union members;</li>
            </ul>
            </li>
            <li>
            a certificate on the total number of trade union members, their registration date and certificate number
            </li>
          </ul>
          <h4>Checking the assessment of compliance with the representativeness criteria</h4>
          <ul>
            <li>
            by comparing the data on the number of trade union members with the data of the statistical authorities on
            the number of employed population in the relevant industry
            </li>
          </ul>
          <h4>Requirements for representativeness</h4>
          <p>
          At least 2% of the employed are recognized as representative for trade unions and their organizations
          population in a certain industry.
          </p>`,
        p3: `<h4>Documents provided for evaluation</h4>
          <ul>
            <li>application letter (on the attached form);</li>
            <li>
            a copy of the applicant's certificate confirming the fact of legalization (registration) of the organization,
            association;
            </li>
            <li>a copy of the applicant's charter;</li>
            <li>
            a certificate on the total number of employees working at member enterprises
            relevant organizations of employers, their associations, which are part of the applicant;
            </li>
            <li>list of members of the applicant.</li>
          </ul>
          <h4>Checking the assessment of compliance with the representativeness criteria</h4>
          <ul>
            <li>
            by comparing data on the total number of employees working for
            enterprises - members of the relevant organizations of employers, their associations, which are included in
            the composition of the applicant with data from the statistical authorities on the number of the employed population in
            the corresponding administrative-territorial unit
            </li>
          </ul>
          <h4>Requirements for representativeness</h4>
          <p>
          Organizations of employers and their associations operating in the territory are recognized as representative
          of the corresponding administrative-territorial unit, at the enterprises of whose members work no
          less than five percent of the employed population in the relevant administrative-territorial area
          units
          </p>`,
      },
    },
  },
  uk: {
    disputesConflicts: {
      p: "Відповідно до ст. 2 Закону України «Про порядок вирішення колективних трудових спорів (конфліктів)» від 3 березня 1998 року № 137/98-ВР КОЛЕКТИВНИЙ ТРУДОВИЙ СПІР - це розбіжності, що виникли між сторонами соціально-трудових відносин, щодо:",
      list: {
        item1:
          "встановлення нових або зміни існуючих соціально-економічних умов праці та виробничого побуту;",
        item2: "укладення чи зміни колективного договору, угоди;",
        item3: "виконання колективного договору, угоди або окремих їх положень;",
        item4: "невиконання вимог законодавства про працю.",
      },
    },
    trainingLaborArbitrators: {
      title1: {
        title: "Особливості підготовки. Вимоги до трудових арбітрів та незалежних  посередників",
        p: `<p>Однією з важливих функцій НСПП є підготовка посередників та арбітрів, які спеціалізуються на вирішенні колективних трудових спорів (конфліктів) і формування списків арбітрів та посередників (підпункти 3-4 частини 6 статті 15 Закону).</p>
            <p>Підготовка і підвищення кваліфікації посередників та арбітрів здійснюється за рахунок коштів Державного бюджету України, а також коштів фізичних і юридичних осіб.</p>
            <p>Відповідно до п. 1.3. Положення про порядок підготовки та підвищення кваліфікації посередників та арбітрів, затвердженого наказом НСПП від 10 червня 2019 року № 41 (Положення 41), посередниками та арбітрами можуть бути:</p>`,
        list: `<li>громадяни України, які здобули вищу освіту (не нижче освітньо-кваліфікаційного рівня бакалавра та магістра), мають досвід роботи не менше трьохроків за фахом юриста, економіста, фінансиста, відповідні знання по вирішенню колективних трудових спорів (конфліктів) примирною комісією та трудовим арбітражем, склали кваліфікаційний іспит та пройшли співбесіду.</li>
              <li>працівники НСПП та її відділень в Автономній Республіці Крим та областях (далі – відділення), з досвідом роботи на займаній посаді не менше одного року за вищезазначених умов за погодженням з Головою НСПП.</li>`,
      },
      title2: {
        title: "Порядок підготовки трудових арбітрів та незалежних посередників",
        p: `<p>Підготовка та підвищення кваліфікації посередників і арбітрів здійснюється закладом вищої освіти України, з яким НСПП уклала договір, за навчальними планами і програмами, що розробляються НСПП або відповідним навчальним закладом згідно типових програм Міністерства освіти і науки України та затверджуються Головою НСПП.</p>
            <p>Підвищення кваліфікації осіб, які пройшли навчання та отримали свідоцтва посередників та арбітрів, може проводитись комісією НСПП, із залученням незалежних експертів або науковців, склад якої затверджується Головою НСПП.</p>
            <p>Посередники та арбітри з числа працівників НСПП та її відділень можуть підвищувати кваліфікацію у ході проведення семінарів, нарад, тренінгів та участі у примирних процедурах.</p>
            <p>Кваліфікаційні іспити визначення рівня знань з питань вирішення колективних трудових спорів (конфліктів) складаються кожним претендентом в порядку, затвердженому спільним рішенням НСПП та відповідного навчального закладу.</p>
            <p>Посвідчення посередника і арбітра – офіційні документи, які засвідчують право громадянина України на здійснення примирних процедур по вирішенню колективних трудових спорів (конфліктів) в якості незалежного посередника або члена трудового арбітражу на території України.</p>
            <p>Термін дії посвідчення до першого продовження терміну повноважень посередника/арбітра - три роки.</p>
            <p>Посвідчення посередника або арбітра з числа працівників НСПП та її відділень чинне протягом усього часу їх роботи у НСПП чи її відділенні.</p>
            <p>Формування списків посередників та арбітрів здійснюється відповідно до Положення про порядок формування і ведення Списку посередників, затвердженого наказом НСПП від 4 квітня 2000 № 27 (із змінами, внесеними наказами НСПП № 27 від 29.01.2003, № 398 від 02.09.2003, № 39 від 24.03.2005) (Положення 27) та Положення про порядок формування і ведення Списку арбітрів затверджене наказом НСПП від 4 квітня 2000 № 28 (із змінами, внесеними згідно з наказами НСПП № 28 від 29.01.2003; № 398 від 02.09.2003; № 39 від 24.03.2005) (Положення 28).</p>`,
      },
      title3: {
        title:
          "Порядок продовження терміну повноважень незалежного посередника та трудового арбітра",
        p: `<p>Продовження терміну повноважень посередника та арбітра, з числа осіб, які пройшли навчання або підвищили кваліфікацію та отримали свідоцтва, проводиться комісією НСПП, із залученням незалежних експертів або науковців, склад якої затверджується Головою НСПП.</p>
        <p>Продовження терміну повноважень посередника та арбітра може проводитись під час проведення семінарів, нарад, тренінгів та участі у примирних процедурах.</p>
        <p>Рішення комісії НСПП оформлюються протоколом, на підставі якого Голова НСПП приймає рішення про продовження терміну повноваження посередника та арбітра, як правило на п’ять років.</p>
        <p>Посередникам та арбітрам, які успішно брали участь у роботі примирних органів на національному або галузевому рівнях, продовження терміну повноважень здійснюється за поданням комісії НСПП та рішенням Голови НСПП, з терміном дії до десяти років.</p>`,
      },
      title4: {
        title: "Відповідальність та гарантії",
        p: `<p>Продовження терміну повноважень посередника та арбітра, з числа осіб, які пройшли навчання або підвищили кваліфікацію та отримали свідоцтва, проводиться комісією НСПП, із залученням незалежних експертів або науковців, склад якої затверджується Головою НСПП.</p>
        <p>Продовження терміну повноважень посередника та арбітра може проводитись під час проведення семінарів, нарад, тренінгів та участі у примирних процедурах.</p>
        <p>Рішення комісії НСПП оформлюються протоколом, на підставі якого Голова НСПП приймає рішення про продовження терміну повноваження посередника та арбітра, як правило на п’ять років.</p>
        <p>Посередникам та арбітрам, які успішно брали участь у роботі примирних органів на національному або галузевому рівнях, продовження терміну повноважень здійснюється за поданням комісії НСПП та рішенням Голови НСПП, з терміном дії до десяти років.</p>
        <p>Незалежним посередникам, членам примирних комісій та трудових арбітражів на час роботи у примирних органах, утворених відповідно до Закону, гарантується збереження місця роботи (посади) і середнього заробітку, а також на них поширюються гарантії, передбачені Кодексом законів про працю України для виборних профспілкових працівників, членів рад (правлінь) підприємств і рад трудових колективів.</p>
        <p>Незалежному посереднику, члену примирної комісії і трудового арбітражу оплата праці в розмірі не менше середньомісячної заробітної плати та відшкодування витрат, пов’язаних з участю у примирній процедурі, провадяться за рахунок сторін колективного трудового спору (конфлікту) за домовленістю, а якщо сторони не досягли згоди - в рівних частках, в порядку та на умовах, визначених Положенням про порядок забезпечення гарантій незалежним посередникам, членам примирних комісій і трудових арбітражів (трудовим арбітрам, затвердженим наказом НСПП від 05.09.2005 № 93, із змінами, внесеними наказом НСПП від 13.07.2012 № 82 (Положення 93) та Положенням про порядок відшкодування витрат, пов’язаних з участю у примирній процедурі по вирішенню колективного трудового спору (конфлікту) незалежного посередника, членів примирної комісії і трудового арбітражу, затвердженого наказом НСПП, Міністерства фінансів України та Міністерства праці та соціальної політики України від 01.12.1999 року №116/308/210 (Положення116/308/210).</p>`,
      },
    },
    algorithm: {
      title1: {
        title: "Крок 1. Підготовка до вирішення спору",
        subtitle1: "1.1. Формування та затвердження вимог",
        subtitle2: "1.2. Направлення вимог, розгляд вимог роботодавцем",
        subtitle3:
          "1.3. Момент виникнення колективного трудового спору та повідомлення про вступ в колективний трудовий спір (конфлікт)",
        subtitle4:
          "1.4. Реєстрація висунутих найманими працівниками, профспілкою вимог та колективного трудового спору (конфлікту)",
        subtitle5: "1.5. Нормативно правова база",
        p1: `<p>В залежності від підстав висунення вимог, що передбачені статтею 2 Закону України «Про
        порядок вирішення колективних трудових спорів (конфліктів)», змінюється порядок вирішення
        колективного трудового спору.
      </p>
      <p>
        Необхідно дуже зважено підійти до висунення вимог, адже від цього залежать і терміни
        вирішення колективного трудового спору мирним шляхом.
      </p>
      <p>
        Вимоги мають адресуватися належній Стороні роботодавця - тобто органу чи особі, які
        компетентні задовольнити вимоги працівників.
      </p>

      <h4>1.1.1. На виробничому рівні</h4>
      <p>
        Затвердження вимог працівників або профспілки на виробничому рівні і визначення органу чи
        особи, які будуть представляти їх інтереси, загальними зборами або конференцією здійснюється
        наступним чином:
      </p>
      <ul>
        <li>
          збори скликаються з ініціативи виборного профспілкового органу чи іншого уповноваженого
          працівниками органу або ініціативної групи працівників, а також за пропозицією не менш як
          третини від загальної кількості штатних працівників;
        </li>
        <li>
          збори працівників є правомочними при наявності на них більше половини працівників, які є
          штатними працівниками підприємства, установи, організації, окремого структурного
          підрозділу, окремої категорії працівників;
        </li>
        <li>
          конференції представників працівників можуть скликатися, у випадках, коли скликання зборів
          утруднене через багатозмінність або територіальну роз’єднаність цехів, відділів, дільниць
          та інших структурних підрозділів;
        </li>
        <li>
          конференція представників працівників є правомочною, якщо в ній бере участь не менше трьох
          четвертих обраних представників працівників (делегатів конференції).
        </li>
        <li>
          або формуються шляхом збору підписів і вважаються чинними за наявності не менше половини
          підписів членів трудового колективу підприємства, установи, організації чи їх структурного
          підрозділу.
        </li>
      </ul>
      <p>
        Вимоги працівників, профспілки чи об’єднання профспілок оформляються відповідним протоколом
        і надсилаються Стороні роботодавця.
      </p>
      <p>
        У випадках, коли скликання загальних зборів найманих працівників ускладнене через багато
        змінність або територіальну роз’єднаність цехів, відділів та інших структурних підрозділів,
        скликання конференції найманих працівників повинно відбуватися з дотриманням норми
        представництва.
      </p>
      <p>
        Ініціатива про скликання зборів або конференції доводиться до відома працівників не пізніше
        як за 7 днів до їх проведення із зазначенням часу скликання, місця проведення, питань, які
        передбачається внести на їх розгляд обговорення.
      </p>
      <p>У зборах можуть брати участь всі штатні працівники.</p>
      <p>
        В роботі зборів має право брати участь Сторона роботодавця, представники місцевих органів
        виконавчої влади, органів місцевого самоврядування, працівники Національної служби
        посередництва і примирення та за згодою учасників даних загальних зборів (конференції) інші
        представники громадських об’єднань.
      </p>
      <p>
        Рішення загальних зборів (конференції) найманих працівників приймаються відкритим або
        таємним голосуванням та вважаються чинними у разі, коли за них проголосувала така кількість
        учасників загальних зборів (конференції), яка складає за їх повноваженнями не менше ніж
        половини працюючих членів трудового колективу підприємства, установи, організації чи їх
        окремого структурного підрозділу.
      </p>
      <p>
        За результатами зборів (конференції) складається протокол, який підписується головою і
        секретарем зборів (конференції). До протоколу зборів (конференції) додаються матеріали
        реєстрації їх учасників (список працівників, які були присутніми на зборах, або список
        представників працівників та протокол (висновок) мандатної (лічильної) комісії).
      </p>
      <p>
        Також затвердження вимог працівників та визначення органу чи особи, які будуть представляти
        їх інтереси може бути здійснено шляхом збору підписів.
      </p>
      <p>
        Профспілковий орган чи ініціативна група працівників виготовляють підписні листи, в яких
        зазначаються вимоги, які пропонуються до висунення працівників та орган чи особа, які
        пропонуються для представлення інтересів працівників. Працівники розглядають пропозиції і
        добровільно ставлять свої підписи в підписному листі.
      </p>
      <p>
        Після завершення збору підписів працівників результати збору підписів узагальнюються
        органом, уповноваженим представляти інтереси найманих працівників, про що складається
        протокол.
      </p>
      <p>
        Вимоги працівників вважаються чинними за наявності не менше половини підписів членів
        трудового колективу підприємства, установи, організації чи їх структурного підрозділу або
        окремої категорії працівників.
      </p>
      <h4>1.1.2. На галузевому, територіальному або національному рівнях</h4>
      <p>
        Затвердження вимог працівників або профспілки на галузевому, територіальному або
        національному рівнях і визначення органу чи особи, які будуть представляти їх інтереси
        здійснюється наступним чином:
      </p>
      <ul>
        <li>
          у випадках, коли інтереси працівників представляє профспілка, об’єднання профспілок -
          рішенням виборного органу відповідної профспілки, об’єднання профспілок;
        </li>
        <li>
          Вимоги найманих працівників, профспілки чи об’єднання профспілок оформляються відповідним
          протоколом і надсилаються Стороні роботодавця.
        </li>
        <li>
          у випадках, коли інтереси працівників представляють інші уповноважені ними організації
          (органи) - конференцією представників підприємств, установ, організацій, обраних зборами
          (конференцією) працівників підприємств, установ, організацій, які перебувають у стані
          трудового спору (конфлікту).
        </li>
      </ul>
      <p>
        Конференція представників працівників є правомірною, якщо в ній бере участь не менше трьох
        четвертих обраних представників працівників.
      </p>
      <p>
        Затвердження вимог найманих працівників і визначення органу чи особи, які будуть
        представляти їх інтереси, конференцією представників підприємств, установ, організацій
        здійснюється у наступному порядку:
      </p>
      <ul>
        <li>
          конференція скликається з ініціативи виборного профспілкового органу чи іншого
          уповноваженого працівниками органу або ініціативної групи працівників;
        </li>
        <li>
          не пізніш як за два тижні до проведення конференції ініціатор скликання повідомляє
          працівників підприємств, установ, організацій відповідної галузі або території щодо часу
          скликання, місця проведення конференції, питань, які передбачається винести на її
          обговорення;
        </li>
        <li>
          для проведення конференції представників підприємств, установ, організацій відповідної
          галузі або території встановлено норми представництва.
        </li>
      </ul>
      <p>
        Рішення конференції представників підприємств, установ, організацій приймаються відкритим
        або таємним голосуванням та вважаються чинними у разі, коли за них проголосувала така
        кількість представників (делегатів конференції), яка складає за їх повноваженнями не менше
        ніж половини працюючих найманих працівників тих підприємств, установ, організацій, які
        перебувають у стані трудового спору (конфлікту) і делегували зазначених представників на цю
        конференцію.
      </p>
      <p>
        За результатами конференції складається протокол, який підписується головою і секретарем
        конференції. До протоколу конференції додаються матеріали реєстрації її учасників (список
        представників працівників, які брали участь у роботі конференції та протокол (висновок)
        мандатної (лічильної) комісії).
      </p>
      <p>
        Вимоги профспілки оформляються відповідним протоколом засідання виборного органу профспілки.
        </p>`,
        p2: `<h4>1.2.1. Направлення вимог</h4>
        <p>
          Вимоги працівників, профспілки чи об’єднання профспілок оформляються відповідним протоколом
          і надсилаються Стороні роботодавця.
        </p>
        <p>
          Важливо зафіксувати дату вручення вимог Стороні роботодавця (в журналі або в інших
          реєстраційних формах обліку вхідної кореспонденції або в формі підпису Сторони роботодавця
          на другому екземплярі вимог, що залишається в представника найманих працівників, або
          поштовим документом (квитанцією), що підтверджує відправку вимог рекомендованим листом).
        </p>
        <p>
          З моменту отримання вимог Стороною роботодавця починається відлік строку розгляду вимог.
        </p>
        <p>
          Днем одержання вимог вважається день їх реєстрації у журналі або книзі вхідної
          кореспонденції, яка ведеться відповідним структурним підрозділом на підприємстві, в
          установі, організації чи безпосередньо Сторона спору, визначена Законом.
        </p>
        <p>
          В процесі реєстрації вимог, що надійшли на розгляд Стороні роботодавця, на протоколі, в
          якому вони зазначені, робиться відповідна помітка щодо дати реєстрації та вхідного номера.
        </p>
        <p>
          У випадку надходження вимог поштою рекомендованим листом з повідомленням днем одержання
          вимог рахуватиметься день вручення Стороні роботодавця відповідного конверта (із вкладеними
          в нього вимогами), про що свідчитиме його особистий підпис або підпис працівника, який
          відповідає за діловодство, на поштовому повідомленні про вручення. Записи у журналі вхідної
          документації в такому випадку здійснюються в день фактичного одержання поштового
          відправлення.
        </p>
        <h4>1.2.2. Строки розгляду вимог</h4>
        <p>
          Згідно статті 5 Закону України «Про порядок вирішення колективних трудових спорів
          (конфліктів)» сторона роботодавця зобов’язана розглянути вимоги працівників, категорій
          працівників, колективу працівників чи профспілки та повідомити їх представників про своє
          рішення у триденний строк з дня одержання вимог (його рахують з дня, наступного після того,
          з якого починається строк. Якщо останній день строку припадає на святковий, вихідний або
          неробочий день, то днем закінчення строку вважається найближчий робочий день. Останній день
          строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення
          необхідні документи здано на пошту).
        </p>
        <p>
          Якщо задоволення вимог виходить за межі компетенції Сторони роботодавця, вони зобов’язані
          надіслати їх у триденний строк з дня одержання вимог власнику або до відповідного
          вищестоящого органу управління, який має право прийняти рішення. При цьому строк розгляду
          вимог найманих працівників кожною інстанцією не повинен перевищувати трьох днів.
        </p>
        <p>
          Загальний строк розгляду вимог і прийняття рішення (з урахуванням часу пересилання) не
          повинен перевищувати тридцяти днів з дня одержання цих вимог Стороною роботодавця до моменту
          одержання найманими працівниками чи профспілкою повідомлення від Сторони роботодавця про
          прийняте рішення.
        </p>
        <p>
          Рішення Сторони роботодавця повинне бути аргументованим, містити відповідь на всі питання,
          поставлені у вимогах та інформацію щодо задоволення чи часткового задоволення вимог
          працівників або профспілки, чи повну або часткову відмову у задоволенні колективних вимог.
        </p>
        <p>
          Рішення Сторони роботодавця викладається у письмовій формі і не пізніше наступного дня
          надсилається уповноваженому представницькому органу іншої сторони колективного трудового
          спору (конфлікту) разом із соціально-економічним обґрунтуванням.
        </p>`,
        p3: `<h4>1.3.1. Виникнення колективного трудового спору</h4>
      <p>Колективний трудовий спір (конфлікт) виникає з моменту:</p>
      <ul>
        <li>
          коли уповноважений представницький орган найманих працівників, категорії найманих
          працівників, колективу працівників або профспілки одержав від Сторони роботодавця
          повідомлення про повну або часткову відмову у задоволенні колективних вимог і прийняв
          рішення про незгоду з рішенням.
        </li>
        <li>
          коли строки розгляду вимог, передбачені Законом, закінчилися, а відповіді від Сторони
          роботодавця не надійшло.
        </li>
      </ul>
      <h4>1.3.2. Повідомлення про вступ в колективний трудовий спір (конфлікт)</h4>
      <p>
        Про виникнення колективного трудового спору орган, який представляє інтереси працівників або
        профспілки, зобов’язаний у триденний строк письмово проінформувати:
      </p>
      <ul>
        <li>роботодавця;</li>
        <li>центральний орган виконавчої влади;</li>
        <li>місцевий орган виконавчої влади;</li>
        <li>орган місцевого самоврядування за місцезнаходженням підприємства;</li>
        <li>
          районну, обласну, галузеву профспілкову організацію (в залежності від рівня колективного
          трудового спору);
        </li>
        <li>Національну службу посередництва і примирення (відділення в областях).</li>
      </ul>
      <p>
        У випадках, встановлених нормативно-правовими актами Національної служби посередництва і
        примирення може бути призупинена реєстрація колективного трудового спору або в реєстрації
        може бути відмовлено.
      </p>`,
        p4: `<h4>1.4.1. Реєстрація колективного трудового спору (конфлікту)</h4>
      <p>
        Уповноважений представницький орган (особа) найманих працівників (окремої категорії найманих
        працівників) або профспілка пiсля прийняття рiшення про вступ в колективний трудовий спiр
        (конфлікт) у триденний строк надсилає НСПП письмову iнформацiю про виникнення колективного
        трудового спору (конфлiкту), в якiй вказує:
      </p>
      <ul>
        <li>
          сторони колективного трудового спору (конфлiкту) iз зазначенням їх повного найменування i
          мiсцезнаходження (юридичної адреси);
        </li>
        <li>вимоги найманих працівників (окремої категорії найманих працівників), профспiлки;</li>
        <li>момент виникнення колективного трудового спору (конфлiкту);</li>
        <li>
          орган чи особу, якi будуть представляти iнтереси найманих працівників (окремої категорії
          найманих працівників);
        </li>
        <li>
          загальну кiлькiсть найманих працівників (окремої категорії найманих працівників) на
          пiдприємствi, в установi та органiзацiї чи їх структурних пiдроздiлах, де виник
          колективний трудовий спiр (конфлiкт), та кiлькiсть найманих працiвникiв, якi беруть участь
          в даному колективному трудовому спорi (конфлiкті).
        </li>
      </ul>
      <p>
        Разом iз письмовою iнформацiєю про виникнення колективного трудового спору (конфлiкту)
        уповноважений представницький орган (особа) найманих працівників (окремої категорії найманих
        працівників) або профспiлка надсилають на ім’я Голови НСПП або відповідного її відділення в
        Автономнiй Республiцi Крим та областях (далі – відділення НСПП) додатки:
      </p>
      <ul>
        <li>оригінали пiдписних листiв та протоколiв;</li>
        <li>
          оригінали або належним чином завірені копії листiв, наказiв, витягiв, довiдок тощо, якi
          пiдтверджують надану iнформацiю.
        </li>
      </ul>
      <p>
        Письмова iнформацiя про виникнення колективного трудового спору (конфлiкту) надсилається:
      </p>
      <ul>
        <li>на нацiональному та галузевому рівнях – на ім’я Голови НСПП;</li>
        <li>на виробничому, територiальному рiвнях – вiддiленням НСПП.</li>
      </ul>
      <p>
        Письмова iнформацiя про виникнення колективного трудового спору (конфлiкту) реєструється в
        установленому порядку в книгах вхiдних документiв та передається на розгляд Головi НСПП,
        начальнику вiддiлення НСПП для надання відповідного доручення.
      </p>
      <p>
        За результатами розгляду письмової iнформацiї про виникнення колективного трудового спору
        (конфлікту) на виробничому та територіальному рівнях вiддiлення НСПП готує i направляє не
        пiзнiше наступного дня (якщо закiнчення строку припадає на вихiдний, святковий чи iнший
        неробочий день, останнiм днем строку є перший робочий день), подання на iм’я Голови НСПП з
        додатком до нього копiї вiдповiдного письмового повiдомлення сторони колективного трудового
        спору (конфлiкту) та копiями протоколiв, листiв, наказiв, витягiв, довiдок тощо, якi
        пiдтверджують надану iнформацiю:
      </p>
      <ul>
        <li>
          про реєстрацiю висунутих найманими працiвниками (окремою категорією найманих працівників)
          або профспiлкою вимог та колективного трудового спору (конфлiкту);
        </li>
        <li>
          про призупинення реєстрацiї висунутих найманими працiвниками (окремою категорією найманих
          працівників) або профспiлкою вимог та колективного трудового спору (конфлiкту);
        </li>
        <li>
          про вiдмову в реєстрацiї висунутих найманими працiвниками (окремою категорією найманих
          працівників) або профспiлкою вимог та колективного трудового спору (конфлiкту).
        </li>
      </ul>
      <p>
        На вимогу Голови НСПП вiддiлення НСПП надсилає додаткову iнформацiю, необхiдну для
        реєстрацiї, призупинення реєстрації, вiдмови в реєстрації вимог та колективного трудового
        спору (конфлiкту).
      </p>
      <p>
        Реєстрацiя висунутих найманими працiвниками (окремою категорією найманих працівників) або
        профспiлкою вимог та колективного трудового спору (конфлiкту) здiйснюється розпорядженням
        НСПП, в якому визначаються:
      </p>
      <ul>
        <li>дата i номер розпорядження НСПП;</li>
        <li>назва розпорядження НСПП;</li>
        <li>факт реєстрацiї колективного трудового спору (конфлiкту);</li>
        <li>реєстрацiйний номер колективного трудового спору (конфлiкту);</li>
        <li>
          факт реєстрацiї висунутих найманими працiвниками (окремою категорією найманих
          працівників), профспiлкою вимог;
        </li>
        <li>
          рiшення про вiдмову в реєстрацiї окремих вимог (частин вимог) найманих працівників
          (окремої категорії найманих працівників), профспiлки (в разi необхiдностi);
        </li>
        <li>
          найменування уповноваженого представницького органу (особи), найманих працівників (окремої
          категорiї найманих працівників) або профспiлки, роботодавця або уповноваженої ним особи,
          органiзацiї роботодавцiв, об’єднання органiзацiй роботодавцiв, юридичних осiб, на адресу
          яких надсилається розпорядження НСПП (орган мiсцевого самоврядування, мiсцевий орган
          виконавчої влади, центральний орган виконавчої влади, до сфери управлiння якого належить
          вiдповiдне пiдприємство, установа або організація, вiддiлення НСПП).
        </li>
      </ul>`,
        p5: `<ol>
        <li>Закон України «Про порядок вирішення колективних трудових спорів (конфліктів)»</li>
        <li>
          Положення «Про Національну службу посередництва і примирення», затвердженим Указом
          Президента України від 17.11.98 N 1258/98, із змінами
        </li>
        <li>
          Наказ НСПП № 43 від 21.06.2019 «Про затвердження Положення про порядок формування і
          затвердження вимог найманих працівників, профспілок» із змінами
        </li>
        <li>
          Наказ НСПП № 28 від 13.08.2020 «Про затвердження Положення про порядок реєстрації НСПП
          висунутих найманими працівниками, профспілкою вимог та колективних трудових спорів
          (конфліктів)» із змінами
        </li>
      </ol>`,
      },
      title2: {
        title: "Крок 2. Варіанти вирішення колективного трудового спору (конфлікту)",
        subtitle1:
          "2.1. Послідовність розгляду і вирішення колективного трудового спору (конфлікту)",
        subtitle2: "2.2. Примирна комісія",
        subtitle3: "2.3. Залучення до вирішення спору незалежного посередника",
        subtitle4: "2.4. Трудовий арбітраж",
        subtitle5: "2.5. Право на страйк",
        subtitle6: "2.6. Звернення до суду",
        p1: `<p>
        Послідовність розгляду і вирішення колективного трудового спору (конфлікту) визначена
        статтями 7-14 Закону України «Про порядок вирішення колективного трудового спору
        (конфлікту)».
      </p>
      <p>Розгляд колективного трудового спору (конфлікту) здійснюється з питань, передбачених:</p>
      <ul>
        <li>
          пунктами "а" і "б" статті 2 Закону, - примирною комісією, а в разі неприйняття рішення у
          строки, встановлені статтею 9 Закону, - трудовим арбітражем;
        </li>
        <li>пунктами "в" і "г" статті 2 Закону, - трудовим арбітражем.</li>
      </ul>
      <p>
        Проте, відповідно до Положення про примирну комісію, затвердженого Наказом НСПП від
        01.12.2020 № 67 (Положення 67), за угодою сторін колективного трудового спору (конфлікту)
        вимоги найманих працівників з питань виконання колективного договору, угоди або окремих їх
        положень та невиконання вимог законодавства про працю, може розглядати примирна комісія.
      </p>
      <p>
        Крім того, відповідно до положень частини 2 статті 13 Закону, Конвенції Міжнародної
        організації праці № 154 1981 року «Про сприяння колективним переговорам» (ратифікована
        постановою Верховної Ради України від 04.02.1994 року № 3932-XII), Рекомендації Міжнародної
        організації праці № 92 1951 року «Щодо добровільного примирення та арбітражу», Рекомендації
        Міжнародної організації праці № 130 1967 року «Щодо розгляду скарг на підприємствах з метою
        їх вирішення» з питань прав і обов’язків сторін колективних трудових спорів (конфліктів) та
        послідовності проведення примирних процедур в частині обов’язку сторін використати для
        врегулювання колективного трудового спору (конфлікту) всі можливості, не заборонені
        законодавством, сторони колективних трудових спорів (конфліктів), за взаємною згодою, можуть
        утворювати примирну комісію для вирішення колективних трудових спорів (конфліктів), що
        виникли з питань:
      </p>
      <ul>
        <li>виконання колективного договору, угоди або окремих їх положень;</li>
        <li>невиконання вимог законодавства про працю.</li>
      </ul>
      <p>
        Жодна із сторін колективного трудового спору (конфлікту) не може ухилятися від участі в
        примирній процедурі.
      </p>`,
        p2: `<p>
        Примирна комісія - орган, призначений для вироблення рішення, що може задовольнити сторони
        колективного трудового спору (конфлікту), та який складається із представників сторін.
      </p>
      <p>Примирна комісія утворюється за ініціативою однієї із сторін:</p>
      <ul>
        <li>на виробничому рівні - у триденний,</li>
        <li>на галузевому чи територіальному рівні - у п’ятиденний,</li>
        <li>
          на національному рівні - у десятиденний строк з моменту виникнення колективного трудового
          спору (конфлікту) з однакової кількості представників сторін.
        </li>
      </ul>
      <p>
        Порядок визначення представників до примирної комісії визначається кожною із сторін
        колективного трудового спору (конфлікту) самостійно.
      </p>
      <p>
        На час ведення переговорів і підготовки рішення примирної комісії її членам надається
        вільний від роботи час.
      </p>
      <p>У разі потреби примирна комісія:</p>
      <ul>
        <li>залучає до свого складу незалежного посередника;</li>
        <li>
          консультується із сторонами колективного трудового спору (конфлікту), центральними та
          місцевими органами виконавчої влади, органами місцевого самоврядування та іншими
          заінтересованими органами.
        </li>
      </ul>
      <p>
        Організаційне та матеріально-технічне забезпечення роботи примирної комісії здійснюється за
        домовленістю сторін, а якщо сторони не досягли згоди - в рівних частках.
      </p>
      <p>
        Сторони колективного трудового спору (конфлікту) зобов’язані надавати примирній комісії
        інформацію, необхідну для ведення переговорів.
      </p>
      <p>
        Члени примирної комісії не мають права розголошувати відомості, що є державною або іншою
        захищеною законом таємницею.
      </p>
      <p>
        Колективні трудові спори (конфлікти) розглядаються виробничою примирною комісією у
        п’ятиденний, галузевою та територіальною примирними комісіями - у десятиденний, примирною
        комісією на національному рівні - у п’ятнадцятиденний строк з моменту утворення комісій. За
        згодою сторін ці строки можуть бути продовжені.
      </p>
      <p>
        Рішення примирної комісії оформляється протоколом та має для сторін обов’язкову силу і
        виконується в порядку і строки, які встановлені цим рішенням.
      </p>
      <p>
        Після прийняття рішення щодо вирішення колективного трудового спору (конфлікту) примирна
        комісія припиняє свою роботу.
      </p>
      <p>
        Примирна комісія утворюється та розглядає вимоги найманих працівників або профспілки
        відповідно до Положення67 та Регламенту роботи примирної комісії по розгляду і вирішенню
        колективного трудового спору (конфлікту), затвердженого Наказом НСПП від 08 серпня 2008 року
        № 94 (Регламент94).
      </p>
      <p>
        НСПП за проханням сторін може залучати до участі в роботі примирної комісії народних
        депутатів України, представників органів державної влади, органів місцевого самоврядування
        відповідно до Положення про порядок залучення до участі у примирних процедурах по вирішенню
        колективних трудових спорів (конфліктів) народних депутатів України, представників державної
        влади та органів місцевого самоврядування від 05.05.2003 №178, із змінами (Положення 178).
      </p>`,
        p3: `<p>
        У разі потреби примирна комісія залучає до свого складу незалежного посередника. Статус
        незалежного посередника визначається ст. 10 Закону та Положенням про посередника,
        затвердженим НСПП від 18.11.2008 №133 (Положення133).
      </p>
      <p>
        Посередник – це підготовлена НСПП особа, яка в разі визначення її за спільним вибором сторін
        колективного трудового спору (конфлікту), набуває статусу незалежного посередника.
      </p>
      <p>
        Незалежний посередник - це визначена за спільним вибором сторін колективного трудового спору
        (конфлікту) особа, яка сприяє встановленню взаємодії між сторонами, проведенню переговорів,
        бере участь у виробленні примирною комісією взаємоприйнятного рішення. Мета та сфера
        діяльності незалежного посередника визначена в розділі 1 Положення133.
      </p>
      <p>
        Відповідно до частини 1 статті 16 Закону, НСПП на прохання сторін колективного трудового
        спору (конфлікту) пропонує кандидатури незалежних посередників.
      </p>
      <p>
        Порядок залучення незалежного посередника до участі в роботі примирної комісії та вирішення
        колективного трудового спору (конфлікту) примирною комісією за участю незалежного
        посередника визначений розділом 3 Положення133 та Положенням про порядок розгляду
        колективного трудового спору (конфлікту) примирною комісією за участю незалежного
        посередника, затвердженого наказом НСПП від 11.05.2004 № 71 (Положення71).
      </p>`,
        p4: `<p>
        Трудовий арбітраж - орган, який складається із залучених сторонами фахівців, експертів та
        інших осіб і приймає рішення по суті трудового спору (конфлікту).
      </p>
      <p>
        Трудовий арбітраж утворюється з ініціативи однієї із сторін або незалежного посередника у
        триденний строк у разі:
      </p>
      <ul>
        <li>
          неприйняття примирною комісією погодженого рішення щодо вирішення колективного трудового
          спору (конфлікту) з питань, передбачених пунктами "а" і "б" статті 2 цього Закону;
        </li>
        <li>
          виникнення колективного трудового спору (конфлікту) з питань, передбачених пунктами "в" і
          "г" статті 2 цього Закону.
        </li>
      </ul>
      <p>
        Кількісний і персональний склад трудового арбітражу визначається за згодою сторін. Голова
        трудового арбітражу обирається з числа його членів.
      </p>
      <p>
        До складу трудового арбітражу також можуть входити народні депутати України, представники
        органів державної влади, органів місцевого самоврядування й інші особи.
      </p>
      <p>
        Організаційне та матеріально-технічне забезпечення роботи трудового арбітражу здійснюється
        за домовленістю сторін, а якщо сторони не досягли згоди - в рівних частках.
      </p>
      <p>
        Колективний трудовий спір (конфлікт) розглядається трудовим арбітражем з обов’язковою участю
        представників сторін, а в разі потреби - представників інших заінтересованих органів та
        організацій.
      </p>
      <p>
        Трудовий арбітраж повинен прийняти рішення у десятиденний строк з дня його створення. За
        рішенням більшості членів трудового арбітражу цей строк може бути продовжено до двадцяти
        днів.
      </p>
      <p>
        Рішення трудового арбітражу приймається більшістю голосів його членів, оформляється
        протоколом і підписується усіма його членами.
      </p>
      <p>
        Члени трудового арбітражу не мають права розголошувати відомості, що є державною таємницею
        або іншою захищеною законом таємницею.
      </p>
      <p>
        Рішення трудового арбітражу про вирішення колективного трудового спору (конфлікту) є
        обов’язковим для виконання, якщо сторони про це попередньо домовилися.
      </p>
      <p>
        Порядок утворення та роботи трудового арбітражу регламентується Положенням про арбітра,
        зареєстрованого наказом НСПП від 18.11.2008 року № 132 (Положення132), Положенням про
        трудовий арбітраж, зареєстрованого наказом НСПП від 01/12/2020 року № 68 (Положення68).
      </p>`,
        p5: `<h4>2.5.1. Реалізація права на страйк</h4>
      <p>
        Відповідно до статті 44 Конституції України ті, хто працює, мають право на страйк для
        захисту своїх економічних і соціальних інтересів.
      </p>
      <p>Порядок здійснення права на страйк встановлюється Законом.</p>
      <p>
        Страйк - це тимчасове колективне добровільне припинення роботи працівниками (невихід на
        роботу, невиконання своїх трудових обов’язків) підприємства, установи, організації
        (структурного підрозділу) з метою вирішення колективного трудового спору (конфлікту).
      </p>
      <p>
        Страйк застосовується як крайній засіб (коли всі інші можливості вичерпано) вирішення
        колективного трудового спору (конфлікту) у зв’язку з відмовою роботодавця або уповноваженої
        ним особи, організації роботодавців, об’єднання організацій роботодавців задовольнити вимоги
        найманих працівників або уповноваженого ними органу, профспілки, об’єднання профспілок чи
        уповноваженого нею (ними) органу.
      </p>
      <p>
        Страйк може бути розпочато, якщо примирні процедури не привели до вирішення колективного
        трудового спору (конфлікту) або роботодавець або уповноважена ним особа, організація
        роботодавців, об’єднання організацій роботодавців ухиляється від примирних процедур або не
        виконує угоди, досягнутої в ході вирішення колективного трудового спору (конфлікту).
      </p>
      <p>
        Порядок оголошення, проведення страйку та вирішення КТС(К) під час страйку регламентується
        Положенням про порядок проведення страйку як крайнього засобу вирішення КТС(К) та примирних
        процедур під час страйку, затвердженим наказом НСПП від 18.11.2008 №131 (Положення131) та
        Правилами поведінки під час страйку передбачені Правилами поведінки найманих працівників,
        органів профспілки, іншого уповноваженого ними органу, роботодавця або уповноваженого ним
        органу (представника), органу (особи), що очолює страйк, під час страйку затвердженими
        наказом НСПП від 28.04.2004 №66, із змінами від 07.02.2005 (Правила66).
      </p>

      <h4>2.5.2. Рішення про оголошення страйку</h4>
      <p>
        Рішення про оголошення страйку на підприємстві приймається за поданням виборного органу
        первинної профспілкової організації (профспілкового представника) чи іншої організації
        найманих працівників, уповноваженої згідно із статтею 3 Закону представляти інтереси
        найманих працівників, загальними зборами (конференцією) найманих працівників шляхом
        голосування і вважається прийнятим, якщо за нього проголосувала більшість найманих
        працівників або дві третини делегатів конференції. Рішення про оголошення страйку
        оформляється протоколом.
      </p>
      <p>
        Рекомендації щодо оголошення чи неоголошення галузевого або територіального страйку
        приймаються на галузевому або територіальному рівнях на конференції, зборах, пленумі чи
        іншому виборному органі представників найманих працівників та/або профспілок і надсилаються
        відповідним трудовим колективам чи профспілкам.
      </p>
      <p>
        Наймані працівники підприємств галузі чи адміністративно-територіальних одиниць самостійно
        приймають рішення про оголошення чи неоголошення страйку на своєму підприємстві.
      </p>
      <p>
        Страйк вважається галузевим чи територіальним, якщо на підприємствах, на яких оголошено
        страйк, кількість працюючих становить більше половини загальної кількості працюючих
        відповідної галузі чи території.
      </p>
      <p>Нікого не може бути примушено до участі або до неучасті у страйку.</p>
      <p>
        Орган (особа), який очолює страйк, зобов’язаний письмово попередити роботодавця або
        уповноважену ним особу, організацію роботодавців, об’єднання організацій роботодавців не
        пізніш як за сім днів до початку страйку, а у разі прийняття рішення про страйк на
        безперервно діючому виробництві - за п’ятнадцять днів.
      </p>
      <p>
        Роботодавець або уповноважена ним особа, організація роботодавців, об’єднання організацій
        роботодавців зобов’язані у найкоротший строк попередити постачальників і споживачів,
        транспортні організації, а також інші заінтересовані підприємства, установи, організації
        щодо рішення найманих працівників про оголошення страйку.
      </p>
      <p>
        Місцеперебування під час страйку працівників, які беруть у ньому участь, визначається
        органом (особою), що керує страйком, за погодженням із роботодавцем.
      </p>
      <p>
        У разі проведення зборів, мітингів, пікетів за межами підприємства орган (особа), який
        очолює страйк, повинен повідомити про запланований захід місцевий орган виконавчої влади чи
        орган місцевого самоврядування не пізніше ніж за три дні.
      </p>
      <p>
        Роботодавець, місцевий орган виконавчої влади або орган місцевого самоврядування і орган
        (особа), що очолює страйк, зобов’язані вжити необхідних заходів до забезпечення під час
        страйку життєздатності підприємства, збереження майна, додержання законності та громадського
        порядку, недопущення загрози життю і здоров’ю людей, навколишньому природному середовищу.
      </p>

      <h4>2.5.3. Керівництво страйком</h4>
      <p>
        Страйк на підприємстві очолює орган (особа), що визначається загальними зборами
        (конференцією) найманих працівників при прийнятті рішення про оголошення страйку.
      </p>
      <p>
        Галузевий чи територіальний страйк очолює (координує) орган (особа), визначений
        конференцією, зборами, пленумом чи іншим виборним органом представників найманих
        працівників, профспілкових чи інших організацій працівників, уповноважених представляти
        відповідні трудові колективи.
      </p>
      <p>
        Орган (особа), що очолює страйк, діє під час страйку в межах прав, передбачених цим Законом,
        інформує працівників про хід вирішення колективного трудового спору (конфлікту).
      </p>
      <p>
        Повноваження органу (особи) як керівника страйку припиняються, якщо сторони підписали угоду
        про врегулювання колективного трудового спору (конфлікту), а також у разі прийняття рішення
        про відміну або про припинення страйку.
      </p>

      <h4>2.5.4. Вирішення колективного трудового спору (конфлікту) під час страйку</h4>
      <p>
        Під час страйку сторони колективного трудового спору (конфлікту) зобов’язані продовжувати
        пошук шляхів його вирішення, використовуючи для цього усі наявні можливості.
      </p>
      <p>
        Угода про вирішення колективного трудового спору (конфлікту) підписується керівником або
        іншим повноважним представником органу, що очолює страйк, і роботодавцем або уповноваженою
        ним особою, головою організації роботодавців, об’єднання організацій роботодавців.
      </p>
      <p>
        Контроль за виконанням умов цієї угоди здійснюється сторонами колективного трудового спору
        (конфлікту) або уповноваженими ними органами (особами).
      </p>

      <h4>2.5.5. Визнання страйку незаконним</h4>
      <p>Незаконними визнаються страйки:</p>
      <ul>
        <li>
          оголошені з вимогами про зміну конституційного ладу, державних кордонів та
          адміністративно-територіального устрою України, а також з вимогами, що порушують права
          людини;
        </li>
        <li>
          оголошені без додержання найманими працівниками, профспілкою, об’єднанням профспілок чи
          уповноваженими ними органами положень статей 2, 4, 6, частин першої і п’ятої статті 12,
          частин першої, третьої і шостої статті 19 Закону;
        </li>
        <li>
          розпочаті з порушенням найманими працівниками, профспілкою, об’єднанням профспілок чи
          уповноваженими ними органами вимог статті 20, частин другої та третьої статті 24 Закону;
        </li>
        <li>
          які оголошені та/або проводяться під час здійснення примирних процедур, передбачених цим
          Законом.
        </li>
      </ul>
      <p>
        Заява роботодавця або уповноваженої ним особи, організації роботодавців, об’єднання
        організацій роботодавців про визнання страйку незаконним розглядається у судовому порядку.
      </p>
      <p>
        Справа щодо цієї заяви повинна бути розглянута судом, включаючи строки підготовки справи до
        судового розгляду, не пізніш як у семиденний строк.
      </p>
      <p>
        Рішення суду про визнання страйку незаконним зобов’язує учасників страйку прийняти рішення
        про припинення або відміну оголошеного страйку, а працівників розпочати роботу не пізніше
        наступної доби після дня вручення копії рішення суду органові (особі), що очолює страйк.
      </p>

      <h4>2.5.6. Заборона проведення страйку</h4>
      <p>
        Забороняється проведення страйку за умов, якщо припинення працівниками роботи створює
        загрозу життю і здоров’ю людей, довкіллю або перешкоджає запобіганню стихійному лиху,
        аваріям, катастрофам, епідеміям та епізоотіям чи ліквідації їх наслідків.
      </p>
      <p>
        Забороняється проведення страйку працівників (крім технічного та обслуговуючого персоналу)
        органів прокуратури, суду, Збройних Сил України, органів державної влади, безпеки та
        правопорядку.
      </p>
      <p>
        У разі оголошення надзвичайного стану Верховна Рада України або Президент України можуть
        заборонити проведення страйків на строк, що не перевищує одного місяця. Подальша заборона
        має бути схвалена спільним актом Верховної Ради України і Президента України. У разі
        оголошення воєнного стану автоматично наступає заборона проведення страйків до моменту його
        відміни.
      </p>
      <p>
        Категорії працюючих, на яких відповідно до закону поширюється заборона на проведення
        страйку:
      </p>
      <ul>
        <li>державні службовці (стаття 10 Закону України «Про державну службу»);</li>
        <li>
          посадові особи органів місцевого самоврядування (стаття 12 Закону України «Про службу в
          органах місцевого самоврядування»);
        </li>
        <li>
          працівники дипломатичної служби (стаття 31 Закону України «Про дипломатичну службу»);
        </li>
        <li>працівники поліції (стаття 61 Закону України «Про Національну поліцію»);</li>
        <li>
          працівники Збройних Сил України (стаття 17 Закону України «Про Збройні Сили України»);
        </li>
        <li>
          військовослужбовці (стаття 5 Закону України «Про соціальний і правовий захист
          військовослужбовців»);
        </li>
        <li>
          особи рядового і начальницького складу служби цивільного захисту, працівники професійних
          аварійно-рятувальних служб (стаття 116 Кодексу цивільного захисту України);
        </li>
        <li>працівники аварійно-рятувальних служб (стаття 29 Гірничого Закону України);</li>
        <li>
          громадяни, направлені на альтернативну службу (стаття 8 Закону України «Про альтернативну
          (невійськову) службу»);
        </li>
        <li>
          персонал - працівники підприємства, установи, організації, які виконують роботи, пов'язані
          з експлуатацією ядерних установок, об'єктів, призначених для поводження з радіоактивними
          відходами, інших джерел іонізуючого випромінювання (стаття 35 Закону України «Про
          використання ядерної енергії та радіаційну безпеку»);
        </li>
        <li>
          працівники підприємств транспорту у випадках, пов’язаних з перевезенням пасажирів,
          обслуговуванням безперервно діючих виробництв, а також, коли страйк становить загрозу
          життю і здоров’ю людини (стаття 18 Закону України «Про транспорт»)
        </li>
      </ul>
      <p>Заборона страйку на окремих виробництвах:</p>
      <ul>
        <li>
          на підприємствах електроенергетики у випадках, якщо вони можуть призвести до порушення
          сталості Об'єднаної енергетичної системи України (ОЕС) України (стаття 13 Закону України
          «Про ринок електричної енергії»);
        </li>
        <li>
          на об’єктах у сфері теплопостачання у випадках, коли вони можуть призвести до порушення
          стабільності теплопостачання споживачів в опалювальний період (стаття 30 Закону України
          «Про теплопостачання»);
        </li>
        <li>
          персоналу постачальника електронних комунікаційних послуг, якщо такі дії призводять до
          припинення функціонування електронних комунікаційних мереж чи надання електронних
          комунікаційних послуг, що створює перешкоди для забезпечення національної безпеки, охорони
          здоров’я, прав і свобод людини (стаття 75 Закону України «Про електронні комунікації»)
        </li>
        <li>у підземних виробках (стаття 42 Гірничого Закону України).</li>
      </ul>

      <h4>
        2.5.7. Гарантії для працівників під час страйку та наслідки участі працівників у страйку
      </h4>
      <p>
        Участь у страйку працівників, за винятком страйків, визнаних судом незаконними, не
        розглядається як порушення трудової дисципліни і не може бути підставою для притягнення до
        дисциплінарної відповідальності.
      </p>
      <p>
        За рішенням найманих працівників чи профспілки може бути утворено страйковий фонд з
        добровільних внесків і пожертвувань.
      </p>
      <p>
        За працівниками, які не брали участі у страйку, але у зв’язку з його проведенням не мали
        можливості виконувати свої трудові обов’язки, зберігається заробітна плата у розмірах не
        нижче від установлених законодавством та колективним договором, укладеним на цьому
        підприємстві, як за час простою не з вини працівника. Облік таких працівників є обов’язком
        роботодавця.
      </p>
      <p>
        Організація страйку, визнаного судом незаконним, або участь у ньому є порушенням трудової
        дисципліни.
      </p>
      <p>Час страйку працівникам, які беруть у ньому участь, не оплачується.</p>
      <p>
        Час участі працівника у страйку, що визнаний судом незаконним, не зараховується до
        загального і безперервного трудового стажу.
      </p>`,
        p6: `<p>
        Законодавством України чітко визначені гарантії найманим працівникам на захист їх соціальних
        і економічних інтересів в разі заборони або обмеження права на страйк.
      </p>
      <p>
        Відповідно до статті 25 Закону, у випадках, передбачених статтею 24 Закону і коли
        рекомендації Національної служби посередництва і примирення щодо вирішення колективного
        трудового спору (конфлікту), сторонами не враховано, Національна служба посередництва і
        примирення звертається із заявою про вирішення колективного трудового спору (конфлікту)
        відповідно до Верховного Суду Автономної Республіки Крим, обласного, Київського і
        Севастопольського міського суду".
      </p>
      <p>
        Процедура надання НСПП рекомендацій щодо вирішення колективного трудового спору (конфлікту)
        регламентується Положенням про порядок підготовки і прийняття рішення Національної служби
        посередництва і примирення по суті колективного трудового спору (конфлікту) у разі звернення
        сторін колективного трудового спору (конфлікту) по сприяння у його вирішенні, затвердженим
        наказом НСПП від 08.09.2005 № 97)
      </p>
      <p>
        Підготовка та подання НСПП заяви до суду щодо вирішення колективного трудового спору
        (конфлікту) регламентується Інструкцією про порядок підготовки та подання Національною
        службою посередництва і примирення до суду заяви про вирішення колективного трудового спору
        (конфлікту), затвердженою наказом НСПП від 16 листопада 2000 № 96, із змінами, внесеними
        наказами НСПП від 23.01.2003 № 20, від 08.09.2003 № 412, від 03.10.2003 № 458, від
        24.03.2005 року N 39.
      </p>
      <p>
        Таким чином, наймані працівники, для яких законами України встановлена заборона на
        проведення страйків, мають можливість захисту своїх соціальних і економічних інтересів
        шляхом проведення примирних процедур і, в кінцевому підсумку, якщо примирні процедури не
        привели до вирішення розбіжностей між ними та власником або уповноваженим ним органом
        (представником), в судовому порядку.
      </p>`,
      },
    },
    prevention: {
      title1: {
        title: "Мета та завдання запобігання виникненню колективних трудових спорів",
        p: `<p>
        Запобігання виникненню колективних трудових спорів (конфліктів) - це комплекс заходів,
        спрямованих на врегулювання розбіжностей між сторонами соціально-трудових відносин на
        доконфліктній стадії.
      </p>
      <p>Завданнями НСПП щодо запобігання виникненню колективних трудових спорів (конфліктів) є:</p>
      <ul>
        <li>
          виявлення підприємств, установ, організацій, територій та галузей, в яких ускладнення
          соціально-трудових відносин може призвести до виникнення колективних трудових спорів
          (конфліктів);
        </li>
        <li>
          вжиття заходів, спрямованих на запобігання виникненню колективних трудових спорів
          (конфліктів), попередження страйків та акцій соціального протесту;
        </li>
        <li>
          проведення вивчення стану соціально-трудових відносин, з метою виявлення можливих ризиків
          їх загострення;
        </li>
        <li>
          сприяння проведенню примирних процедур щодо усунення розбіжностей між сторонами
          соціально-трудових відносин;
        </li>
        <li>
          взаємодія із центральними та місцевими органами виконавчої влади, іншими державними
          органами, органами місцевого самоврядування, організаціями профспілок та їх об'єднань,
          організаціями роботодавців та їх об'єднань у запобіганні виникненню колективних трудових
          спорів (конфліктів) тощо.
        </li>
      </ul>`,
      },
      title2: {
        title: "Порядок взяття на облік НСПП конфліктних ситуацій",
        p: `<p>
        Взяття на облік конфліктних ситуацій з метою запобігання виникненню колективних трудових
        спорів (конфліктів) здійснюється: (п.3.1. Положення про порядок здійснення Національною
        службою посередництва і примирення заходів щодо запобігання виникненню колективних трудових
        спорів (конфліктів) Наказ НСПП від 06.06.2019 року №39):
      </p>
      <ul>
        <li>
          на підставі письмового або усного звернення суб’єктів запобігання виникненню колективних
          трудових спорів (конфліктів) про ускладнення стану соціально-трудових відносин з причин,
          визначених у статті 2 Закону України “Про порядок вирішення колективних трудових спорів
          (конфліктів)”;
        </li>
        <li>
          за результатами моніторингу стану соціально-трудових відносин, здійсненого відділеннями
          НСПП.
        </li>
      </ul>
      <p>
        Інформація, що міститься у зверненнях, підлягає вивченню відповідними структурними
        підрозділами НСПП з подальшим складанням довідки щодо ускладнення стану соціально-трудових
        відносин, неврегулювання якого може призвести до виникнення колективного трудового спору
        (конфлікту), ескалації ситуації та зумовити проведення стихійних страйків та акцій
        соціального протесту (Наказ НСПП від 31.12.2009 № 90 Про затвердження Примірного переліку
        питань щодо здійснення моніторингу підприємств, установ, організацій, де ускладнення
        соціально-трудових відносин може призвести до виникнення колективних трудових спорів
        (конфліктів).
      </p>
      <p>
        Взяття на облік конфліктних ситуацій на виробничому та територіальному рівнях здійснюється
        наказом відділення НСПП.
      </p>
      <p>
        У наказі відділення НСПП про взяття на облік конфліктної ситуації повинні бути зазначені
        правові підстави взяття на облік, сторони соціально-трудових відносин, розбіжності,
        визначені у статті 2 Закону України "Про порядок вирішення колективних трудових спорів
        (конфліктів)".
      </p>
      <p>
        Копія наказу відділення НСПП про взяття на облік конфліктної ситуації надсилається суб’єктам
        сторін соціального діалогу.
      </p>`,
      },
      title3: {
        title: "Заходи НСПП щодо запобігання виникненню колективних трудових спорів",
        p: `<p>
        Після видання наказу, відділення НСПП інформує суб’єктів сторін соціального діалогу про
        взяття на облік конфліктної ситуації, надає рекомендації щодо шляхів усунення розбіжностей
        соціально-трудових відносин та ініціює проведення узгоджувальної зустрічі з метою
        запобігання виникненню колективного трудового спору (конфлікту).
      </p>
      <p>
        Якщо в розбіжностях соціально-трудових відносин містяться питання, вирішення яких,
        відповідно до законодавства, віднесено до компетенції органів виконавчої влади, органів
        місцевого самоврядування, відділення НСПП надсилають свої пропозиції або рекомендації
        керівникам цих органів щодо подальшого сприяння вирішенню таких питань, які повинні їх
        розглянути у п’ятнадцятиденний строк і поінформувати про прийняті ними рішення НСПП та
        відповідне відділення.
      </p>`,
      },
      title4: {
        title: "Зняття з обліку НСПП конфліктних ситуацій",
        p: `<p>Зняття з обліку конфліктної ситуації здійснюється у разі:</p>
      <ul>
        <li>врегулювання розбіжностей, про що НСПП отримано відповідну інформацію;</li>
        <li>
          розгляду питань, що викликали ускладнення стану соціально-трудових відносин в судовому
          порядку;
        </li>
        <li>
          рішення за результатами узгоджувальної зустрічі про врегулювання розбіжностей
          соціально-трудових відносин;
        </li>
        <li>вступу в колективний трудовий спір (конфлікт);</li>
        <li>
          припинення трудових відносин між сторонами соціально-трудових відносин, в тому числі у
          зв'язку із звільненням з роботи більш як двох третин найманих працівників;
        </li>
        <li>
          врегулювання конфліктної ситуації через колективний договір (угоду), що діє на
          підприємстві;
        </li>
        <li>укладання угоди між сторонами соціально-трудових відносин;</li>
        <li>наявності інших випадків.</li>
      </ul>
      <p>
        У разі зняття з обліку конфліктної ситуації на виробничому та територіальному рівнях, якщо
        розбіжності не були усунуті і виник колективний трудовий спір (конфлікт), видається
        відповідний наказ відділення НСПП про зняття з обліку конфліктної ситуації.
      </p>
      <p>
        Копія наказу відділення НСПП про зняття з обліку конфліктної ситуації надсилається суб’єктам
        сторін соціального діалогу.
      </p>
      <p>
        У разі, якщо роботодавець або уповноважений ним орган (представник) інформує про
        врегулювання розбіжностей, а орган, уповноважений представляти інтереси найманих
        працівників, профспілки відмовляється дати підтвердження, то відділення НСПП здійснює аналіз
        усунення/неусунення розбіжностей щодо доцільності подальшого перебування на обліку
        конфліктної ситуації.
      </p>
      <p>
        У наказі відділення НСПП про зняття з обліку конфліктної ситуації зазначаються правові
        підстави зняття з обліку, сторони соціально-трудових відносин, розбіжності, визначені у
        статті 2 Закону України "Про порядок вирішення колективних трудових спорів (конфліктів)".
      </p>`,
      },
      title5: {
        title: "Облік роботи",
        p: `<p>
        Дані про роботу по запобіганню виникнення колективних трудових спорів (конфліктів) вносяться
        до Книги обліку роботи із запобігання виникненню колективних трудових спорів (конфліктів) та
        відображаються у звітності структурних підрозділів НСПП у формі щомісячного звіту про
        результати роботи із запобігання виникненню колективних трудових спорів (конфліктів) (форма
        № 1-ЗКТС), додатків 3, 5 до інформації за квартал, перше півріччя, дев’ять місяців та звіту
        про результати діяльності за рік.
      </p>
      <p>
        З моменту взяття відділенням НСПП на облік конфліктної ситуації, формується справа щодо
        здійснення заходів із запобігання виникненню колективного трудового спору (конфлікту), яка
        містить:
      </p>
      <ul>
        <li>
          звернення суб’єктів запобігання виникненню колективного трудового спору (конфлікту) щодо
          розбіжностей з питань, визначених у положеннях статті 2 Закону України "Про порядок
          вирішення колективних трудових спорів (конфліктів)";
        </li>
        <li>
          матеріали щодо вивчення та проведеного аналізу, які підтверджують наявність розбіжностей
          соціально-трудових відносин, що може призвести до виникнення колективного трудового спору
          (конфлікту);
        </li>
        <li>
          наказ відділення НСПП про взяття на облік конфліктної ситуації з питань, визначених у
          статті 2 Закону України "Про порядок вирішення колективних трудових спорів (конфліктів)";
        </li>
        <li>
          план заходів щодо здійснення запобігання виникненню колективного трудового спору
          (конфлікту);
        </li>
        <li>
          звернення відділення НСПП до сторін соціально-трудових відносин щодо взяття на облік
          конфліктної ситуації та надання інформації про причини конфліктної ситуації та можливі
          шляхи її усунення;
        </li>
        <li>
          відповіді суб’єктів сторін соціального діалогу, копії листів, статистичних матеріалів,
          інформацію, що стосується поліпшення соціально-трудових відносин;
        </li>
        <li>рекомендації відділення НСПП щодо шляхів усунення розбіжностей;</li>
        <li>
          розпорядження НСПП про реєстрацію колективного трудового спору (конфлікту) і вимог
          найманих працівників, профспілки, які були причиною ускладнення стану соціально-трудових
          відносин;
        </li>
        <li>
          наказ відділення НСПП про зняття з обліку конфліктної ситуації з питань, визначених у
          положеннях статті 2 Закону України "Про порядок вирішення колективних трудових спорів
          (конфліктів)";
        </li>
        <li>інші необхідні документи.</li>
      </ul>`,
      },
      title6: {
        title: "Інформаційне забезпечення",
        p: `<p>
        Основним завданням інформаційного забезпечення системи запобігання виникненню колективних
        трудових спорів (конфліктів) є забезпечення НСПП, відділень НСПП необхідними інформаційними
        матеріалами для вивчення, аналізу та визначення причин ускладнень стану соціально-трудових
        відносин з метою підготовки обґрунтованих рекомендацій щодо шляхів поліпшення цих відносин.
      </p>
      <p>
        Інформаційне забезпечення діяльності НСПП, відділень НСПП здійснюють суб’єкти запобігання
        виникненню колективного трудового спору (конфлікту).
      </p>
      <p>
        З метою доступу до необхідних для роботи офіційних документів НСПП, відділення НСПП (у
        встановленому порядку) направляють суб’єктам, відповідні запити.
      </p>
      <p>
        Суб’єкти інформаційного забезпечення розглядають запити НСПП та її відділень в десятиденний
        термін відповідно до пункту 5 Положення про Національну службу посередництва і примирення та
        з врахуванням положень Закону України "Про інформацію".
      </p>
      <p>
        НСПП, відділення НСПП інформують суб’єктів сторін соціального діалогу про хід реалізації
        заходів щодо запобігання виникненню колективних трудових спорів (конфліктів) та сприяння
        поліпшенню трудових відносин.
      </p>
      <p>
        НСПП оприлюднює узагальнені результати заходів щодо запобігання виникненню колективних
        трудових спорів (конфліктів) та сприяння поліпшенню трудових відносин в звіті про результати
        діяльності НСПП та на офіційному веб-сайті НСПП.
      </p>`,
      },
    },
    concept: {
      p: "Відповідно до ст. 1 Закону України «Про соціальний діалог в Україні», соціальний діалог - процес визначення та зближення позицій, досягнення спільних домовленостей та прийняття узгоджених рішень сторонами соціального діалогу, які представляють інтереси працівників, роботодавців та органів виконавчої влади і органів місцевого самоврядування, з питань формування та реалізації державної соціальної та економічної політики, регулювання трудових, соціальних, економічних відносин.",
    },
    principles: {
      p: `<p>
        Відповідно до ст. 3 Закону України «Про соціальний діалог в Україні», основними принципами
        соціального діалогу є:
      </p>
      <ul>
        <li>законності та верховенства права;</li>
        <li>репрезентативності і правоможності сторін та їх представників;</li>
        <li>незалежності та рівноправності сторін;</li>
        <li>конструктивності та взаємодії;</li>
        <li>добровільності та прийняття реальних зобов'язань;</li>
        <li>взаємної поваги та пошуку компромісних рішень;</li>
        <li>обов'язковості розгляду пропозицій сторін;</li>
        <li>пріоритету узгоджувальних процедур;</li>
        <li>відкритості та гласності;</li>
        <li>обов'язковості дотримання досягнутих домовленостей;</li>
        <li>відповідальності за виконання прийнятих зобов'язань.</li>
      </ul>`,
    },
    peculiarties: {
      p: `<p>
        Протягом дії воєнного стану у разі закінчення строку дії свідоцтв про підтвердження
        репрезентативності суб’єктів сторін профспілок та організацій роботодавців на національному,
        галузевому та територіальному рівнях, суб’єкти сторін профспілок та організацій роботодавців
        звертаються до НСПП/відділення НСПП відповідно до Порядку оцінки.
      </p>
      <p>
        Суб’єкти сторін профспілок та організацій роботодавців мають право подати до НСПП/відділення
        НСПП обґрунтовану заяву про продовження дії свідоцтва про оцінку відповідності критеріям
        репрезентативності або підтвердження репрезентативності на період дії воєнного стану та
        протягом шести місяців після припинення чи скасування воєнного стану (далі – заява), у разі
        неможливості здійснення оцінки відповідності критеріям репрезентативності та підтвердження
        репрезентативності суб’єктів сторін профспілок та організацій роботодавців відповідно до
        Закону України «Про соціальний діалог в Україні» у зв’язку з відсутністю необхідних
        статистичних даних, інформації, неможливості її отримання у встановлені терміни та
        можливості їх перевірити і підтвердити.
      </p>`,
      title1: {
        title: "Перелік необхідних даних",
        subtitle1: "На національному рівні",
        subtitle2: "На галузевому рівні",
        subtitle3: "На територіальному рівні",
        list: `<li>Для всеукраїнських об’єднань профспілок – даних щодо чисельності членів.</li>
        <li>
          Для всеукраїнських об’єднань організацій роботодавців – даних щодо чисельності
          працівників.роведення вибіркових перевірок достовірності наданих даних.
        </li>`,
        p1: `<p>
          Для всеукраїнських профспілок на галузевому рівні – даних щодо чисельності членів профспілки
          із зазначенням організаційної структури всеукраїнських профспілок та основних видів
          економічної діяльності у відповідній галузі (виді економічної діяльності), де вони зайняті
          та даних щодо загальної кількості працівників зайнятих у відповідній галузі (виді
          економічної діяльності) від органів статистики України.
        </p>
        <p>
          Для організацій роботодавців та їх об'єднань, які є всеукраїнськими об'єднаннями організацій
          роботодавців, створеними за галузевою ознакою – даних про загальну чисельність на
          підприємствах - членах відповідних організацій роботодавців, їх об'єднань, що входять до
          складу заявника та даних щодо загальної кількості працівників зайнятих у відповідній галузі
          (виді економічної діяльності) від органів статистики України
        </p>`,
        p2: `<p>
        Для об'єднань профспілок, профспілок, їх організацій – даних щодо чисельності членів
        профспілок та даних органів статистики України про чисельність зайнятого населення у
        відповідній адміністративно-територіальній одиниці.
      </p>
      <p>
        Для організацій роботодавців, їх об'єднань даних про загальну чисельність працівників, які
        працюють на підприємствах - членах відповідних організацій роботодавців, їх об'єднань, що
        входять до складу заявника, та даних органів статистики України про чисельність зайнятого
        населення у відповідній адміністративно-територіальній одиниці.
      </p>`,
      },
      title2: {
        title:
          "Документи, що надаються для продовження строку дії свідоцтва про підтвердження репрезентативності суб’єктів сторін профспілок та організацій роботодавців",
        p: `<h4>1. Заява (в якій зазначаються такі відомості:)</h4>
      <ul>
        <li>повна назва суб’єкта сторони профспілок або організації роботодавців;</li>
        <li>адреса електронної пошти;</li>
        <li>
          номер свідоцтва про оцінку (підтвердження) відповідності критеріям, дата його видачі та
          строк дії;
        </li>
        <li>
          обставини, які унеможливлюють подання репрезентативними суб’єктами сторони профспілок та
          організацій роботодавців необхідних статистичних даних, інформації, неможливості її
          отримання у встановлені терміни;
        </li>
      </ul>
      <h4>2. До заяви додаються:</h4>
      <ul>
        <li>
          Копія (скан-копія) свідоцтва про оцінку (підтвердження) відповідності критеріям
          репрезентативності;
        </li>
        <li>
          Документи щодо підтвердження обставин, які засвідчують відсутність необхідних статистичних
          даних, інформації, неможливості її отримання у встановлені терміни (лист Державної служби
          статистики України або іншого органу виконавчої влади, уповноваженого засвідчити
          неможливість отримання та підтвердження даних).
        </li>
      </ul>`,
      },
      title3: {
        title: "Загальні вимоги, щодо надання документів",
        p: `<p>
        Всі документи подаються разом та мають бути підписані керівником та завірені печаткою
        суб’єкта сторони профспілок або сторони роботодавців.
      </p>
      <p>
        Профспілки, їх організації та об’єднання надають дані про чисельність членів профспілок
        відповідно до форм звітності, затверджених рішеннями своїх виборних колегіальних органів
        (керівниками).
      </p>
      <p>
        Організації роботодавців та їх об’єднання надають дані про чисельність працівників, які
        працюють на підприємствах - членах організацій роботодавців та їх об’єднань, на підставі
        інформації органів державної статистики.
      </p>
      <p>Дані про чисельність надаються станом на 1 січня року звернення.</p>
      <p>
        Дані визначені щодо суб’єктів сторін профспілок та організацій роботодавців, які розташовані
        на територіях, що входять до Переліку територій, на яких ведуться (велися) бойові дії або
        тимчасово окупованих російською федерацією, вважаються такими, що тимчасово відсутні.
      </p>
      <p>
        Документи вважаються не поданими у разі, коли виявлено відсутність документа або неправильно
        оформлені документи (без додатків, не підписані, незасвідчені тощо). Кореспонденція
        повертається відправникові разом із супроводжувальним листом.
      </p>
      <p>
        Суб’єкти сторін профспілок та організацій роботодавців, у тому числі новоутворені, мають
        право звертатися до НСПП або до відповідних її відділень для оцінки відповідності критеріям
        репрезентативності за наявності для цього фактичних підстав, але не частіше одного разу на
        рік.
      </p>`,
      },
      title4: {
        title: "Нормативно правова база",
        list: `<li>Закон України «Про соціальний діалог в Україні»</li>
        <li>
          Порядок оцінки відповідності критеріям репрезентативності та підтвердження
          репрезентативності суб’єктів сторін профспілок та організацій роботодавців, затверджений
          наказом НСПП від 21.07.2011 № 73
        </li>
        <li>
          Положення про комісії з проведення оцінки відповідності критеріям репрезентативності та
          підтвердження репрезентативності суб’єктів сторін профспілок і організацій роботодавців,
          затверджене наказом НСПП від 01.08.2011 № 80, із змінами, внесеними наказом НСПП від
          13.07.2012 № 80
        </li>
        <li>
          Порядок проведення НСПП та її відділеннями в АРК та областях, у місті Києві та Київській
          області вибіркових перевірок для оцінки відповідності критеріям репрезентативності та
          підтвердження репрезентативності суб’єктів сторін профспілок та організацій роботодавців,
          затверджений наказом НСПП від 19.08.2011 №89
        </li>
        <li>
          Методичні рекомендації щодо організації підготовки та проведення оцінки відповідності
          критеріям репрезентативності та підтвердження репрезентативності суб’єктів сторін
          профспілок та організацій роботодавців, затверджені наказом НСПП від 24.06.2019№ 44
        </li>
        <li>
          Порядок продовження строку дії свідоцтв про підтвердження репрезентативності суб’єктів
          сторін профспілок та організацій роботодавців, затверджений розпорядженням НСПП від №
        </li>`,
      },
    },
    foreignExperience: {
      title: "Базові акти про соціальний діалог в ЄС",
      p: `<ul>
        <li>ст.ст. 9, 151, 152 Договору про функціонування ЄС;</li>
        <li>
          рішення, прийняті на зустрічі на найвищому рівні в Маастріхті (Голландія) у 1991 році;
        </li>
        <li>
          зміст Зеленої книги, опублікованої у 1996 році Європейською Комісією Партнерство для нової
          організації праці;
        </li>
        <li>Хартії Спільноти про фундаментальні соціальні права працівників (1989);</li>
        <li>Лакенська декларація європейських соціальних партнерів 2001 року;</li>
        <li>
          Декларація про новий старт заради сильного соціального діалогу, затверджена соціальними
          партнерами на засіданні тематичної групи 26/27 січня 2016 року та ін.
        </li>
      </ul>
      <h4>Передбачається:</h4>
      <ul>
        <li>Інституціалізація сторін соціального діалогу (від популізму до спроможності);</li>
        <li>
          <span>Регламентація трьох типів діяльності соціальних партнерів</span>
          <ul>
            <li>
              Тристороння координація: для означення політичних та/або технічних обмінів між
              соціальними партнерами і європейськими органами (наприклад: тристоронній соціальний
              саміт, макроекономічний діалог).
            </li>
            <li>
              Консультації із соціальними партнерами: для означення діяльності консультативних
              комітетів або спеціальних слухань з питань, що мають соціальні наслідки, і офіційних
              консультацій в дусі статті 154 Договору про функціонування ЄС.
            </li>
            <li>
              Соціальний діалог: для означення двосторонньої роботи соціальних партнерів, незалежно
              від того, викликана вона або ні офіційними консультаціями Комісії на основі статей
              154-155 Договору. (Двосторонній соціальний діалог стосується договірних відносин між
              соціальними партнерами, результати яких можуть які могли виливатися в: їх автономну
              програму роботи, угоди, рамкові дії, спільні декларації і/або позиції, а також інші
              інструментів)
            </li>
          </ul>
        </li>
      </ul>`,
    },
    legislation:
      "Закон України «Про соціальний діалог в Україні» від 23 грудня 2010 року № 2862-VI",
    determination: {
      title1: {
        title: "Всеукраїнські об’єднання профспілок",
        subtitle1: "Всеукраїнські об’єднання профспілок",
        subtitle2: "Всеукраїнські об'єднання організацій роботодавців",
        p1: `<h4>Документи, що надаються для проведення оцінювання</h4>
      <ul>
        <li>лист – заяву (за формою, що додається);</li>
        <li>
          копію свідоцтва про легалізацію, виданого Міністерством юстиції України, на відповідність
          заявленому статусу;
        </li>
        <li>копію статуту;</li>
        <li>
          <span
            >перелік всеукраїнських профспілок, які входять до об’єднання, легалізованих
            Міністерством юстиції України, із зазначенням:</span
          >
          <ul>
            <li>дати їх реєстрації і номера свідоцтва;</li>
            <li>юридичної адреси та фактичного місця знаходження;</li>
            <li>прізвища, ім’я, по батькові керівника;</li>
            <li>засобів зв’язку (номерів телефонів, електронних адрес);</li>
            <li>кількості членів профспілки кожної із них;</li>
          </ul>
        </li>
        <li>
          <span
            >перелік профспілок, організацій всеукраїнських профспілок із статусом республіканських,
            обласних, міських в містах Києві та Севастополі, легалізованих відповідними органами
            юстиції із зазначенням:</span
          >
          <ul>
            <li>дати їх реєстрації і номера свідоцтва;</li>
            <li>юридичної адреси та фактичного місця знаходження;</li>
            <li>прізвища, ім’я, по батькові керівника;</li>
            <li>засобів зв’язку (номерів телефонів, електронних адрес);</li>
            <li>
              кількості членів профспілки, яких вони об’єднують у відповідних адміністративно -
              територіальних одиницях;
            </li>
            <li>довідку про загальну чисельність членів профспілок в об’єднанні.</li>
          </ul>
        </li>
      </ul>
      <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
      <ul>
        <li>
          <span>щодо галузевої та територіальної розгалуженості - шляхом аналізу:</span>
          <ul>
            <li>структури об’єднання,</li>
            <li>статутних документів,</li>
            <li>угод про утворення,</li>
            <li>рішень колегіальних органів стосовно прийняття членом об’єднання;</li>
          </ul>
        </li>
        <li>
          щодо чисельності – шляхом проведення вибіркових перевірок достовірності наданих даних.
        </li>
      </ul>
      <h4>Вимоги щодо репрезентативності</h4>
      <p>Репрезентативними визнаються профоб’єднання:</p>
      <ul>
        <li>які налічують не менше 150 тисяч членів;</li>
        <li>
          мають у своєму складі профспілки, їх організації у більшості
          адміністративно-територіальних одиниць України, визначених частиною другою статті 133
          Конституції України,
        </li>
        <li>а також не менш як три всеукраїнські профспілки.</li>
      </ul>`,
        p2: `<h4>Документи, що надаються для проведення оцінювання</h4>
      <ul>
        <li>лист-заяву (за формою, що додається);</li>
        <li>копію свідоцтва заявника, що підтверджує факт легалізації (реєстрації) об’єднання;</li>
        <li>копію статуту заявника;</li>
        <li>
          <span
            >інформацію про перелік об’єднань організацій роботодавців, розташованих у більшості
            адміністративно-територіальних одиниць, визначених частиною другою статті 133
            Конституції України, всеукраїнських об’єднань організацій роботодавців із
            наданням:</span
          >
          <ul>
            <li>копій свідоцтв про реєстрацію (легалізацію),</li>
            <li>
              рішень статутних органів заявника про вступ цих об’єднань до його складу, відомостей
              про місце знаходження їх статутних органів;
            </li>
          </ul>
        </li>
        <li>
          довідку про загальну чисельність працівників, які працюють на підприємствах - членах
          відповідних організацій роботодавців, їх об’єднань, що входять до складу заявника.
        </li>
      </ul>
      <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
      <ul>
        <li>
          щодо галузевої та територіальної розгалуженості - шляхом аналізу структури об’єднання,
          статутних документів;
        </li>
        <li>
          щодо чисельності – шляхом проведення вибіркових перевірок достовірності наданих даних.
        </li>
      </ul>
      <h4>Вимоги щодо репрезентативності</h4>
      <p>Репрезентативними визнаються: на підприємствах членів яких працюють:</p>
      <ul>
        <li>не менш як 200 тисяч працівників;</li>
        <li>
          мають у своєму складі об'єднання організацій роботодавців у більшості
          адміністративно-територіальних одиниць України, визначених частиною другою статті 133
          Конституції України,
        </li>
        <li>а також не менш три всеукраїнські об'єднання організацій роботодавців.</li>
      </ul>`,
      },
      title2: {
        title: "Галузевий рівень",
        subtitle1: "Всеукраїнські профспілки",
        subtitle2:
          "Організації роботодавців та їх об’єднання, які є всеукраїнськими  об'єднаннями організацій роботодавців, створеними за галузевою ознакою",
        p1: `<h4>Документи, що надаються для проведення оцінювання</h4>
      <ul>
        <li>лист-заяву (за формою, що додається);</li>
        <li>
          копію свідоцтва про легалізацію, виданого Міністерством юстиції України, на відповідність
          заявленому статусу;
        </li>
        <li>копію статуту;</li>
        <li>
          довідку про загальну чисельність членів профспілки із зазначенням організаційної структури
          всеукраїнських профспілок та основних видів економічної діяльності в відповідній галузі.
        </li>
      </ul>
      <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
      <ul>
        <li>
          шляхом порівняння даних про чисельність членів профспілки з даними органів статистики про
          чисельність працівників, зайнятих у відповідній галузі (виді економічної діяльності).
        </li>
      </ul>
      <h4>Вимоги щодо репрезентативності</h4>
      <p>
        Репрезентативними визнаються профспілки, членами яких є не менше, як 3% працівників,
        зайнятих у відповідній галузі (виді економічної діяльності).
      </p>`,
        p2: `<h4>Документи, що надаються для проведення оцінювання</h4>
      <ul>
        <li>лист-заяву (за формою, що додається);</li>
        <li>копію свідоцтва заявника, що підтверджує факт легалізації (реєстрації) об’єднання;</li>
        <li>копію статуту заявника;</li>
        <li>
          довідку про загальну чисельність працівників, які працюють на підприємствах - членах
          відповідних організацій роботодавців, їх об’єднань, що входять до складу заявника;
        </li>
        <li>інформацію про територіальну розгалуженість заявника.</li>
      </ul>
      <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
      <ul>
        <li>
          шляхом порівняння даних про загальну чисельність працівників, які працюють на
          підприємствах - членах відповідних організацій роботодавців, їх об’єднань, що входять до
          складу заявника з даними органів статистики про чисельність працівників, зайнятих у
          відповідному виді (видах) економічної діяльності.
        </li>
      </ul>
      <h4>Вимоги щодо репрезентативності</h4>
      <p>
        Репрезентативними визнаються всеукраїнські об'єднання організацій роботодавців, створені за
        галузевою ознакою, на підприємствах членів яких працюють не менш як п'ять відсотків
        працівників, зайнятих у відповідному виді (видах) економічної діяльності.
      </p>`,
      },
      title3: {
        title: "Територіальний рівень",
        subtitle1: "Об’єднання профспілок",
        subtitle2:
          "Професійні спілки (республіканські, обласні, місцеві), організації всеукраїнських профспілок:",
        subtitle3: "Організації роботодавців, об'єднання організацій роботодавців",
        p1: `<h4>Документи, що надаються для проведення оцінювання</h4>
          <ul>
            <li>лист - заяву (за формою, що додається);</li>
            <li>
              копію свідоцтва про легалізацію, виданого відповідним органом юстиції в Автономній
              Республіці Крим, області, місті Києві і Севастополі, на відповідність заявленому статусу;
            </li>
            <li>копію статуту (положення);</li>
            <li>
              <span
                >перелік республіканських обласних, місцевих профспілок, організацій всеукраїнських
                профспілок, легалізованих відповідними органами юстиції із зазначенням:
              </span>
              <ul>
                <li>дати їх реєстрації і номера свідоцтва;</li>
                <li>юридичної адреси та фактичного місця знаходження;</li>
                <li>прізвища, ім’я, по батькові керівника;</li>
                <li>засобів зв’язку (номерів телефонів, електронних адрес);</li>
                <li>кількості членів профспілки;</li>
              </ul>
            </li>
            <li>довідку про загальну чисельність членів профспілок, які входять до об’єднання;</li>
          </ul>
          <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
          <ul>
            <li>
              шляхом порівняння даних про чисельність членів профспілок з даними органів статистики про
              чисельність зайнятого населення у відповідній адміністративно-територіальній одиниці.
            </li>
          </ul>
          <h4>Вимоги щодо репрезентативності</h4>
          <p>
            Репрезентативними визнаються об’єднання профспілок, членами яких є не менше 2% зайнятого
            населення у відповідній адміністративно-територіальній одиниці,
        </p>`,
        p2: `<h4>Документи, що надаються для проведення оцінювання</h4>
          <ul>
            <li>лист - заяву (за формою, що додається);</li>
            <li>
              копію свідоцтва про легалізацію або повідомлення про включення до реєстру об’єднань
              громадян, виданого відповідним органом юстиції, на відповідність заявленому статусу;
            </li>
            <li>
              <span
                >перелік організацій профспілки у відповідній адміністративно-територіальній одиниці,
                легалізованих відповідними органами юстиції із зазначенням:
              </span>
              <ul>
                <li>дати їх реєстрації і номера свідоцтва;</li>
                <li>юридичної адреси та фактичного місця знаходження;</li>
                <li>прізвища, ім’я, по батькові керівника;</li>
                <li>засобів зв’язку (номерів телефонів, електронних адрес);</li>
                <li>кількості членів профспілки;</li>
              </ul>
            </li>
            <li>
              довідку про загальну чисельність членів профспілки дати їх реєстрації і номера свідоцтва
            </li>
          </ul>
          <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
          <ul>
            <li>
              шляхом порівняння даних про чисельність членів профспілок з даними органів статистики про
              чисельність зайнятого населення у відповідній галузі
            </li>
          </ul>
          <h4>Вимоги щодо репрезентативності</h4>
          <p>
            Репрезентативними визнаються для профспілок та їх організацій – не менше 2% зайнятого
            населення у певній галузі.
          </p>`,
        p3: `<h4>Документи, що надаються для проведення оцінювання</h4>
        <ul>
          <li>лист-заяву (за формою, що додається);</li>
          <li>
            копію свідоцтва заявника, що підтверджує факт легалізації (реєстрації) організації,
            об’єднання;
          </li>
          <li>копію статуту заявника;</li>
          <li>
            довідку про загальну чисельність працівників, які працюють на підприємствах - членах
            відповідних організацій роботодавців, їх об’єднань, що входять до складу заявника;
          </li>
          <li>перелік членів заявника.</li>
        </ul>
        <h4>Перевірка оцінки відповідності критеріям репрезентативності</h4>
        <ul>
          <li>
            шляхом порівняння даних про загальну чисельність працівників, які працюють на
            підприємствах - членах відповідних організацій роботодавців, їх об’єднань, що входять до
            складу заявника з даними органів статистики про чисельність зайнятого населення у
            відповідній адміністративно-територіальній одиниці
          </li>
        </ul>
        <h4>Вимоги щодо репрезентативності</h4>
        <p>
          Репрезентативними визнаються організації роботодавців, їх об'єднання, що діють на території
          відповідної адміністративно-територіальної одиниці, на підприємствах членів яких працюють не
          менше п'яти відсотків зайнятого населення у відповідній адміністративно-територіальній
          одиниці.
        </p>`,
      },
    },
  },
};
