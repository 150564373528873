export default {
  en: {
    error: "Failed to load the documents. Try later",
    clearFilters: "Reset filter settings",
    emptyResults: "No documents were found for your request",
    makeFilter: "Apply filters",
    filtered: "Filtered out",
    count: "In general",
    docs: "document",
    filters: "Filters",
    newDocs: "New legislation documents",
    searchPlaceholder: "Search documents",
    sectionLabel: "Document section",
    sectionPlaceholder: "Select section",
    dateLabel: "Publication date",
    datePlaceholder: "Select date",
    searchButtonText: "Search",
    framework: {
      title:
        "Get access to the complete regulatory framework - exactly the documents and laws you need",
      text:
        "We provide you with the necessary information on documents regulating the activities of state bodies and " +
        "institutions in the field of disputes and conflicts",
      link: "Go to the regulatory framework",
      newDocs: "New legislation documents",
      oftenSearch: "Often searched for",
      filters: {
        searchPlaceholder: "Search documents",
        sectionLabel: "Document section",
        sectionPlaceholder: "Select section",
        sectionOptions: {
          legislation: "Legislation",
          activity: "Regulatory Acts by Areas of Activity",
          judicialPractice: "Judicial Practice",
        },
        dateLabel: "Publication date",
        datePlaceholder: "Select date",
        searchButtonText: "Search",
      },
    },
  },
  uk: {
    error: "Не вдалося завантажити документи. Спробуйте пізніше",
    clearFilters: "Скинути налаштування фільтрів",
    emptyResults: "За вашим запитом не знайдено документів",
    makeFilter: "Застосувати фільтри",
    search: "Пошук по документах",
    filtered: "Відфільтровано",
    count: "Загалом",
    docs: "документ",
    filters: "Фільтри",
    newDocs: "Нові законодавчі документи",
    searchPlaceholder: "Пошук по документах",
    sectionLabel: "Розділ документу",
    sectionPlaceholder: "Обрати розділ",
    dateLabel: "Дата прийняття",
    datePlaceholder: "Обрати дату",
    searchButtonText: "Знайти",
    framework: {
      title:
        "Отримайте доступ до повної нормативної бази - саме ті документи та закони, які вам потрібні",
      text:
        "Ми забезпечуємо вам необхідну інформацію по документах, що регулюють діяльність державних органів" +
        " та установ у сфері спорів та конфліктів",
      link: "Перейти до бази",
      newDocs: "Нові законодавчі документи",
      oftenSearch: "Часто шукають",
      filters: {
        searchPlaceholder: "Пошук по документах",
        sectionLabel: "Розділ документу",
        sectionPlaceholder: "Обрати розділ",
        dateLabel: "Дата прийняття",
        datePlaceholder: "Обрати дату",
        searchButtonText: "Знайти",
        sectionOptions: {
          legislation: "Законодавство",
          activity: "НПА за напрямами діяльності",
          judicialPractice: "Судова практика",
        },
      },
    },
  },
};
