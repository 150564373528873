import main from "./main";
import forms from "./forms";
import home from "./home";
import aboutUs from "./aboutUs";
import activities from "./activities";
import regulatory from "./regulatory";
import forPublic from "./forPublic";
import register from "./register";
import pressCenter from "./pressCenter";

export const messages = {
  en: {
    main: main.en,
    forms: forms.en,
    home: home.en,
    aboutUs: aboutUs.en,
    activities: activities.en,
    regulatory: regulatory.en,
    forPublic: forPublic.en,
    register: register.en,
    pressCenter: pressCenter.en,
  },
  uk: {
    main: main.uk,
    forms: forms.uk,
    home: home.uk,
    aboutUs: aboutUs.uk,
    activities: activities.uk,
    regulatory: regulatory.uk,
    forPublic: forPublic.uk,
    register: register.uk,
    pressCenter: pressCenter.uk,
  },
};
