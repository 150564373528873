export default {
  en: {
    personalData: "Personal data",
    residencePlace: "Place of residence",
    fullName: "Full name",
    addressRequirements:
      'In accordance with the requirements of Article 5 of the Law of Ukraine "On Appeals of Citizens", mandatory information - the full postal address - must be specified in the appeal.',
    region: "Region",
    settlement: "Settlement",
    address: "Address",
    zipCode: "ZIP Code",
    work: "Work",
    position: "Position",
    applicationInfo: "Application information",
    applicationDescription:
      "The essence of the raised issue, remarks, suggestions, statements or complaints, requests or demands, who was previously addressed to resolve the issue, if addressed earlier.",
    attachment: "Attachment",
    attachmentDescription:
      "It is possible to attach the necessary copies of documents in the following formats: jpg, jpeg, png, docx, pdf.",
    fileFormat: "mb",
    fileError: "An error occurred while uploading the file.",
    fileSizeError: "File size exceeds",
    addFile: "Add file",
    mandatoryField: "Indicates a required field",
    getAnswerTo: "Get an answer to",
    send: "Send",
    formLoadingError: "We could not load the form, please try again.",
    successMessage: "Thank you for your request.",
    errorMessage: "We could not send your request, please try again.",
    respondBy: {
      email: "Email",
      postalAddress: "Postal address",
    },
    questioner: "The questioner",
    legalEntity: {
      individual: "Individual",
      legalEntity: "Legal entity",
      associationOfCitizensWithoutStatus:
        "Association of citizens without the status of a legal entity",
    },
    personalInfo: "Personal data of the requester",
    phone: "Phone",
    email: "Email",
    postalAddress: "Postal address",
    generalDescriptionOfRequest: "General description of the request",
    generalDescription: "Type, name, details or content of the requested document",
    invalidEmail: "Invalid email format.",
    invalidPhone: "Invalid phone format.",
    invalidRequest: "Please decrease your input to 4500 symbols.",
  },
  uk: {
    personalData: "Особисті дані",
    residencePlace: "Місце проживання",
    fullName: "ПІБ",
    addressRequirements:
      "Відповідно до вимог статті 5 Закону України «Про звернення громадян» у зверненні необхідно зазначити обов'язкову інформацію - повну поштову адресу.",
    region: "Район",
    settlement: "Населений пункт",
    address: "Адреса",
    zipCode: "Індекс",
    work: "Місце роботи",
    position: "Посада",
    applicationInfo: "Інформація про звернення",
    applicationDescription:
      "Cуть порушеного питання, зауваження, пропозиції, заяви чи скарги, прохання чи вимоги, до кого попередньо зверталися для вирішення питання, якщо звертались раніше.",
    attachment: "Вкладення",
    attachmentDescription:
      "Прикріпити необхідні копії документів можливо у наступних форматах: jpg, jpeg, png, docx, pdf.",
    fileFormat: "мб",
    fileError: "Помилка при завантаженні файлу.",
    fileSizeError: "Розмір файлу перевищує",
    addFile: "Прикріпіть файл",
    mandatoryField: "Позначає обов'язкове поле",
    getAnswerTo: "Отримати відповідь на",
    send: "Відправити",
    formLoadingError: "Не вдалося завантажити форму.",
    successMessage: "Дякуємо за Ваш запит.",
    errorMessage: "Ми не змогли відправити Ваш запит, спробуйте ще раз.",
    respondBy: {
      email: "Email",
      postalAddress: "Поштову адресу",
    },
    questioner: "Запитувач",
    legalEntity: {
      individual: "Фізична особа",
      legalEntity: "Юридична особа",
      associationOfCitizensWithoutStatus: "Об’єднання громадян без статусу юридичної особи",
    },
    personalInfo: "Особисті дані запитувача",
    phone: "Телефон",
    email: "Електронна адреса",
    postalAddress: "Поштова адреса",
    generalDescriptionOfRequest: "Загальний опис запиту",
    generalDescription: "Вид, назва, реквізити чи зміст документа, що запитується",
    invalidEmail: "Невірний формат електронної пошти.",
    invalidPhone: "Невірний формат номеру телефону.",
    invalidRequest: "Будь ласка, зменшіть текст до 4500 символів.",
  },
};
