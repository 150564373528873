export default {
  en: {
    appeal: {
      electronic: "Electronic",
      byMail: "By mail",
      suggestedAppel:
        "You can contact the National Mediation and Conciliation Service with a statement, complaint, or proposal by means of a written request and send it to our postal address.",
      low: "Law of Ukraine 'On Appeals of Citizens'",
      byMailAppeal: {
        title: "Submit an application by mail",
        write:
          "Write your application with a statement, complaint, or proposal, formatted according to the requirements",
        low: "Law of Ukraine 'On Appeals of Citizens'",
        send: "Send an application to the postal address of st. Baseina 1/2-A, Kyiv, 01024 or to the e-mail address",
        note: "Pay attention! The appeal must be signed by the applicant(s) and dated",
      },
    },
    public: {
      title: "Access to public information",
      accordions: {
        title1: "Information on access to public information",
        title2: "List of data sets to be made public in the form of open data",
        title3:
          "Reimbursement of costs for copying or printing of documents provided upon request for information",
      },
      section1: {
        low: "According to the Law of Ukraine 'On Access to Public Information', public information is information displayed and documented by any means and on any media, which was received or created in the process of the performance by subjects of authority of their duties, provided for by the current legislation or which is in the possession of subjects of authority, other managers of public information, defined by this law.",
        accessGuaranteed: {
          title: "The right to access public information is guaranteed:",
          point1:
            "it is the duty of information managers to provide and publish information, except for cases provided for by law;",
          point2:
            "determination by the information manager of special structural subdivisions or officials who organize, in the prescribed manner, access to public information that he possesses;",
          point3:
            "maximum simplification of the procedure for submitting a request and receiving information;",
          point4:
            "access to meetings of collegial subjects of power, except for cases provided for by law;",
          point5:
            "implementation of parliamentary, public and state control over the observance of rights to access to public information;",
          point6:
            "legal responsibility for violation of legislation on access to public information.",
        },
        basicPrinciples: {
          title: "Basic principles of ensuring access to public information",
          description:
            "The provision of public information by the National Social Service Service of Ukraine is carried out in response to an information request.",
          point1: "transparency and openness of the activities of subjects of power;",
          point2:
            "free receipt and distribution of information, except for restrictions established by law;",
          point3:
            "equality, regardless of race, political, religious and other beliefs, gender, ethnic and social origin, property status, place of residence, language or other characteristics.",
        },
        informationRequest: {
          title:
            "The request for receiving information is made in an arbitrary form. In this case, it is necessary to specify:",
          point1:
            "the requester's first and last name, postal or e-mail address, and telephone number;",
          point2:
            "a list of information that the requester would like to receive. (Type, name, details or content of the document for which the request was made)",
          point3: "signature and date.",
        },
        appealingDecisions: {
          title:
            "The procedure for appealing decisions, actions or inaction of the manager of information",
          table: "Table",
          paragraph1:
            'According to the Law "On Access to Public Information", a response to an information request must be provided no later than five working days from the date of receipt of the request.',
          paragraph2:
            "If the request concerns the provision of a large amount of information or requires searching for information among a significant amount of data, the period for considering the request may be extended up to 20 working days with justification for such extension.",
          paragraph3:
            'According to Article 23 of the Law "On Access to Public Information", the decisions, actions or inaction of information managers can be appealed to the head of the manager, a higher authority or a court.',
          paragraph4:
            "Appealing decisions, actions or inaction of information administrators to the court is carried out in accordance with the Code of Administrative Procedure of Ukraine.",
          paragraph5:
            "Also, in order for the requesters to correctly understand the concepts of 'request for information' and 'appeal of citizens', we suggest that you familiarize yourself with the {link}, in which their distinguishing features are given.",
          paragraph6:
            "The procedure for providing access to public information in the NMCS is determined in accordance with the Regulation on providing access to public information in the National Mediation and Conciliation Service, its branches in the Autonomous Republic of Crimea and regions, approved by the order of the NMCS dated 10.10.2012 No. 118.",
        },
      },
      section2: {
        low: "The list of data sets in the possession of the NMCS, which are subject to publication in the form of open data, was approved by the order of the NMCS dated 03.05.2023 No. 32.",
      },
      section3: {
        paragraph:
          "The procedure for reimbursement of actual costs for copying or printing documents provided upon information requests at the NMCS Instruction on the procedure for reimbursement of costs for copying or printing documents provided upon an information request, the administrator of which is the NMCS, its branches in the ARC and regions, and the Amount of these actual expenses, approved by order of the NMCS dated 10.10.2012 No. 120.",
        documents: {
          document1:
            "Application for a statement of account for the payment of costs for copying or printing documents provided upon request for information",
          document2:
            "Account for payment of costs for copying or printing of documents provided upon request for information",
          document3:
            "The amount of actual costs for copying or printing documents provided upon requests for information, the manager of which is the NMCS, its branch",
        },
      },
      publicInformationRequest: "Request for public information",
      requestDescription:
        "Citizens of Ukraine have the right to apply to the National Mediation and Conciliation Service and its bodies with a request for access to public information in electronic and written form.",
      low: "Law of Ukraine 'On Access to Public Information'",
    },
    corruption: {
      title: "Prevention and counteraction of corruption",
      cooperation:
        "The National Mediation and Conciliation Service cooperates with persons who report possible facts of corruption or corruption-related offenses committed by NMCS employees. Persons who help in preventing and countering corruption are under the protection of the state.",
      whistleblower:
        "Whistleblower is an individual who, in the presence of conviction that the information is reliable, reported possible facts of corruption or corruption-related offenses, other violations of this Law, committed by another person, if such information became known to him in connection with his work , professional, economic, public, scientific activity, her service or training or her participation in procedures prescribed by law, which are mandatory for starting such activity, service or training.",
      rights:
        "Rights and guarantees of protection of whistleblowers in accordance with the Law of Ukraine 'On Prevention of Corruption'",
      otherRights:
        "Rights and guarantees of protection of whistleblowers in accordance with other regulatory legal acts",
      accordion: {
        antiCorruptionLegislation: "Anti-corruption legislation",
        antiCorruptionProgram: "Anti-corruption program of NMCS",
        clarification: "Clarification regarding the application of anti-corruption legislation",
      },
      legalActs: "Normative and legal acts of NMCS",
      order203: "NMCS order dated 06.12.2021 No. 203",
      order28: "NMCS order dated 06.05.2022 No. 28",
      order32: "NMCS order dated 20.05.2022 No. 32",
      order33: "NMCS order dated 20.05.2022 No. 33",
      order34: "NMCS order dated 31.05.2022 No. 34",
      order127: "NMCS order dated 21.10.2022 No. 127",
      order133: "NMCS order dated 09.12.2022 No. 133",
      briefings:
        "On the approval of the Regulation on conducting briefings on compliance with anti-corruption legislation, order of the NMCS dated 06.12.2021 No. 203",
      workOrganization:
        "On approval of the Procedure for the organization of work with reports on possible facts of corruption or corruption-related offenses, other violations of the Law of Ukraine 'On Prevention of Corruption' in the National Mediation and Conciliation Service",
      specialistInteraction:
        "On the approval of the Procedure for the interaction of the chief specialist in the prevention and detection of corruption with the personnel management sector of the National Mediation and Conciliation Service",
      incentiveMechanisms:
        "On the approval of the Regulation on the implementation of mechanisms to encourage whistleblowers and the formation of a culture of notification of possible facts of corruption or corruption-related offenses, other violations of the Law of Ukraine 'On Prevention of Corruption' in the National Mediation and Conciliation Service",
      algorithm:
        "On the approval of Methodological recommendations regarding the algorithm of actions of employees of the National Mediation and Conciliation Service and its branches in the regions in the event of a potential or real conflict of interests during the performance of their official duties",
      risks:
        "On conducting an assessment of corruption risks in the activities of the National Mediation and Conciliation Service",
      workGroups:
        "About the formation of a working group for assessing corruption risks in the activities of the National Mediation and Conciliation Service",
      NACPExplanation: "NACP EXPLANATION",
      explanation2:
        "NACP explanation dated 12.06.2023 No. 2 regarding the legal status of the whistleblower",
      antiCorruptionExamination:
        "Methodology of anti-corruption examination by the National Agency on Corruption Prevention (NACP order dated 18.05.2023 No. 109/23) (pdf, 7Mb)",
      declarationExplanation:
        'NACP explanation dated 10.04.2023 regarding the declaration of material assistance received by public servants or members of their families"',
      declarationData:
        "The 'Data for declaration' function can now do almost everything: added information about corporate rights, beneficiary, pension and social benefits",
      benefitsBan:
        "Regarding the specifics of the implementation of the ban on the receipt of benefits, services and property by state authorities and local self-government bodies (Article 54 of the Law of Ukraine 'On Corruption Prevention') and responsibility for making decisions regarding persons who have accepted such benefits, services and property",
      topErrors: "NACP prepared the memo 'TOP-25 common mistakes when filling out the declaration'",
      publicAccess:
        "Access to the public part of the Register of Declarations, the Register of POLITDATA Party Reports and the Register of Corrupt Persons is limited",
      saveTime:
        "Before the victory over the russian invaders, the declarers should not waste time on",
      humanitarianAidHeadquarters:
        "The National Agency for the Prevention of Corruption launches the Headquarters of Humanitarian Aid at the NACP to support the Ukrainian Army and victims of russian aggression",
      militaryOfficials:
        "The truth and our victory are yours: the officials who joined the ranks of the Armed Forces, territorial defense and volunteer formations do not violate the requirements for co-operation and co-operation",
      financialAssistance:
        "Can officials who were forced to leave the territory of Ukraine due to the russian invasion receive financial assistance abroad?",
      collaboratorsPunishment: "Punishments for collaborators have been increased in Ukraine",
      NACPReminder:
        "NACP reminds: it is not necessary to submit declarations, notices of significant changes in property status, and notices of opening a foreign currency account in a non-resident bank during the war",
      corruptRegisterExclusion:
        "Those who protect Ukraine as part of the Armed Forces, Military Police, State Emergency Service or other bodies may be excluded from the Register of Corruption Perpetrators",
      NACPDocsAccess:
        "We remind declarants: you can get access to all the documents submitted to NACP and submit a declaration in your personal account in the Register of Declarations",
      NACPChecks:
        "NACP clarified when and how special inspections will be conducted, taking into account the state of war",
      declarationsRegistersFeature:
        "A function has appeared in the Register of Declarations that will allow declarants to find out information about their property available in the registers. It will make it easier to fill out the declaration for those who have lost property documents",
      declarationInWar: "Is it mandatory to submit declarations during wartime?",
      educationTransparency:
        "Transparency in higher education: how integrity is ensured in one of the most famous technical universities of the country — KPI named after Igor Sikorsky",
      corruptRegister:
        "How can public procurement participants obtain information from the Register of Corrupt Persons during martial law?",
      citizensProtectionStandards:
        "Ukraine continues to raise the standards of protection of citizens who expose corruption: the court made the first decision to protect the labor rights of a relative of the whistleblower",
      laborRightsProtection:
        "New clarifications of the NACP: guarantees of protection of the labor rights of corruption whistleblowers",
      individualSanctions:
        "Road map of individual sanctions: it will be possible to avoid the imposition of sanctions only by publicly denouncing the Kremlin's policy and transferring your wealth to the Fund for the Reconstruction of Ukraine",
      officialInvestigation:
        "New clarification of the NACP: how to correctly submit and consider a submission on conducting an official investigation",
      lvivUniversity:
        "Transparency in higher education: modern technologies and trust are important conditions for the formation of integrity in students. An example of the Lviv State University of Internal Affairs",
      armyHelp:
        "Will it be necessary to declare funds that the official collected to help the army or war victims?",
      combination:
        "Regarding the application of the provisions of the Law of Ukraine 'On Prevention of Corruption' regarding the observance of restrictions on co-operation and co-operation with other types of activities under martial law",
      declarationSubjects:
        "Clarifications regarding the application of certain provisions of the Law of Ukraine 'On the Prevention of Corruption' regarding the classification of natural persons as subjects of declaration in accordance with paragraph 5 of the first part of Article 3 of the said Law, approved by the decision of the NACP dated 03/29/2018 No. 549",
      electronicDeclarationsClarification: "Clarification on filling out electronic declarations",
      propertySignificantChanges:
        "Information regarding the submission of a notice of significant changes in the property status of the subject of the declaration",
      inaccurateInfoResponsibility:
        "Information on responsibility for notoriously inaccurate information in the declaration",
      enterpriseTransfer:
        "Methodological recommendations regarding the transfer of enterprise and/or corporate rights to the management in order to prevent conflicts of interest",
      conflictsPrevention:
        "Methodological recommendations on the prevention and settlement of conflicts of interest, approved by the decision of the NAKC dated September 29, 2017 No. 839",
      informing:
        "The procedure for informing the National Agency for the Prevention of Corruption about the opening of a foreign currency account in a non-resident bank institution and the amendment to the Procedure for the formation, maintenance and publication (provision) of information of the Unified State Register of Declarations of Persons Authorized to Perform the Functions of the State or Local Self-Government",
      declarationsVerification:
        "Decision of the National Agency for the Prevention of Corruption dated September 6, 2016 No. 19 'On approval of the Procedure for verification of the fact of submission of declarations by entities in accordance with the Law of Ukraine 'On Prevention of Corruption' and notification of the National Agency for the Prevention of Corruption on cases of non-submission or late submission such declarations'",
      report: "Report a corruption offense",
      involvedInCorruption: "Information about a person involved in corruption",
      text: "The text of the appeal",
      circumstances:
        "Circumstances testifying to the facts of the commission of a corruption or corruption-related offence",
    },
    governmentProcurement: {
      title: "State procurement",
      p1: "NSPP purchases are carried out in accordance with the annual plan (Law of Ukraine 'On Public Procurement')",
      plan: {
        start: "You can familiarize yourself with",
        link: "the procurement plans",
        end: " of NSPP on the Prozorro web portal.",
      },
      procurement: {
        start: "You can familiarize yourself with",
        link: "purchases",
        end: "of NSPP on the Prozorro web portal.",
      },
      title3: "Annual procurement plans of NSPP",
    },
    governmentCleans: {
      error: "Failed to load the reports. Try later",
      search: "Search by documents",
    },
    vacancies: {
      p1: "In accordance with the legislation, appointment to civil service positions during the period of the legal regime of martial law is carried out without competitive selection.",
      p2: "Information about the results of the competition for previous years is posted on the NADS website at the link",
      h3: "Examples of documents",
      docTitle1: "Personal card",
      docTitle2: "Application form",
      docTitle3: "Summary",
      docTitle4:
        "Application form for the inspection provided for by the Law of Ukraine 'On Purification of Power'",
      docTitle5: "Consent to the processing of personal data",
      regulatoryTitle1:
        "Law of Ukraine No. 2259-IX of May 12, 2022 'On Amendments to Certain Laws of Ukraine Regarding the Functioning of the Civil Service and Local Self-Government During Martial Law'",
      regulatoryTitle2:
        "The procedure for holding a competition for public service positions, approved by the Resolution of the Cabinet of Ministers of Ukraine dated March 25, 2016 No. 246 (with amendments)",
      regulatoryTitle3:
        "Methodical recommendations on certain issues of determining special requirements for persons applying for civil service positions of categories 'B' and 'B' and preparation of conditions for conducting competition No. 4-21 dated January 15, 2021",
      regulatoryTitle4:
        "Note on preparation for the exam to determine the level of proficiency in the state language",
      moreInfo:
        "For additional information on conducting a competition (selection) to fill vacant positions in the civil service, please contact the Department of Personnel Management and Records Management of the National Mediation and Conciliation Service at the phone number",
      base: "Regulatory and legal base",
      timing: "Deadline for submission of documents",
      testing: "Testing",
      moreInfoh6: "Additional information on the conduct of the competition",
    },
  },
  uk: {
    appeal: {
      electronic: "Електронне",
      byMail: "Письмове",
      suggestedAppel:
        "Ви можете звернутися до Національної служби посередництва і примирення із заявою, скаргою, чи пропозицією за допомогою письмового звернення і надіслати його на нашу поштову адресу.",
      low: "Закон України «Про звернення громадян»",
      byMailAppeal: {
        title: "Подати письмове звернення поштою",
        write:
          "Напишіть своє звернення із заявою, скаргою, чи пропозицією, оформлене відповідно до вимог",
        low: "Законe України «Про звернення громадян»",
        send: "Надішліть звернення на поштову адресу вул. Басейна 1/2-А, м. Київ, 01024 або на адресу електронної пошти",
        note: "Зверніть увагу! Письмове звернення повинно бути підписано заявником (заявниками) із зазначенням дати",
      },
    },
    public: {
      title: "Доступ до публічної інформації",
      accordions: {
        title1: "Інформація щодо доступу до публічної інформації",
        title2: "Перелік наборів даних, які підлягають оприлюдненню у формі відкритих даних",
        title3:
          "Відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію",
      },
      section1: {
        low: "Відповідно дo Закону України «Про доступ до публічної інформації» публічною інформацією є відображена та задокументована будь-якими засобами та на будь-яких носіях інформація, яка була отримана або створена в процесі виконання суб'єктами владних повноважень своїх обов'язків, передбачених чинним законодавством або яка знаходиться у володінні суб'єктів владних повноважень, інших розпорядників публічної інформації, визначених цим законом.",
        accessGuaranteed: {
          title: "Право на доступ до публічної інформації гарантується:",
          point1:
            "обов'язком розпорядників інформації надавати і оприлюднювати інформацію, окрім випадків передбачених законом;",
          point2:
            "визначенням розпорядником інформації спеціальних структурних підрозділів або посадових осіб, які організовують у встановленому порядку доступ до публічної інформації, якою він володіє;",
          point3: "максимальним спрощенням процедури подання запиту і отримання інформації;",
          point4:
            "доступом до засідань колегіальних суб'єктів владних повноважень, крім випадків, передбачених законодавством;",
          point5:
            "здійсненням парламентського, громадського та державного контролю за дотриманням прав на доступ до публічної інформації;",
          point6:
            "юридичною відповідальністю за порушення законодавства про доступ до публічної інформації.",
        },
        basicPrinciples: {
          title: "Основні принципи забезпечення доступу до публічної інформації",
          description:
            "Надання публічної інформації Національною соціальною сервісною службою України здійснюється у відповідь на інформаційний запит.",
          point1: "прозорість та відкритість діяльності суб'єктів владних повноважень;",
          point2: "вільне отримання та поширення інформації, крім обмежень, встановлених законом;",
          point3:
            "рівноправність, незалежно від ознак раси, політичних, релігійних та інших переконань, статі, етнічного та соціального походження, майнового стану, місця проживання, мовних або інших ознак.",
        },
        informationRequest: {
          title:
            "Запит на отримання інформації складається у довільній формі. При цьому необхідно вказати:",
          point1:
            "ім'я та прізвище запитувача, поштову адресу або адресу електронної пошти, а також номер телефону;",
          point2:
            "опис інформації, яку запитувач хотів би отримати. (Вид, назву, реквізити чи зміст документа, щодо якого зроблено запит)",
          point3: "підпис і дату.",
        },
        appealingDecisions: {
          title: "Порядок оскарження рішень, дій чи бездіяльності розпорядника інформації",
          table: "Таблицею",
          paragraph1:
            'Згідно з Законом "Про доступ до публічної інформації", відповідь на інформаційний запит має бути надано не пізніше п\'яти робочих днів з дня отримання запиту.',
          paragraph2:
            "У разі, якщо запит стосується надання великого обсягу інформації або потребує пошуку інформації серед значної кількості даних, строк розгляду запиту може бути продовжено до 20 робочих днів з обґрунтуванням такого продовження.",
          paragraph3:
            'Відповідно до статті 23 Закону "Про доступ до публічної інформації", рішення, дії чи бездіяльність розпорядників інформації можуть бути оскаржені до керівника розпорядника, вищого органу або суду.',
          paragraph4:
            "Оскарження рішень, дій чи бездіяльності розпорядників інформації до суду здійснюється відповідно до Кодексу адміністративного судочинства України.",
          paragraph5:
            "Також з метою правильного розуміння запитувачами понять «запит на інформацію» та «звернення громадян» пропонуємо ознайомитися із {link}, в якій наведені їх відмінні ознаки.",
          paragraph6:
            "Порядок забезпечення доступу до публічної інформації в НСПП визначений згідно Положення про забезпечення доступу до публічної інформації у Національній службі посередництва і примирення, її відділеннях в Автономній Республіці Крим та областях, затвердженого наказом НСПП від 10.10.2012 № 118.",
        },
      },
      section2: {
        low: "Перелік наборів даних, що перебувають у володінні НСПП, які підлягають оприлюдненню у формі відкритих даних затверджено розпорядженням НСПП від 03.05.2023 № 32.",
      },
      section3: {
        paragraph:
          "Порядок відшкодування фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію у НСПП Інструкція про порядок відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію, розпорядником якої є НСПП, її відділення в АРК та областях, та Розміру цих фактичних витрат, затверджена наказом НСПП від 10.10.2012 № 120.",
        documents: {
          document1:
            "Заявка на виписку рахунка для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document2:
            "Рахунок для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document3:
            "Розмір фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію, розпорядником якої є НСПП, її відділення",
        },
      },
      publicInformationRequest: "Запит на публічну інформацію",
      requestDescription:
        "Громадяни України мають право звернутися до Національної служби посередництва і примирення та її органів із запитом на доступ до публічної інформації у електронній та письмовій формі.",
      low: "Закон України «Про доступ до публічної інформації»",
    },
    corruption: {
      title: "Запобігання та протидія корупції",
      cooperation:
        "Національна служба посередництва і примирення співпрацює з особами, які повідомляють про можливі факти корупційних або пов’язаних з корупцією правопорушень, вчинених працівниками НСПП. Особи, які допомогають в запобіганні і протидії корупції, перебувають під захистом держави.",
      whistleblower:
        "Викривач - фізична особа, яка за наявності переконання, що інформація є достовірною, повідомила про можливі факти корупційних або пов’язаних з корупцією правопорушень, інших порушень цього Закону, вчинених іншою особою, якщо така інформація стала їй відома у зв’язку з її трудовою, професійною, господарською, громадською, науковою діяльністю, проходженням нею служби чи навчання або її участю у передбачених законодавством процедурах, які є обов’язковими для початку такої діяльності, проходження служби чи навчання.",
      rights:
        "Права та гарантії захисту викривачів відповідно до Закону України «Про запобігання корупції»",
      otherRights:
        "Права та гарантії захисту викривачів відповідно до інших нормативно-правових актів",
      accordion: {
        antiCorruptionLegislation: "Антикорупційне законодавство",
        antiCorruptionProgram: "Антикорупційна програма НСПП",
        clarification: "Роз’яснення щодо застосування норм антикорупційного законодавства",
      },
      legalActs: "Нормативно-правові акти НСПП",
      order203: "Наказ НСПП від06.12.2021 №203",
      order28: "Наказ НСПП від 06.05.2022 № 28",
      order32: "Наказ НСПП від 20.05.2022 № 32",
      order33: "Наказ НСПП від 20.05.2022 № 33",
      order34: "Наказ НСПП від 31.05.2022 № 34",
      order127: "Наказ НСПП від 21.10.2022 № 127",
      order133: "Наказ НСПП від09.12.2022 №133",
      briefings:
        "Про затвердження Положення про проведення інструктажів з питань дотримання антикорупційного законодавства, наказ НСПП від 06.12.2021 № 203",
      workOrganization:
        "Про затвердження Порядку організації роботи з повідомленнями про можливі факти корупційних або пов'язаних з корупцією правопорушень, інших порушень Закону України «Про запобігання корупції» у Національній службі посередництва і примирення",
      specialistInteraction:
        "Про затвердження Порядку взаємодії головного спеціаліста з питань запобігання та виявлення корупції з сектором управління персоналом Національно служби посередництва і примирення",
      incentiveMechanisms:
        "Про затвердження Положення про впровадження механізмів заохочення викривачів та формування культури повідомлення про можливі факти корупційних або пов’язаних з корупцією правопорушень, інших порушень Закону України «Про запобігання корупції» в Національній службі посередництва і примирення",
      algorithm:
        "Про затвердження Методичних рекомендацій щодо алгоритму дій працівників Національної служби посередництва і примирення та її відділень в областях у разі виникнення потенційного чи реального конфлікту інтересів під час виконання ними службових обов’язків",
      risks:
        "Про проведення оцінювання корупційних ризиків у діяльності Національної служби посередництва і примирення",
      workGroups:
        "Про утворення робочої групи з оцінювання корупційних ризиків у діяльності Національної служби посередництва і примирення",
      NACPExplanation: "РОЗ’ЯСНЕННЯ НАЗК",
      explanation2: "Роз’яснення НАЗК від 12.06.2023 № 2 щодо правового статусу викривача",
      antiCorruptionExamination:
        "Методологія проведення антикорупційної експертизи Національним агентством з питань запобігання корупції (наказ НАЗК від 18.05.2023 № 109/23) (pdf, 7Mb)",
      declarationExplanation:
        "Роз’яснення НАЗК від 10.04.2023 щодо декларування матеріальної допомоги, яку отримали публічні службовці або члени їхніх сімей",
      declarationData:
        "Функція «Дані для декларації» тепер може майже все: додалася інформація про корпоративні права, бенефіціарство, пенсію та соцвиплати",
      benefitsBan:
        "Щодо особливостей реалізації заборони на одержання пільг, послуг і майна органами державної влади та органами місцевого самоврядування (ст. 54 Закону України «Про запобігання корупції») та відповідальності за прийняття рішень щодо осіб, які прийняли такі пільги, послуги і майно",
      topErrors: "НАЗК підготувало пам’ятку «ТОП-25 поширених помилок при заповненні декларації»",
      publicAccess:
        "Доступ до публічної частини Реєстру декларацій, Реєстру звітів партій POLITDATA та Реєстру корупціонерів обмежено",
      saveTime: "До перемоги над рашистськими загарбниками декларанти не повинні втрачати час на",
      humanitarianAidHeadquarters:
        "Нацагентство з питань запобігання корупції запускає Штаб гуманітарної допомоги при НАЗК для підтримки Української армії та постраждалих від агресії росії",
      militaryOfficials:
        "За вами правда та наша перемога: посадовці, які вступили до лав ЗСУ, тероборони та добровольчих формувань не порушують вимоги щодо сумісництва та суміщення",
      financialAssistance:
        "Чи можуть посадовці, які вимушені були покинути територію України через російське вторгнення, отримувати грошову допомогу за кордоном?",
      collaboratorsPunishment: "В Україні посилили покарання для колаборантів",
      NACPReminder:
        "НАЗК нагадує: подавати декларації, повідомлення про суттєві зміни в майновому стані та повідомлення про відкриття валютного рахунку в банку-нерезиденті під час війни не потрібно",
      corruptRegisterExclusion:
        "Тих, хто захищає Україну у складі ЗСУ, ТРО, ДСНС або інших органів, може бути виключено з Реєстру корупціонерів",
      NACPDocsAccess:
        "Нагадуємо декларантам: отримати доступ до всіх поданих до НАЗК документів та подати декларацію ви можете в персональному кабінеті у Реєстрі декларацій",
      NACPChecks:
        "НАЗК роз’яснило, коли і як будуть проводитися спеціальні перевірки, зважаючи на воєнний стан",
      declarationsRegistersFeature:
        "У Реєстрі декларацій з’явилася функція, яка дозволить декларантам дізнаватися відомості про свою власність, наявні в реєстрах. Вона полегшить заповнення декларації для тих, хто втратив документи про власність",
      declarationInWar: "Чи обов’язково подавати декларації під час війни?",
      educationTransparency:
        "Прозорість у вищій освіті: як забезпечується доброчесність в одному з найвідоміших технічних університетів країни — КПІ ім. Ігоря Сікорського",
      corruptRegister:
        "Як учасники публічних закупівель можуть отримати інформацію з Реєстру корупціонерів під час дії воєнного стану?",
      citizensProtectionStandards:
        "Україна продовжує підвищувати стандарти захисту громадян, які викривають корупцію: судом прийнято перше рішення про захист трудових прав близької особи викривача",
      laborRightsProtection:
        "Нові роз’яснення НАЗК: гарантії захисту трудових прав викривачів корупції",
      individualSanctions:
        "Дорожня карта індивідуальних санкцій: уникнути накладення санкцій можна буде лише публічно засудивши політику кремля та передавши своє багатство у Фонд відбудови України",
      officialInvestigation:
        "Нове роз’яснення НАЗК: як правильно вносити і розглядати подання про проведення службового розслідування",
      lvivUniversity:
        "Прозорість у вищій освіті: сучасні технології та довіра — важливі умови формування доброчесності в студентів. Приклад Львівського державного університету внутрішніх справ",
      armyHelp:
        "Чи потрібно буде декларувати кошти, які посадовець збирав на допомогу армії чи постраждалим від війни?",
      combination:
        "Щодо застосування положень Закону України «Про запобігання корупції» стосовно дотримання обмежень щодо сумісництва та суміщення з іншими видами діяльності в умовах воєнного стану",
      declarationSubjects:
        "Роз’яснення щодо застосування окремих положень Закону України «Про запобігання корупції» стосовно віднесення до суб’єктів декларування фізичних осіб відповідно до пункту 5 частини першої статті 3 зазначеного Закону, затверджені рішенням НАЗК від 29.03.2018 № 549",
      electronicDeclarationsClarification: "Роз’яснення щодо заповнення електронних декларацій",
      propertySignificantChanges:
        "Інформація щодо подання повідомлення про суттєві зміни у майновому стані суб’єкта декларування",
      inaccurateInfoResponsibility:
        "Інформація про відповідальність за завідомо недостовірні відомості у декларації",
      enterpriseTransfer:
        "Методичні рекомендації щодо передачі в управління підприємств та/або корпоративних прав з метою запобігання конфлікту інтересів",
      conflictsPrevention:
        "Методичні рекомендації щодо запобігання та врегулювання конфлікту інтересів, затверджені рішення НАЗК від 29.09.2017 № 839",
      informing:
        "Порядок інформування Національного агентства з питань запобігання корупції про відкриття валютного рахунка в установі банку-нерезидента та внесення зміни до Порядку формування, ведення та оприлюднення (надання) інформації Єдиного державного реєстру декларацій осіб, уповноважених на виконання функцій держави або місцевого самоврядування",
      declarationsVerification:
        "Рішення Національного агентства з питань запобігання корупції від 6 вересня 2016 р. № 19 «Про затвердження Порядку перевірки факту подання суб'єктами декларування декларацій відповідно до Закону України «Про запобігання корупції» та повідомлення Національного агентства з питань запобігання корупції про випадки неподання чи несвоєчасного подання таких декларацій»",
      report: "Повідомити про корупційне правопорушення",
      involvedInCorruption: "Відомості про особу, причетну до корупції",
      text: "Текст звернення",
      circumstances:
        "Обставини, що свідчать про факти вчинення корупційного або пов'язаного з корупцією правопорушення",
    },
    governmentProcurement: {
      title: "Державні закупівлі",
      p1: "Закупівлі НСПП здійснюються відповідно до річного плану ( Закон України «Про публічні закупівлі»)",
      plan: {
        start: "Із",
        link: "планами закупівель",
        end: "НСПП можна ознайомитись на вебпорталі Прозорро.",
      },
      procurement: {
        start: "Із",
        link: "закупівлями",
        end: "НСПП можна ознайомитись на вебпорталі Прозорро.",
      },
      title3: "Річні плани закупівель НСПП",
    },
    governmentCleans: {
      error: "Не вдалося завантажити звіти. Спробуйте пізніше",
      search: "Пошук по документах",
    },
    vacancies: {
      p1: "Відповідно до законодавства призначення на посади державної служби у період дії правового режиму воєнного стану здійснюється без конкурсного відбору.",
      p2: "Інформація про результати конкурсу за попередні роки розміщена на вебсайті НАДС за посиланням",
      h3: "Приклади документів",
      docTitle1: "Особова картка",
      docTitle2: "Бланк заяви",
      docTitle3: "Резюме",
      docTitle4:
        "Бланк заяви про проведення перевірки, передбаченої Законом України «Про очищення влади»",
      docTitle5: "Згода на обробку персональних даних",
      regulatoryTitle1:
        "Закон України № 2259-IX від 12 травня 2022 року «Про внесення змін до деяких законів України щодо функціонування державної служби та місцевого самоврядування у період дії воєнного стану»",
      regulatoryTitle2:
        "Порядок проведення конкурсу на зайняття посад державної служби, затверджений постановою Кабінету Міністрів України від 25 березня 2016 року № 246 (зі змінами)",
      regulatoryTitle3:
        "Методичні рекомендації щодо окремих питань визначення спеціальних вимог до осіб, які претендують на зайняття посад державної служби категорій «Б» і «В», та підготовки умов проведення конкурсу №4-21 від 15.01.2021",
      regulatoryTitle4:
        "Пам’ятка стосовно підготовки до іспиту на визначення рівня володіння державною мовою",
      moreInfo:
        "За додатковою інформацією з питань проведення конкурсу (добору) на зайняття вакантних посад державної служби звертатися до відділу управління персоналом та діловодства Національної служби посередництва і примирення за номером телефону",
      base: "Нормативно-правова база",
      timing: "Строк подачі документів",
      testing: "Проведення тестування",
      moreInfoh6: "Додаткова інформація з питань проведення конкурсу",
    },
  },
};
