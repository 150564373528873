export default {
  en: {
    news: {
      news: "News",
      mainNews: "Main news",
      newsByTopic: "News by topic",
      error: "Failed to download news. Try again later",
      newsSub: {
        digest: "We send you a weekly news digest",
        subscribe:
          "Sign up to receive emails with the most important news about the work of our service.",
        agreement:
          "By clicking the button, you automatically agree to the processing of personal data",
      },
      subscription: {
        placeholder: "Your email",
        subscribeButton: "Subscribe",
        emailExists: "You are already subscribed",
        error: "Failed to subscribe",
        success: "Subscribed successfully",
      },
      allNews: "All News",
      searchByName: "Search news by name",
      datePickerLabel: "Publication date",
      datePickerPlaceholder: "Select date",
      stayUpToDate: "Stay up to date with the news",
      publicationDate: "publication date",
      tags: "tags",
      foundBy: "Found by ",
      searchQuery: "search query",
      clearFilters: "Clear results",
      foundResults: "Found by search query",
      searchResults: "Search results",
      emptyResults: "No news were found for your request",
    },
    gallery: {
      allDepartments: "All departments",
      contentType: "Content type",
      contentTypeSelect: {
        all: "All",
        photo: "Photo",
        video: "Video",
      },
      departments: "Departments",
      error: "Failed to load the gallery. Try later",
      departmentsError: "Failed to load the departments. Try later",
      multilinePhoto: "photos/videos",
      photo: "photo/video",
      search: "Search",
      searchByName: "Search by name",
      emptyResults: "No documents were found for your request",
    },
    events: {
      error: "Failed to load the event. Try later",
      calendar: "Add to the calendar",
      date: "Date",
      time: "Time",
      location: "Location",
      upcoming_events: "Upcoming events",
      filter: {
        title: "Found by author",
        clear: "Clear the results",
      },
    },
    pubInMedia: {
      error: "Failed to load posts. try later",
    },
  },
  uk: {
    news: {
      news: "Новини",
      mainNews: "Головні новини",
      newsByTopic: "Новини за темою",
      error: "Не вдалося завантажити новини. Спробуйте пізніше",
      newsSub: {
        digest: "Щотижня ділимося новинами",
        subscribe:
          "Кожної неділі ми ділимося цікавим з роботи нашої служби. Підпишіться, щоб отримувати листи з нашими новинами.",
        agreement: "Натискаючи кнопку, ви автоматично погоджуєтесь на обробку персональних даних",
      },
      subscription: {
        placeholder: "Ваш email",
        subscribeButton: "Підписатися",
        emailExists: "Ви вже підписані",
        error: "Не вдалося підписатися",
        success: "Ви успішно підписалися",
      },
      allNews: "Всі новини",
      searchByName: "Пошук новин по назві",
      datePickerLabel: "Дата публікації",

      stayUpToDate: "Будь в курсі новин",
      publicationDate: "датою публікації",
      tags: "тегом",
      foundBy: "Знайдено за ",
      searchQuery: "пошуковим запитом",
      clearFilters: "Очистити результати",
      foundResults: "Знайдено за пошуковим запитом",
      searchResults: "Результати пошуку",
      emptyResults: "За вашим запитом не знайдено новин",
    },
    gallery: {
      allDepartments: "Усі відділення",
      contentType: "Тип контенту",
      contentTypeSelect: {
        all: "Усі",
        photo: "Фото",
        video: "Відео",
      },
      departments: "Відділення",
      error: "Не вдалося завантажити галерею. Спробуйте пізніше",
      departmentsError: "Не вдалося завантажити відділення. Спробуйте пізніше",
      multilinePhoto: "фото/відео",
      photo: "фото/відео",
      search: "Пошук",
      searchByName: "Пошук по назві",
      emptyResults: "За вашим запитом не знайдено документів",
    },
    events: {
      error: "Не вдалося завантажити анонс. Спробуйте пізніше",
      calendar: "Додати в календар",
      date: "Дата",
      time: "Час",
      location: "Місце проведення",
      upcoming_events: "Найближчі події",
      filter: {
        title: "Знайдено за автором",
        clear: "Очистити результати",
      },
    },
    pubInMedia: {
      error: "Не вдалося завантажити публікації. Спробуйте пізніше",
    },
  },
};
