export default {
  en: {
    announcements: "Events",
    all_announcements: "All events",
    electronicAppeal: "Electronic appeal",
    sendAppeal: "Send appeal",
    submitElectronicAppeal:
      "Submit your questions, complaints, suggestions and get the necessary information from any place where there is access to the Internet.",
    license:
      "All content is available under the Creative Commons Attribution 4.0 International license unless otherwise noted",
    areas: {
      disputesConflicts:
        "A collective labor dispute is a disagreement that has arisen between parties to social and labor relations.",
      alorithm:
        'Depending on the grounds for making claims provided for in Article 2 of the Law of Ukraine "On the procedure for resolving collective labor disputes", the procedure for resolving a collective labor dispute changes.',
      trainingLaborArbitrators:
        "One of the important functions of the NSPP is the training of mediators and arbitrators who specialize in resolving collective labor disputes and forming lists of arbitrators and mediators.",
      prevention:
        "A collective labor dispute is a disagreement that has arisen between parties to social and labor relations.",
      concept:
        'According to Art. 1 of the Law of Ukraine "On Social Dialogue in Ukraine", social dialogue is the process of defining and converging positions, reaching common agreements and making agreed decisions by the parties to the social dialogue.',
    },
  },
  uk: {
    announcements: "Анонси",
    all_announcements: "Всі анонси",
    electronicAppeal: "Електронне звернення",
    sendAppeal: "Подати звернення",
    submitElectronicAppeal:
      "Подайте свої запитання, скарги, пропозиції та отримайте необхідну інформацію з будь-якого місця, де є доступ до інтернету.",
    license:
      "Весь контент доступний за ліцензією Creative Commons Attribution 4.0 International license, якщо не зазначено інше",
    areas: {
      disputesConflicts:
        "Колективний трудовий спір  - це розбіжності, що виникли між сторонами соціально-трудових відносин.",
      alorithm:
        "В залежності від підстав висунення вимог, що передбачені статтею 2 Закону України «Про порядок вирішення колективних трудових спорів», змінюється порядок вирішення колективного трудового спору.",
      trainingLaborArbitrators:
        "Однією з важливих функцій НСПП є підготовка посередників та арбітрів, які спеціалізуються на вирішенні колективних трудових спорів і формування списків арбітрів та посередників.",
      prevention:
        "Колективний трудовий спір  - це розбіжності, що виникли між сторонами соціально-трудових відносин.",
      concept:
        "Відповідно до ст. 1 Закону України «Про соціальний діалог в Україні», соціальний діалог - процес визначення та зближення позицій, досягнення спільних домовленостей та прийняття узгоджених рішень сторонами соціального діалогу.",
    },
  },
};
