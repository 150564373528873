import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createHead } from "@unhead/vue";
import { messages } from "./i18n";
import { Locale, LOCALE_KEY } from "./constants";
import "bootstrap";
import "@vuepic/vue-datepicker/dist/main.css";
import "./assets/styles/color-variables.scss";
import "./assets/styles/grid-variables.scss";
import "./assets/styles/styles.scss";
import filters from "./helpers/filters";
import dayjs from "dayjs";
import "dayjs/locale/uk";

const i18n = createI18n({
  locale: Locale.Uk,
  legacy: false,
  messages,
});

const app = createApp(App);

app.use(store).use(router).use(i18n).use(createHead());
app.config.globalProperties.$filters = filters;

function chooseLocale() {
  if (localStorage.getItem(LOCALE_KEY)) {
    const locale = localStorage.getItem(LOCALE_KEY) as Locale;
    i18n.global.locale.value = locale;
    dayjs.locale(locale);
    return;
  }

  const locale = navigator.language.includes(Locale.En) ? Locale.En : Locale.Uk;
  // const locale = Locale.Uk;
  localStorage.setItem(LOCALE_KEY, locale);
  i18n.global.locale.value = locale;
  dayjs.locale(locale);
}
chooseLocale();

app.use(createHead());

app.mount("#app");
