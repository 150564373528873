<script setup lang="ts"></script>

<template>
  <div class="regulatory-block">
    <h5 class="heading">{{ $t("regulatory.framework.title") }}</h5>
    <p class="text">{{ $t("regulatory.framework.text") }}</p>
    <router-link to="/regulatory/legislation" class="button button-linear">
      {{ $t("regulatory.framework.link") }}
    </router-link>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/grid-variables";

.regulatory-block {
  padding: 32px 16px 40px 16px;
  background: var(--white-smoke);
  font-size: 14px;
  line-height: 21px;

  @media all and (min-width: map-get($grid-breakpoints, "desktop")) {
    padding-right: 14rem;
    padding-left: 40px;
  }

  .heading {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px;

    @media all and (min-width: map-get($grid-breakpoints, "desktop")) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .text {
    margin-bottom: 32px;
  }
}
</style>
