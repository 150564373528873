<script setup>
/* eslint-disable */
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["on-token-change"]);
const { locale } = useI18n();

const recaptchaScript = ref();

const loadRecaptchaScript = () => {
  recaptchaScript.value = document.createElement("script");
  recaptchaScript.value.setAttribute(
    "src",
    `https://www.google.com/recaptcha/api.js?onload=onloadCallback&hl=${locale.value}`,
  );
  recaptchaScript.value.setAttribute("async", "true");
  recaptchaScript.value.setAttribute("defer", "true");
  document.head.appendChild(recaptchaScript.value);
};

onMounted(() => {
  window.onloadCallback = () => {
    grecaptcha.render("recaptcha_container", {
      sitekey: process.env.RECAPTCHA_PUBLIC_KEY,
      action: "verify",
      callback: (token) => {
        window.recaptcha_token = token;
        emit("on-token-change", token);
      },
      "expired-callback": () => {
        grecaptcha.reset();
        emit("on-token-change", "");
      }
    });
  };

  loadRecaptchaScript();
});

</script>

<template>
  <div id="recaptcha_container" />
</template>
